@import '../../utils/_variables';

.counter {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2px 0 16px;
}

.counter-button {
    background-color: $colorGrayFontNew;
    color: $colorMainBackgroud;
    border: none;
    padding: 0 10px;
    font-size: 24px;
    height: 30px;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    // margin: 0 10px;
    // border-radius: 10px;
}

.counter-button.left {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.counter-button.right {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.counter-button:hover {
    background-color: $colorMainBlack;
    color: #fff;
}

.counter-value {
    font-size: 24px;
    height: 30px;
    line-height: 30px;
    width: 90px;
    /* 固定宽度 */
    text-align: center;
    /* 居中对齐 */
    background-color: $colorMainBlack;
}