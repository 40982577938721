@import  '../../../../utils/_variables';
.CCCampaigns{
  .CCCampaigns__create--btn{
    width: 300px;
    letter-spacing: 1.2px;
    margin: 20px 0 20px 0;
  }
  .ToggleTable {

    .ToggleTable__header {
      background: #2b2e38;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 30px;
      .ToggleTable__tabs {
        display: flex;
        margin-left: 10px;
        .ToggleTable__tab {
          display: flex;
          align-items: center;
          background: rgba(255, 255, 255, 0.05);
          border: none;
          padding: 5px 8px;
          border-radius: 3px;
          color: #FFF;
          line-height: 1;
          letter-spacing: 1px;
          font-weight: 600;
          cursor: pointer;
          text-decoration: none;
          margin-left: 5px;
          &:hover {
            background: rgba(255,255,255,.2);
          }
          &.active {
            background: rgba(255,255,255,.2);
          }
        }
      }
      .ToggleTable__header-title {
        padding: 0;
        margin: 0;
        font-size: 22px;
        letter-spacing: 2.5px;
      }
      .dot-status{
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background-color: #ef3937;
        box-shadow: 0 0 9px 0 #ff987f;
        margin-left: 10px;
      }
      i {
        font-size: 30px;
        line-height: 18px;
        margin-left: auto;
      }
    }
    .ToggleTable__table-wrapper {
      background: #2b2e38;
      padding: 30px;
      .no-results {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 44px;
        margin-top: 30px;
      }
      .Box {
        border: 1px solid rgba(255,255,255,.2);
        border-radius: 5px;
        display: flex;
        padding: 15px;
        margin-bottom: 20px;
        min-height: 150px;
        .MuiLinearProgress-determinate {
          margin: 10px 0px 5px 0px;
          height: 10px;
        }
        .Box__item {
          text-align: center;

          &.Box__item--4 {
            margin: 0px 20px;
            width: 20%;
          }
          .Box__item--title {
            font-weight: 600;
          }
        }
        .Box__title {
          font-size: 20px;
        }
        .Box__row {
          display: flex;
          margin-top: 10px;
        }
        .Box__left {
          width: 100%;
          padding-right: 7.5px;
        }
        .Box__right {
          padding-left: 7.5px;
          width: 45%;
          a {
            color: white;
          }
        }
        .Box__cta {
          margin-top: 10px;
          display: flex;
          button, a {
            display: flex;
            align-items: center;
            background: rgba(255, 255, 255, 0.05);
            border: none;
            padding: 5px 8px;
            border-radius: 3px;
            color: #FFF;
            line-height: 1;
            letter-spacing: 1px;
            font-weight: 600;
            cursor: pointer;
            text-decoration: none;
            margin-right: 10px;
          }
        }
        .Box__readmore {
          display: flex;
          align-items: center;
          background: rgba(255, 255, 255, 0.05);
          border: none;
          padding: 5px 8px;
          border-radius: 3px;
          color: #FFF;
          line-height: 1;
          letter-spacing: 1px;
          font-weight: 600;
          cursor: pointer;
          text-decoration: none;
        }
      }
    }
  }
  .CCCampaignsModalMaster {
    &.active{
      visibility: visible;
      opacity: 1;
      .Modal__wrapper{
        transform: scale(1);
      }
    }
    .Modal {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 99;
      display: flex;
      align-items: center;
      justify-content: center;
      visibility: hidden;
      opacity: 0;
      overflow-x: hidden;
      background: rgba(0,0,0,.5);
      align-items: flex-start;
      @media screen and (min-width: 640px){
        align-items: center;
      }
      &.active {
        visibility: visible;
        opacity: 1;
        .Modal__wrapper {
          // transform: scale(1);
          top: -50%;
          transform: scale(1) translateY(calc(50% + 10vh));
          margin: 10vh 0;
          @media #{$mobileOnly}{
            transform: none !important;
            margin:5vh 0 !important;
            top:0 !important;
          }
        }
      }
      .Modal__layer {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
      }
      .Modal__wrapper {
        min-width: 300px;
        min-height: 300px;
        background: #202227;
        padding: 0;
        position: relative;
        text-align: center;
        align-items: center;
        justify-content: center;
        transform: scale(0.8);
        // margin: 5vw 0 5vh 0;
        @media screen and (min-width: 640px){
          margin: 0;
        }
        .Modal__loading {
          padding-top: 40%;
        }
        .Modal__header {
          font-size: 20px;
          margin-top: 80px;
        }
        .Modal__body {
          margin-top: 30px;
          a {
            margin-top: 20px;
            color: white;
            font-size: 20px;
          }
        }
        .Modal__footer {
          margin-top: 30px;
          button {
            border: 1px solid white;
            color: white;
            padding: 5px 10px;
            cursor: pointer;
            &:hover {
              color: #202227;
              background: white;
            }
          }
        }
      }
    }
  }
}

.disabled-report {
  cursor: not-allowed;
  pointer-events: none;
  opacity: .5;
}