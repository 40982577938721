@import "../../../../../utils/_variables";

$flexSpacings: 15px;

.CampaignDisplay {
  .CampaignDisplay__Search {
    display: flex;
    flex-direction: column;

    .CampaignDisplay__Search_Top {
      align-items: center;
      display: flex;
      margin-bottom: 10px;

      .CampaignDisplay__Search_Input {
        align-items: center;
        background-color: $colorBackground;
        border-radius: 10px;
        display: flex;
        height: 50px;
        margin-right: 27px;
        padding: 0 25px;
        width: 412px;

        svg {
          height: 21px;
          width: 20px;
        }

        #quest-display-search-input {
          background: none;
          border: none;
          box-shadow: none;
          color: $colorLightWhite;
          margin-bottom: 1px;
          width: 100%;

          &:focus {
            outline: none;
          }
        }
      }

      .CampaignDisplay__Search_Filters_Button {
        @include center-content;
        background-image: linear-gradient(to bottom, #e8cdfe, #b7bbfd);
        border-radius: 10px;
        cursor: pointer;
        height: 50px;
        width: 50px;

        svg {
          height: 21px;
          width: 20px;
        }
      }
    }

    .CampaignDisplay__Search_Filters {
      background-color: $colorBackground;
      border-radius: 10px;
      display: inline-block;
      max-height: 0;
      overflow: hidden;
      -webkit-transition: max-height 1s;
      -moz-transition: max-height 1s;
      -ms-transition: max-height 1s;
      -o-transition: max-height 1s;
      transition: max-height 1s;
      width: 60%;

      .CampaignDisplay__Search_Filters_Content {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        padding: 20px;

        .CampaignDisplay__Search_Filters_Content_Title {
          font-size: larger;
          font-weight: bolder;
        }

        .CampaignDisplay__Search_Filters_Content_Item {
          align-items: center;
          display: flex;

          .CampaignDisplay__Search_Filters_Content_Item_Checkbox {
            color: $colorLightWhite;
          }

          .CampaignDisplay__Search_Filters_Content_Item_Title {
            color: $colorLightWhite;
          }
        }
      }
    }

    .Filters_Open {
      max-height: 420px;
    }
  }

  .QuestDisplay_Title {
    font-size: 50px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 900;
    letter-spacing: 0.5px;
    margin: 20px 0;
    text-align: left;
  }

  .QuestDisplay_Filters {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    -webkit-transition: height 1s;
    -moz-transition: height 1s;
    -ms-transition: height 1s;
    -o-transition: height 1s;
    transition: height 1s;
    width: 100%;

    .QuestDisplay_Filters_Buttons {
      display: flex;

      .QuestDisplay_Filters_Button {
        @include center-content;
        background-color: $colorPurpleRay;
        border-radius: 4px;
        color: $colorWhite;
        cursor: pointer;
        font-size: 14px;
        font-stretch: normal;
        font-style: normal;
        font-weight: normal;
        height: 45px;
        letter-spacing: normal;
        margin-right: 22px;
        padding: 0 20px;
        position: relative;
        text-align: center;

        .QuestDisplay_Filters_Button_Close {
          position: absolute;
          right: 6px;
          top: 0;
        }
      }
    }

    .QuestDisplay_Filters_Clear {
      @include gray-border-button;
      font-size: 14px;
      font-stretch: normal;
      font-style: normal;
      font-weight: 900;
      height: 45px;
      letter-spacing: normal;
      line-height: normal;
      text-align: right;
      width: 193px;
    }
  }

  .QuestDisplay_List {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;

    .CampaignDisplay__Item {
      background-color: #322D38;
      border-radius: 8px;
      display: flex;
      height: 244px;
      width: 100%;
      gap: 60px;

      @media #{$mobileAndTabletOnly} {
        @include col-evenly;
        align-items: center;
        padding: 25px;
      }

      .CampaignDisplay__Item_Img {
        border-radius: 10px;
        height: 244px;
        width: 514px;
        min-width: 514px;
        max-width:514px;
        object-fit: cover;
        @media #{$mobileAndTabletOnly} {
          max-width: none;
          min-width: 0;
          width: 100%;
        }
      }

      .CampaignDisplay__Item_Details {
        flex: 3;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-evenly;
        width: 100%;

        @media #{$mobileAndTabletOnly} {
          margin-left: 0;
        }
      
        .CampaignDisplay__Item_Title {
          font-size: 18px;
          font-stretch: normal;
          font-style: normal;
          font-weight: 900;
          letter-spacing: 0.3px;
          text-align: left;

          @media #{$mobileAndTabletOnly} {
            align-self: center;
            margin: #{$flexSpacings} 0px;
          }   
        }

        .CampaignDisplay__Item_Rewards {
          display: flex;
          flex-direction: row;
          gap: 20px;

          @media #{$mobileAndTabletOnly} {
            margin-left: 0;
            @include row-evenly;
          }

          .CampaignDisplay__Item_Rewards_Title {
            color: #CDCCD0;
            font-size: 14px;
            font-weight: 400;
            margin: 4px 10px;
            text-align: left;
          }

          .CampaignDisplay__Item_Rewards_Details {
            align-items: center;
            display: flex;
            border-radius: 14px;
            background-color: #211C27;
            padding: 8px;
            width: 143px;
            svg {
              height: auto;
              width: 18px;
            }

            .CampaignDisplay__Item_Rewards_Details_Text {
              font-size: 14px;
              font-weight: bold;
              letter-spacing: 0.24px;
              margin-left: 10px;
              text-align: left;
              color: #CDCCD0 ;
            }
          }
        }

        .CampaignDisplay__Item_Actions {
          @include row-start;
          align-items: flex-start;
          gap: 12px;
          width: 100%;
          font-size: 16px;

          @media #{$mobileAndTabletOnly} {
            flex-wrap: wrap;
            margin-left: 0;
            margin-top: $flexSpacings;
            @include row-evenly;
          }

          .CampaignDisplay__Item_Actions_Action {
            @include row-between;
            @include transform-scale;
            background-color: #02D18F;
            border-radius: 20px;
            cursor: pointer;
            font-stretch: normal;
            font-style: normal;
            color: $colorBackground;
            height: 25px;
            letter-spacing: 0.24px;
            padding: 20px;
            text-align: left;
            text-transform: uppercase;
            @include vw-pixels(('font-size': 25));

            @media #{$desktopAndLarger} {
              font-size: 100%;
              max-width: 350px;
            }

            svg {
              height: 20px;
              width: 20px;
              margin-right: 10px;
            }
          }

          .CampaignDisplay__Item_Actions_Game {
            @include transform-scale;
            @include row-between;
            background-color: #EF3937;
            border-radius: 20px;
            cursor: pointer;
            height: 25px;
            padding: 20px;
            text-transform: uppercase;
            @media #{$desktopAndLarger} {
              max-width: 350px;
            }

            img {
              height: 36px;
              width: 10%;
              
            }
            .CampaignDisplay__Item_Actions_Game_Title {

              @media #{$desktopAndLarger} {
                max-width: 350px;
              }
              font-size: 16px;
              font-weight: bold;
              letter-spacing: 0.24px;
              text-align: left;
            }

            svg {
              height: 20px;
              width: 20px;
              margin-right: 10px;
            }
          }
        }

        .CampaignDisplay__Item_View_Details {
          @include purple-gradient-button;
          height: 48px;
          width: 100%;
        }
      }
    }

    .CampaignDisplay__Blank {
      @include center-content;
      border: 1px dashed $colorSoftGray;
      border-radius: 14px;
      flex-direction: column;
      max-height: 412px;
      min-height: 412px;
      width: 100%;

      & > * {
        margin: 12px;
      }

      svg {
        height: 85px;
        width: auto;
      }

      .CampaignDisplay__Blank_Text {
        color: $colorWhite;
        font-size: 20px;
        font-stretch: normal;
        font-style: normal;
        font-weight: normal;
        letter-spacing: 0.2px;
        text-align: center;
      }

      .CampaignDisplay__Blank_Button {
        @include purple-gradient-button;
        @include transform-scale;
        height: 48px;
        width: 40%;
      }
    }
  }
}
