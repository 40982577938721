@import "../../../../../utils/_variables";

$magic-number: 35px;

.QuestDetail {
  display: flex;
  flex-direction: column;

  width: 100%;
  position: relative;
  .QuestDetail__container{
    background-color: #211C27;
  }

  .QuestDetailMasthead {
    position: relative;

    min-height: 440px;
    width: 100%;

    background-color: $colorBackground;

    .QuestDetailMasthead__Edit {
      @include hazy-button;

      // background-color: $colorBackground;

      position: absolute;
      top: 30px;
      right: 20px;

      height: 67px;
      width: 130px;

      z-index: 99;

      svg {
        height: 21px;
        width: auto;
      }

      .QuestDetailMasthead__Edit__Text {
        margin-left: 7px;

        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
      }
    }

    .QuestDetailMasthead__Cover {
      width: 100%;
      object-fit: cover;
      -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
      mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
      border-radius: 8px 8px 0 0;
    }

    .QuestDetailMasthead__Fade {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      background-image: linear-gradient(to bottom, rgba(23, 25, 28, 0), rgb(23, 25, 28));
    }

    .QuestDetailMasthead__GameLogo {
      // center using transform
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: auto;
      height: 165px;
      object-fit: contain;
    }

    .QuestDetailMasthead__Title {
      @include center-content;
      background: #211C27;
      bottom: 0;
      height: 75px;
      position: absolute;
      width: 100%;

      .QuestDetailMasthead__Title__Text {
        font-size: 24px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 0.24px;
        text-align: center;
        color: #FDFDFD;
        border-bottom: 2px solid transparent;
        border-image: linear-gradient(to right, transparent 20%, #FA3633 20% 80%, transparent 80%);
        border-image-slice: 1;
        text-transform: uppercase;
      }
    }
  }

  .QuestDetailDetails {
    display: flex;
    flex-direction: column;
    padding: $magic-number;
    background: #211C27;
    width: 100%;

    .QuestDetailDetails__Title {
      font-size: 24px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.15;
      letter-spacing: 0.4px;
      text-align: left;
      margin-bottom: 24px;
      text-transform: uppercase;
    }

    .QuestDetailDetails__Date,
    .QuestDetailDetails__Reward,
    .QuestDetailDetails__Description,
    .QuestDetailDetails__RequiredStreamTime {
      color: #CDCCD0;
      font-size: 16px;
      margin-bottom: 4px;
      text-align: left;
    }

    .QuestDetailsIcons {
      align-items: center;
      display: flex;
      margin: 20px 0 30px;

      svg {
        height: 28px;
        margin-right: 12px;
        width: auto;
      }
    }
  }

  .QuestDetailEligibility {
    display: flex;
    flex-direction: column;
    width: 100%;

    .Eligibility__Title {
      @include center-content;
      color: #EF3936;
      font-size: 24px;
      font-stretch: normal;
      font-style: normal;
      margin-bottom: 40px;
    }

    .Eligibility__Items {
      display: flex;
      justify-content: center;
      width: 100%;

      @media #{$mobileOnly} {
        flex-direction: column;
      }

      .Eligibility__Items__Item {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin: 0 35px;
        min-height: 91px;

        @media #{$mobileOnly} {
          margin: 35px 0;
        }

        .Eligibility__Item__Title {
          font-size: 55px;
          font-stretch: normal;
          font-style: normal;
          font-weight: 900;
          letter-spacing: -0.55px;
          line-height: normal;
        }

        .Eligibility__Item__Subtitle {
          font-size: 16px;
          font-stretch: normal;
          font-style: normal;
          font-weight: 900;
          letter-spacing: 0.96px;
          line-height: 1.13;
          margin-top: 8px;
        }

        .Eligibility__Item__Flag {
          height: 50px;
          width: auto;

          margin-bottom: 11px;
        }
      }
    }
  }

  .QuestDetailDeliverables {
    display: flex;
    flex-direction: column;
    margin-top: 45px;
    width: 100%;

    .QuestDetailDeliverables__Title {
      font-size: 32px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: 0.32px;
      text-align: center;
    }

    .QuestDetailDeliverables__List {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;

      @media #{$mobileOnly} {
        align-items: center;
        flex-direction: column;
      }

      .DeliverableCard {
        align-items: center;
        background: #322D38;
       
        border-radius: 8px;
        display: flex;
        height: 95px;
        margin: 18px;
        max-width: 378px;
        padding: 18px;
        width: 44%;

        @media only screen and (max-width: 931px) {
          width: calc(100% - 36px);
        }

        @media only screen and (min-width: 1025px) and (max-width: 1272px) {
          width: calc(100% - 36px);
        }

        .DeliverableCard__Text {
          color: #FFF;
          font-size: 16px;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          line-height: 0.95;
          margin-left: 18px;
        }

        .DeliverableCard__Icon {
          max-width: 36px;

          .DeliverableCard__Icon__Img {
            object-fit: cover;    
            width: 36px;

            &.Darken {
              filter: brightness(255);
            }
          }

          svg {
            height: auto;
            width: 36px;
          }
        }
      }
    }
  }

  .QuestDetailDeveloper {
    display: flex;
    flex-direction: column;

    width: 100%;

    padding: $magic-number;

    .Developer__Header {
      display: flex;
      flex-direction: column;
      align-items: center;

      margin-bottom: $magic-number;

      .Developer__Header__Logo {
        @include center-content;
        min-height: 100px;
        max-height: 100px;
        min-width: 200px;
        max-width: 200px;
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.75), rgba(84, 84, 84, 0.75));
        .Developer__Header__Logo__Img {
          height: 100px;
          width: 100px;
        }
      }

      .Developer__Header__Title {
        padding: $magic-number;
        font-size: 20px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        text-transform: uppercase;
        letter-spacing: 0.5px;
      }
    }

    .Developer__Description {
      margin-bottom: $magic-number;
      color: #CDCCD0;
      font-size: 16px;
      margin-bottom: 4px;
      text-align: left;
    }

    .Developer__LearnMore {
      @include red-button-content;
      font-size: 16px;
      color: #FDFDFD;
      text-transform: uppercase;
      margin: 100px auto;
      height: 32px;
      width: 40%;
    }
  }

  .QuestDetail__Padding__Wrapper {
    padding: $magic-number;
  }

  .QuestDetailFAQs {
    display: flex;
    flex-direction: column;

    width: 100%;

    .FAQ__Title {
      font-size: 32px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: 0.32px;
      text-align: left;
    }

    .FAQCard {
      display: flex;
      flex-direction: column;

      margin-top: 20px;
      padding: 20px;

      border-radius: 3px;
      background-color: rgba(0, 0, 0, 0.65);
      cursor: pointer;

      &:hover {
        background-color: rgba(69, 69, 69, 0.85);
      }

      .FAQCard__Question {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .FAQCard__Question__Text {
          font-size: 18px;
          font-weight: 900;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: 0.18px;
          text-align: left;
        }

        svg {
          height: auto;
          width: 14px;
        }
      }

      .FAQCard__Answer {
        display: inline-block;
        max-height: 0;
        margin-top: 0;

        overflow: hidden;

        -webkit-transition: max-height 0.25s;
        -moz-transition: max-height 0.25s;
        -ms-transition: max-height 0.25s;
        -o-transition: max-height 0.25s;
        transition: max-height 0.25s;
        -webkit-transition: margin-top 0.05s;
        -moz-transition: margin-top 0.05s;
        -ms-transition: margin-top 0.05s;
        -o-transition: margin-top 0.05s;
        transition: margin-top 0.05s;

        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: 0.14px;
        text-align: left;
      }

      .AnswerExpanded {
        max-height: 1000px;
        margin-top: 20px;

        -webkit-transition: max-height 0.75s;
        -moz-transition: max-height 0.75s;
        -ms-transition: max-height 0.75s;
        -o-transition: max-height 0.75s;
        transition: max-height 0.75s;
      }
    }
  }
}
