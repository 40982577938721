@import '../../utils/_variables';

.Welcome {
	font-family: Roboto;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-direction: column;
	flex-direction: column;
	min-width: 100%;
	min-height: 100vh;
	background-image: url("../images/bg.png");
	padding-top: 120px;

	.image-sizer {
		opacity: 1;

		&.image-sizer-gamers {
			width: 104px;
			height: 68px;
		}

		&.image-sizer-streamers {
			width: 94px;
			height: 91px;
		}
	}

	.ModalMaster {
		.Modal {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 99;
			display: flex;
			align-items: center;
			justify-content: center;
			overflow-x: hidden;
			background: rgba(0, 0, 0,0.5);

			.Modal__layer {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				cursor: pointer;
			}
			@media screen and (min-width: 640px) {
				align-items: flex-start;
			}

			.Modal__left_welcome {
				width: 100%;
				height: 80px;
				background-color: #211C27;
				display: flex;
				align-items: center;
				justify-content: space-around;

				.logo {
					align-self: center;
					background-repeat: no-repeat;
					background-size: contain;
					height: 46px;
					width: 122px;
				}

				@media #{$mobileOnly} {
					width: 100%;
					height: 8vh;
				}
			}

			.Modal__wrapper {
				width: 900px;
				height: 100%;
				background: #322D38;
				position: relative;
				text-align: center;
				align-items: center;
				justify-content: center;
				.Modal__title {
					font-size: 25px;
					letter-spacing: 2px;
					margin: 20px 0 0;
				}
				.Modal__close-btn {
					width: 35px;
					height: 35px;
					margin-left: auto;
					position: absolute;
					right: 30px;
					top: 30px;
					cursor: pointer;

					&:before {
						content: "";
						width: 3px;
						height: 90%;
						background: #37393D;
						display: block;
						position: absolute;
						left: 50%;
						transform-origin: center;
						transform: rotate(45deg);
					}

					&:after {
						content: "";
						width: 3px;
						height: 90%;
						background: #37393D;
						display: block;
						position: absolute;
						left: 50%;
						transform-origin: center;
						transform: rotate(-45deg);
					}
				}

				.blurb {
					display: block;
					color: #595261;
					width: 100%;
					font-size: 16px;
					text-align: center;
					position: absolute;
					bottom: 32px;
				}
			}
		}

		.Modal__welcome {
			.Modal__wrapper {
				.Welcome__header {
					margin-top: 70px;
					margin-bottom: 30px;
					font-size: 24px;

					@media #{$mobileOnly} {
						margin-bottom: 40px;
					}

					.Welcome__header__title {
						font-size: 24px;
						border-bottom: 2px solid transparent;
						border-image: linear-gradient(to right, transparent 47%, #FA3633 47% 54%, transparent 54%);
						border-image-slice: 1;
						text-transform: uppercase;
					}

					.Welcome__header__description {
						color: #7E7B82;
						font-size: 18px;
						margin: 0 auto;
						margin-top: 48px;
						border:none;
					}
				}
				.Welcome__body {
					display: flex;
					justify-content: center;
					gap: 64px;
					margin-top: 48px;

					@media #{$mobileOnly} {
						flex-direction: column;
						align-items: center;
					}
					.Welcome__gamers {
						border-radius: 12px 12px 12px 12px;
						border: 2px solid #595261;
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: flex-end;
						height: 316px;
						width: 275px;
						padding-bottom: 29px;
						background-color: #2E2933;
						@media #{$mobileOnly} {
							margin-bottom: 40px;
						}
						.Welcome__gamers__image {
							position: relative;
							width: 140px;
						}
						.Welcome__gamers__title {
							text-transform: uppercase;
							font-weight: 700;
							font-size: 30px;
							margin: 10px 0 15px;
						}
						.Welcome__gamers__row {
							font-weight: 400;
							font-size: 16px;
							color: #7E7B82;
							line-height: 24px;
						}
					}

					.Welcome__streamers {
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: flex-end;
						border-radius: 12px 12px 12px 12px;
						border: 2px solid #595261;
						height: 316px;
						width: 275px;
						background-color: #2E2933;
						padding-bottom: 29px;

						@media #{$mobileOnly} {
							margin-bottom: 40px;
						}

						.Welcome__streamers__image {
							position: relative;
							width: 140px;
						}

						.Welcome__streamers__title {
							text-transform: uppercase;
							font-weight: 700;
							font-size: 30px;
							margin: 10px 0;
						}

						.Welcome__streamers__row {
							font-weight: 400;
							font-size: 16px;
							color: #7E7B82;
							line-height: 24px;
						}
					}
				}

				.Welcome__footer {
					display: flex;
					align-items: center;
					justify-content: center;
					margin-bottom: 30px;
					.Welcome__footer__button {
						button,
						a {
							display: block;
							background: #EF3937;
							color: white;
							cursor: pointer;
							text-decoration: none;
							text-align: center;
							font-weight: normal;
							border-radius: 4px;
							font-weight: 600;
							font-size: 18px;
							padding: 8px 90px;
							text-transform: uppercase;
							border-radius: 36px;
							margin-top: 100px;
						}
					}
				}
			}
		}
	}
}