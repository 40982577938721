@import '../../utils/_variables';
@import '../../utils/_util';



@mixin HomeTitle {
	font-family: 'Arial', sans-serif;
	color: #F4F4F4;
	font-weight: 600;
	text-transform: uppercase;
	text-align: center;
	font-size: 36px;
	margin-bottom: 80px;
}

@mixin BgPaddingBlock {
	padding-block: 120px;
}

@mixin BgPaddingTop {
	padding-top: 120px;
}

@mixin PsDscription {
	padding-top: 120px;
	font-size: 18px;
	color: #322D38;
	text-align: center;
	font-family: Arial;
}

@mixin OurBenefitsDscription {
	padding-top: 120px;
	font-size: 18px;
	color: #322D38;
	text-align: center;
	font-family: Arial;
}

.HomeV4 {
	.bg-button {
		display: inline-block;
		background-color: #EF3937;
		border-radius: 50px;
		color: #FFF;
		font-weight: 900;
		text-decoration: none;
		text-transform: uppercase;
		font-size: 18px;
		margin-top: 80px;
		padding: 8px 72px;
	}

	.Hero {
		position: relative;
		.Hero__background {
			width: 100%;
			z-index: 1;
		}

		.Hero__content {
			position: absolute;
			top: 15%;
			left: 15%;
			z-index: 2;

			.Hero__title {
				font-weight: 600;
				line-height: 114%;
				text-transform: uppercase;
				font-size: 80px;
			}

			.Hero__description {
				font-size: 24px;
			}
		}

		.Hero__scroll-cta {
			background-color: transparent;
			border: none;
			display: none;

			@media screen and (min-width: 1024px) {
				cursor: pointer;
				display: flex;
				flex-direction: column;
				align-items: center;
				outline: none;
				position: absolute;
				left: calc(50% - 50px);
				bottom: 10%;
			}

			span {
				color: #FFF;
				display: block;
				font-family: 'Roboto', sans-serif;
				font-size: 14px;
				font-weight: bold;
				line-height: 1.4vh;
				white-space: nowrap;
				width: 13vh;
			}

			img {
				align-self: center;
				bottom: 1vw;
				display: block;
				position: absolute;
				width: 16px;
				margin-bottom: 14px;
			}
		}
	}

	.OurBenefits {
		background-image: url('../brand-service/assetsV2/Lconcat-bg.png');
		background-position: center;
		background-size: cover;
		height: 100%;
		display: flex;
		width: 100%;
		@include BgPaddingBlock;

		.OurBenefits__content {
			align-items: center;
			display: flex;
			flex-direction: column;
			justify-content: center;
			width: 100%;
			position: relative;

			.OurBenefits__title {
				@include HomeTitle;
				border-bottom: 2px solid transparent;
				border-image: linear-gradient(to right, transparent 20%, #FA3633 20% 80%, #FA3633 80%, transparent 80%);
				border-image-slice: 1;
			}

			.OurBenefits__items {
				align-items: center;
				display: grid;
				grid-template-columns: repeat(3, 1fr);
				gap: 40px;
				align-items: stretch;
				justify-content: flex-end;
				position: relative;
				text-transform: uppercase;
				

				.BenefitsItem {
					display: flex;
					align-items: center;
					flex-direction: column;
					justify-content: flex-end;
					background-color: #EF3937;
					border-radius: 8px;
					width: 328px;
					position: relative;
					.BenefitsItem__icon {
						width: 50%;
						background-size: cover;
						margin-top: 36px;
						z-index: 1;
					}
					.BenefitsItem__title {
						color: #fff;
						font-family: 'Roboto', sans-serif;
						font-size:24px;
						font-weight: 900;
						margin: 4px 0 20px 0;
						text-align: center;
					}

					.BenefitsItem__description {
						color: #000;
						background: #fff;
						font-family: 'Roboto', sans-serif;
						font-size: 12px;
						padding: 20px;
						text-align: center;
						height: 40%;
					}
				}
			}
		}
	}

	.SeeHowItWorks {
		background-image: url('./assets/v4/how-it-works-bg.png');
		background-position: center;
		background-size: cover;
		display: flex;
		flex-direction: column;
		position: relative;
		@include BgPaddingBlock;

		.SeeHowItWorks__content {
			align-items: center;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			position: relative;

			.SeeHowItWorks__title {
				@include HomeTitle;
				border-bottom: 2px solid transparent;
				border-image: linear-gradient(to right, transparent 25%, #FA3633 25% 75%, transparent 75%);
				border-image-slice: 1;
			}

			.SeeHowItWorks__player {
				position: relative;
				width: 95%;

				@media screen and (min-width: 1024px) {
					width: 65%;
				}

				&::before {
					content: "";
					display: block;
					padding-top: 56%;
					// padding-top: 70%;
					// border: 1px solid #FFF;
				}

				.SeeHowItWorks__player--decoration {
					height: 100%;
					left: 0;
					position: absolute;
					top: 0;
					width: 100%;

					.d1 {
						border: 1px #47dbc4 solid;
						border-bottom: none;
						border-right: none;
						height: 11%;
						left: -2px;
						position: absolute;
						top: -2px;
						// background: #47dbc4;
						width: 10%;

						@media screen and (min-width: 1024px) {
							border: 4px #47dbc4 solid;
							border-bottom: none;
							border-right: none;
							height: 11%;
							left: -5px;
							top: -5px;
						}
					}

					.d2 {
						border: 2px dotted #fd8d82;
						border-bottom: none;
						border-left: none;
						height: 15%;
						position: absolute;
						right: -3px;
						top: -3px;
						width: 11%;

						@media screen and (min-width: 1024px) {
							border: 4px #fd8d82 dotted;
							border-bottom: none;
							border-left: none;
							right: -5px;
							top: -5px;
						}
					}

					.d3 {
						border-bottom: 3px dotted #3a74e0;
						bottom: -3px;
						height: 15%;
						left: 10px;
						position: absolute;
						width: 16%;

						@media screen and (min-width: 1024px) {
							border: 4px #3a74e0 dotted;
							border-left: none;
							border-top: none;
							bottom: -5px;
							left: 10px;
						}
					}

					.d4 {
						background: #a142ff;
					}
				}

				.SeeHowItWorks__player--video {
					height: 100%;
					left: 0;
					position: absolute;
					top: 0;
					width: 100%;
				}

				.SeeHowItWorks__player--preview-image {
					background-image: url('./assets/v4/how-it-works-bg.jpg');
					background-position: top center;
					background-size: cover;
					display: block;
					height: 100%;
					left: 0;
					position: absolute;
					top: 0;
					width: 100%;
					border: 3px solid #EF3937;

					&.hidden {
						display: none;
					}
				}

				.SeeHowItWorks__player--preview-overlay {
					background: rgba(0, 0, 0, 0.6);
					content: "";
					height: 100%;
					left: 0;
					position: absolute;
					top: 0;
					transition: ease-in-out 300ms all;
					width: 100%;

					&.hidden {
						display: none;
					}
				}

				.SeeHowItWorks__player--play-btn {
					background: rgba(255, 255, 255, 0);
					cursor: pointer;
					left: 50%;
					max-width: 70px;
					opacity: 0.8;
					outline: none;
					position: absolute;
					top: 50%;
					transform: translate(-50%, -50%);
					transform-origin: center;
					transition: ease-in-out 200ms all;
					width: 16%;

					&.hidden {
						display: none;
					}

					&:hover {
						background: rgba(255, 255, 255, 0.2);
					}
				}

				&:hover {
					.SeeHowItWorks__player--preview-overlay {
						background: rgba(0, 0, 0, 0.4);
					}

					.SeeHowItWorks__player--play-btn {
						opacity: 1;
					}
				}
			}
		}
	}

	.TrackingAndPayments {
		background: #FFF;
		color: #000;
		@include BgPaddingBlock;

		.TrackingAndPayments__content {
			align-items: center;
			display: flex;
			width: 100%;
			flex-direction: column;
			justify-content: center;
			position: relative;

			.TrackingAndPayments__media {
				position: relative;
				width: 40%;

				&::before {
					content: "";
					display: block;
					padding-top: 64%;
				}

				.TrackingAndPayments__media--decoration {
					height: 100%;
					left: 0;
					position: absolute;
					top: 0;
					width: 100%;
				}

				.TrackingAndPayments__media--image {
					left: 0;
					position: absolute;
					top: 40px;
					width: 100%;
				}
			}

			.TrackingAndPayments__details {
				align-items: center;
				display: flex;
				flex-direction: column;
				position: relative;

				.TrackingAndPayments__title {
					@include HomeTitle;
					color: #211C27;
					width: 100%;
					border-bottom: 2px solid transparent;
					border-image: linear-gradient(to right, transparent 33%, #FA3633 33% 66%, transparent 66%);
					border-image-slice: 1;
				}

				.TrackingAndPayments__description {
					color: #322D38;
					font-family: 'Roboto', sans-serif;
					font-size: 18px;
					padding: 0 6%;
					width: 700px;
					text-align: center;
				}
			}
		}
	}

	.PlatformSupport {
		background: url('../brand-service/assetsV2/Lconcat-bg.png');
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		@include BgPaddingTop;

		.PlatformSupport__content {
			align-items: center;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			position: relative;

			.PlatformSupport__content-left {
				display: flex;
				flex-direction: column;
				align-items: center;
				padding-bottom: 2vw;

				.PlatformSupport__title {
					@include HomeTitle;
					border-bottom: 2px solid transparent;
					border-image: linear-gradient(to right, transparent 33%, #FA3633 33% 66%, transparent 66%);
					border-image-slice: 1;
				}

				.PlatformSupport__description {
					font-size: 18px;
					color: #CDCCD0;
					text-align: center;
					font-family: Arial;

					@media screen and (max-width: 2560px) {
						@include vw-pixels(("font-size": 18));
					}
				}

				.PlatformSupport__platforms {
					// justify-content: space-between;
					justify-content: center;
					display: flex;
					flex-direction: row;
					gap: 36px;
					width: 100%;

					@media screen and (min-width: 1024px) {
						max-width: 70%;
					}

					.PlatformItem {
						align-items: center;
						display: flex;
						justify-content: center;
						position: relative;
						width: 110px;
						height: 110px;
						border-radius: 50%;
						background: #EF3937;

						&:before {
							content: '';
							display: block;
							padding-top: 65%;
						}

						&.coming-soon {
							&:after {
								align-items: center;
								background: rgba(0, 0, 0, 0.7);
								content: 'Coming Soon';
								display: flex;
								font-weight: bold;
								height: 100%;
								justify-content: center;
								left: 0;
								position: absolute;
								text-transform: uppercase;
								top: 0;
								transition: 300ms ease opacity;
								width: 100%;
							}

							&:hover {
								&:after {
									opacity: 0;
								}
							}
						}

						.PlatformItem__icon {
							width: 50%;

							&.PlatformItem__icon--youtube {
								width: 80%;
							}
						}
					}
				}
			}

			.PlatformSupport__content-right {
				align-items: center;
				// background: #49e1ca;
				display: -ms-flexbox;
				display: flex;
				flex-direction: column;
				justify-content: center;
				padding: 12vw 0vw;
				width: 100%;

				@media screen and (min-width: 1024px) {
					height: 100%;
					padding: 0;
					position: relative;
					width: 40%;
				}

				img {
					width: 100%;
				}
			}
		}
	}

	.Brands {
		background-image: url('../about-us/assets/BrandsBackground.png');
		background-position: center;
		background-size: cover;
		@include BgPaddingBlock;

		.Brands__content {
			display: flex;
			flex-direction: column;
			margin: 0 auto;
			width: 80%;

			.Brands__content--title {
				@include HomeTitle;
				border-bottom: 2px solid transparent;
				border-image: linear-gradient(to right, transparent 35%, #322D38 35% 65%, transparent 65%);
				border-image-slice: 1;
			}

			.Brands__logos-wrapper {
				align-items: flex-start;
				display: grid;
				grid-template-columns: repeat(5, 1fr);
				gap: 20px;
				justify-content: center;
				margin: 0 auto;

				.Brands__logos-wrapper--logo {
					width: 260px;
					align-items: center;
					display: flex;
					flex-direction: column;
					justify-content: center;
					transition: all 0.3s ease;

					img {
						width: 100%;
						height: auto;
						object-fit: contain;
					}

					&:hover {
						transform: translateY(-5px);
					}
				}
			}
		}
	}
}