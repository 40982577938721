@import '../../utils/_variables';

.navigation {
  align-items: center;
  background-color: initial;
  display: flex;
  flex-direction: row;
  height: initial;
  justify-content: center;
  left: 0;
  opacity: 1;
  padding-top: 0;
  position: initial;
  top: 120px;
  transition: 200ms ease all;
  visibility: visible;
  width: 100%;
  font-family: Arial;

  ul {
    display: flex;
    flex-direction: row;
    margin-left: 20px;
    gap: 20px;
    padding: 0;
    list-style: none;
    width: initial;

    li {
      margin: 0 20px ;
      position: relative;
      a,
      button {
        color: #D6D5D7;
        align-items: center;
        background: initial;
        border: none;
        cursor: pointer;
        display: inline-flex;
        justify-content: center;
        letter-spacing: 0.4px;
        line-height: 16px;
        margin: 0;
        padding: 15px 0;
        position: relative;
        text-decoration: none;
        transition: 300ms ease background;
        width: 100%;
      }
    }

    &.navigation-left {
      li {
        &:nth-child(3) {
          a,
          button {
            color: $colorMainOrange;
          }
        }
        a,
        button {
          font-size: 16px;
          &:after {
            background-position-x: -45px;
            background-repeat: no-repeat;
            background-size: 35px;
            background: none;
            border-bottom: 3px solid $colorMainOrange;
            border-image-slice: 1;
            border-left: 0px solid $colorMainOrange;
            border-right: 0px solid $colorMainOrange;
            border-top: 0px solid $colorMainOrange;
            bottom: 0;
            color: #FFF;
            content: "";
            display: block;
            // height: inherit;
            opacity: 0;
            pointer-events: none;
            position: absolute;
            top: 0;
            transition: 200ms ease all;
            height: 76%;
            width: 100%;
            z-index: 10;
          }

          &:hover:after,
          &.active:after {
            background-repeat: no-repeat;
            background-position-x: 0px;
            opacity: 1;
          }
        }
      }
    }

    &.navigation-right {
      align-items: center;
      margin-left: auto;

      li {
        align-items: center;
        display: flex;

        button {
          // background-color: rgba(255, 255, 255, 0.1);
          border: solid 3px $colorMainOrange;
          border-radius: 22px;
          height: 36px;
          padding: 0;
          width: 164px;

          i {
            color: $colorGray;
            font-size: 16px;
            margin-right: 8px;
          }

          span {
            color: #D6D5D7;
            font-size: 14px;
            font-weight: 300;
          }
        }
      }
    }
  }
}

.custom-btn-back {
  border: solid 3px $colorMainOrange;
  background-color: $colorMainOrange;
  border-radius: 22px;
  color: #fff;
  height: 36px;
  padding: 0;
  width: 164px;
}

/* 在 NavMenuNew.scss 文件中添加 */
.custom-tooltip {
  z-index: 9999 !important;
  // max-width: 250px !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15) !important;
}

/* 调整提示框在图标下方的位置 */
.__react_component_tooltip.place-bottom {
  margin-top: 10px !important;
}