@import '../../utils/variables';

.game-card {
  background-color: $colorBGray;
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  /* Ensure the card takes the full width of its container */
  overflow: hidden;
  /* Hide any overflow content */

  @media #{$mobileOnly} {
    border-radius: 4px;
    flex-direction: column;

    @include vw-pixels(('height': 480), $vwPixelsScale);
  }

  @media #{$macroScreens} {
    min-height: 480px;
  }

  .image-container {
    width: 100%;

    .image {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      // height: 0;
      justify-content: center;
      overflow: hidden;
      // padding-bottom: 56.25%; //16：9 aspect ratio
      padding-bottom: 485px;
      position: relative;

      // @media #{$mobileOnly} {
      //   flex: 1;
      // }
      // object-fit: cover;
      // height: 30%;
      height: auto;
      width: 100%;

      img {
        object-fit: cover;
        height: auto;
      }

      .image-overlay {
        // background: radial-gradient(circle, rgba(2, 0, 36, 0) 0%, rgba(0, 0, 0, 0) 35%, rgba(0, 0, 0, 0.618) 100%);
        // padding-bottom: 56.25%;
        // width: 100%;
        position: absolute;
        top: 0;
        right: 0;
        width: 40%;
        height: 100%;
        background: rgba(0, 0, 0, 0.3);
      }
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    right: 0;
    width: 40%;
    height: 100%;
    box-sizing: border-box;
    padding: 50px;

    @media #{$mobileOnly} {
      height: 400px;
      padding: 0;
    }

    .dev {
      display: flex;
      padding: 12px;
      order: 0;

      @media #{$mobileOnly} {
        background-image: linear-gradient(to top, rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.05));
        max-height: 70px;
      }

      .logo {
        background-color: #000;
        border-radius: 25%;
        height: 90px;
        margin-right: 20px;
        padding-bottom: 9px;
        padding-left: 9px;
        padding-right: 8px;
        padding-top: 7px;
        width: 90px;
        @media #{$mobileOnly} {
          height: 40px;
          margin: 0;
          width: 40px;
          @include vw-pixels(('margin': 8,
            ));
        }
      }

      .names {
        height: 100%;
        display: flex;
        flex-direction: column;
        text-align: left;
        justify-content: center;
        font-family: 'arial';
        text-transform: uppercase;
        font-size: 18px;


        @media #{$mobileOnly} {
          // justify-content: center;

          @include vw-pixels(('font-size': 25,
              'padding-left': 12,
            ));
        }
        .dev-name {
          color: $colorGrayNew;
        }

        .game-name {
          color: $colorGrayNew;
          font-weight: 900;
        }
      }
    }

    .game-description {
      height: auto;
      font-stretch: extra-condensed;
      font-weight: bold;
      text-align: left;
      text-transform: uppercase;
      font-family: 'arial';
      font-size: 40px;
      margin-top: 40px;

      @media #{$mobileOnly} {
        margin-top: 0;
        order: 0;

        @include vw-pixels(('font-size': 32,
            'padding': 32));
      }

      p {
        margin: 0;
      }
    }

    .image-list {
      display: flex;
      flex-direction: row;
      margin-top: 20px;
    }

    .game-image {
      width: 30px;
      height: 30px;
      object-fit: cover;
      border-radius: 4px;
    }

    .game-image:not(:first-child) {
      margin-left: 5px;
      /* Apply margin to all images except the first one */
    }

    .view-quests-button {
      cursor: pointer;
      text-decoration: none;
      text-transform: uppercase;
      background-color: $colorMainOrange;
      color: $colorGrayNew;
      border-radius: 26px;
      text-align: center;
      font-size: 18px;
      padding: 5px 30px;
      width: fit-content;
      margin-top: auto
    }

  }
}