@import "../../utils/_variables";

.Profile {
  background: #322D38;
  padding: 120px 0 35px;
  .add-hover-glow {
    @include hoverGlow;
  }
  .inputFile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  .Profile_list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 18px;

    .GameList__wrapper {
      min-width: 200px;
      background: #211C27;
      border-radius: 10px;
      padding: 20px 0;

      .loading {
        text-align: center;
        padding: 20px 0 0 0;
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        .separator {
          border-top: 2px solid #83858c;
          margin: 0 0 15px 0;
        }

        li {
          margin: 0 0 15px 0;
          font-weight: 400;
        }

        .GameList__label {
          font-weight: bold;
          cursor: pointer;
          display: flex;
          align-items: center;
          color: #FFFEFE;
          background: #2c3239;
          height: 44px;
          margin: 0 0 4px 0;
          padding: 0 0 0 18px;

          i {
            margin: 0 16px 0 auto;
            align-self: center;
          }
        }

        .GameList__GameItem {
          list-style: none;
          border-bottom: 1px solid #2C2733;
          border-top: 1px solid #2C2733;
          cursor: pointer;
          &:last-child {
            margin: 0;
          }

          a,
          button {
            display: block;
            width: 100%;
            padding-block: 16px;
            font-size: 16px;
            color: #FFFEFE;
            text-decoration: none;
            text-align: center;
            &.active,
            &.hover {
              font-weight: bold;
              color: #ffffff;
            }
          }

          &.active,
          &.hover {
            background: #EF3937;

            a,
            button {
              font-weight: bold;
              color: #ffffff;
            }
          }
        }

        .link-out {
          margin: 0 5px;

          &.active::after,
          &:hover::after {
            width: 0 !important;
            height: 0 !important;
          }
        }
      }
    }

    .Profile__container {
      width: 100%;
      max-width: 1200px;
      // Mobile
      padding-right: 15px;
      padding-left: 15px;

      @media #{$tabletAndLarger} {
        padding-right: 0;
        padding-left: 0;
      }

      /*********** START PROFILE TOP ***********/
      .Profile__top {
        font-size: 24px;
        font-weight: bold;
        text-transform: uppercase;
        color: #FFFFFF;
        background: #211C27;
        border-radius: 10px 10px 0 0;
        padding: 20px;
        border-bottom: 2px solid #595261;
      }

      /*********** END PROFILE TOP ***********/
      /*********** START PROFILE INFO ***********/
      .Profile__info {
        background: #211C27;
        padding: 35px 45px;

        .title_wrap {
          text-align: center;
          margin-bottom: 48px;
          margin-right: 98px;
        }

        .Profile__info__title {
          color: #fff;
          font-weight: bold;
          font-size: 24px;
          text-align: center;
          padding: 4px;
          text-transform: uppercase;
          border-bottom: 2px solid transparent;
          border-image: linear-gradient(to right, transparent 20%, #FA3633 20% 80%, transparent 80%);
          border-image-slice: 1;
        }

        .Profile__info__content {
          @media #{$tabletAndLarger} {
            display: flex;
            gap: 76px;
          }

          .Profile__info__right {
            flex: 1;
            // @media #{$tabletAndLarger} {
            //   padding: 0 10px;
            // }

            .Profile__info__form {
              border-top: #2e2f32 2px solid;
              padding-top: 35px;
              padding-right: 90px;

              @media #{$tabletAndLarger} {
                padding-top: 0;
                border-top: none;
              }

              .Profile__info__form__row {
                justify-content: center;
                gap: 30px;

                span.disabled {
                  color: #83858c;
                }

                &.mobile__hide {
                  display: none;
                }

                @media #{$tabletAndLarger} {
                  display: flex;

                  // not redundant
                  &.mobile__hide {
                    display: flex;
                  }
                }

                .Profile__info__form__left {
                  flex: 1;
                  position: relative;

                  input[disabled] {
                    cursor: default;
                    color: #83858c;
                  }

                  .Profile__info__change-password {
                    color: #3a74e0;
                    position: absolute;
                    top: 22px;
                    right: 0;
                    font-size: 12px;
                    cursor: pointer;
                    z-index: 2;
                  }
                }

                .Profile__info__form__right {
                  flex: 1;
                  position: relative;

                  input[disabled] {
                    cursor: default;
                    color: #83858c;
                  }

                  .Profile__info__change-password {
                    color: #3a74e0;
                    position: absolute;
                    top: 22px;
                    right: 0;
                    font-size: 12px;
                    cursor: pointer;
                    z-index: 2;
                  }
                }

                .Profile__info__form__single {
                  flex-basis: 100%;

                  input[disabled] {
                    cursor: default;
                    color: #83858c;
                  }

                  .form_label {
                    font-family: Arial;
                    font-weight: 400;
                    font-size: 14px;
                    color: #7E7B82;
                    line-height: 19px;
                  }

                  .form_value {
                    margin-top: 10px;
                    display: flex;
                    align-items: center;
                    gap: 20px;
                  }

                  .value__item {
                    display: flex;
                    align-items: baseline;
                    gap: 10px;
                    font-size: 14px;
                  }
                }

                .Profile__info__form__last {
                  flex-basis: 100%;
                  display: flex;
                  justify-content: flex-end;
                  position: relative;
                  right: -90px;

                  button {
                    margin-left: 6px;
                  }
                  .fa-edit{
                    margin-right: 12px;
                  }
                }

                label {
                  color: #83858c;
                  font-size: 12px;
                  line-height: 20px;
                  margin-bottom: 12px;
                }

                .input-wrapper {
                  position: relative;

                  .error-message {
                    color: #dc4f4f;
                    font-size: 12px;
                    position: absolute;
                    right: 0;
                    bottom: -17px;
                  }

                  .error-icon {
                    color: #dc4f4f;
                    display: none;
                    position: absolute;
                    right: 10px;
                    top: 10px;
                  }

                  .ok-icon {
                    position: absolute;
                    right: 10px;
                    bottom: 5px;
                    color: #49e1ca;
                  }
                }

                input,
                select {
                  background-color: #202227;
                  border: none;
                  // border-bottom: 2px solid #83858c;
                  border-radius: 0px;
                  color: white;
                  display: inline-block;
                  padding-left: 12px;
                  text-align: left;
                  font-size: 12px;
                  margin: 0;
                  width: 100%;
                }

                input.error,
                select.error {
                  border-bottom: 2px solid #dc4f4f;
                }

                select {
                  background-image: none;
                }

                a {
                  text-decoration: none;
                }

                ::placeholder {
                  /* Chrome, Firefox, Opera, Safari 10.1+ */
                  color: rgb(131, 133, 140);
                  opacity: 1;
                  /* Firefox */
                }

                :-ms-input-placeholder {
                  /* Internet Explorer 10-11 */
                  color: rgb(131, 133, 140);
                }

                ::-ms-input-placeholder {
                  /* Microsoft Edge */
                  color: rgb(131, 133, 140);
                }

                .first-option {
                  color: rgb(131, 133, 140);
                }

                input:focus,
                select:focus {
                  border-bottom: 2px solid #3a74e0;
                  box-shadow: none;
                }

                input:placeholder-shown+label {
                  cursor: text;
                  max-width: 66.66%;
                  color: #d1d1d1;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  transform-origin: left bottom;
                  transform: translate(0, 1.9rem) scale(1.5);

                  &:after {
                    content: attr(data-label);
                  }
                }

                .input-wrapper {
                  display: flex;
                  flex-flow: column-reverse;
                  margin-bottom: 1em;
                  position: relative;
                  background-color: #322D38;
                  border-radius: 8px;
                }

                input,
                select {
                  z-index: 1;
                  background-color: transparent;
                }

                select option {
                  background-color: #202227;
                }

                label {
                  z-index: 0;
                }

                label,
                input {
                  transition: all 0.2s;
                  touch-action: manipulation;
                }

                .Profile__info__verificationcode {
                  color: #3a74e0;
                  position: absolute;
                  right: 16px;
                  top: 22px;
                  font-size: 12px;
                  cursor: pointer;
                  z-index: 2;
                }
              }
            }
          }

          .Profile__info__left {
            margin-top: 120px;

            .UserInfo__profile--container {
              position: relative;
              max-width: 120px;
              max-height: 120px;
              width: 100%;
              height: 100%;
              cursor: pointer;
              background-color: #60525D;
              border-radius: 10px;

              .UserInfo__profile--container-avatar {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                height: 100%;
                padding: 0;
                margin: 0;
                background-repeat: no-repeat;
                background-size: contain;
                object-fit: cover;
                cursor: pointer;
                border-radius: 10px;
              }
            }

            .UserInfo__profile__button {
              width: 120px;
              border-radius: 8px;
              cursor: pointer;
              color: #7E7B82;
              font-size: 16px;
              text-align: center;
              background-color: #322D38;
              padding: 10px 0;
              line-height: 20px;
              margin-top: 6px;
            }
          }
        }
      }

      /*********** END PROFILE INFO ***********/
      /*********** START PROFILE CONNECT ***********/
      .Profile_connect_container {
        padding: 0 24px 24px;
        ;
        border-bottom: 2px solid #EF3937;
        border-radius: 0 0 10px 10px;
        background-color: #211C27;
      }

      .Profile__connect {
        // background: #2C2733;
        padding: 70px 260px;

        .Profile__connect__title {
          color: #fff;
          padding: 4px;
          font-size: 18px;
          line-height: 24px;
          text-align: center;
          text-transform: uppercase;
        }

        .title_wrap {
          text-align: center;
          margin-bottom: 48px;
        }

        .Profile__connect__title {
          color: #fff;
          font-weight: bold;
          font-size: 24px;
          text-align: center;
          padding: 4px;
          text-transform: uppercase;
          border-bottom: 2px solid transparent;
          border-image: linear-gradient(to right, transparent 25%, #FA3633 25% 75%, transparent 75%);
          border-image-slice: 1;
        }

        .Profile__connect__message {
          color: $colorLight;
          margin: 30px auto 80px;
          font-size: 16px;
          text-align: center;

          a {
            color: $colorLight;
          }
        }

        .Profile__connect__content {
          margin-bottom: 50px;
          padding: 0 25px 25px 25px;
          position: relative;
        }
      }

      /*********** END PROFILE CONNECT ***********/
      .CommandCenter__child {
        display: flex;
        flex-direction: column;
        min-width: calc(75% - 30px);
        width: 100%;
        background: #211C27;

        .CommandCenter__navigation {
          display: flex;
          margin: 20px 20px 0;

          >a {
            position: relative;
            font-size: 18px;
            font-style: normal;
            font-stretch: normal;
            line-height: 36px;
            height: 36px;
            color: #CDCCD0;
            text-decoration: none;
            list-style: none;
            margin-right: 20px;
            padding: 0 18px;
            border-radius: 16px;

            &.active,
            &:hover {
              color: #fff;
              background: #EF3937;
            }
          }
        }
      }
    }


  }

  .cta__button {
    border: 2px solid #595261;
    background: #322D38;
    border-radius: 16px;
    color: #CDCCD0;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    font-weight: normal;
    font-weight: 600;
    font-size: 14px;
    padding: 4px 30px;
  }

  .cta2__button {
    border: 2px solid #595261;
    background: #211C27;
    border-radius: 16px;
    color: #CDCCD0;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    font-weight: normal;
    font-weight: 600;
    font-size: 14px;
    padding: 4px 30px;
  }

  .ProfileUpdateImage {
    font-family: Roboto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 100%;

    button.StripeCheckout {
      margin-top: 60%;
    }

    .ModalMaster {
      .Modal__updateavatar {
        .Modal__wrapper {
          max-height: 350px;
          max-width: 350px;
          display: flex;
          flex-flow: wrap;
          background: $colorBackground;

          .Modal__updateavatar__content {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            margin: 4%;
            width: 92%;
            height: 92%;
            cursor: pointer;

            .Modal__updateavatar__image {
              padding-top: 30px;
              background: url("../../images/profile/upload.png");
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center;
              height: 50px;
              width: 55px;
              margin-right: auto;
              margin-left: auto;
              position: relative;
            }

            .Modal__updateavatar__title {
              padding-top: 25px;
              font-weight: 700;
              font-size: 20px;
              width: 100%;
              color: white;
              position: relative;
            }

            .Modal__updateavatar__or {
              padding-top: 10px;
              font-size: 14px;
              color: $colorLightGray;
              width: 100%;
              position: relative;
            }

            .Modal__updateavatar__action {
              padding-top: 10px;
              position: relative;

              button,
              label {
                padding: 15px 30px;
                font-weight: 900;
                font-size: 16px;
              }

              label {
                width: 50%;
                line-height: 1;
                margin: 0 auto;
              }
            }

            .Modal__updateavatar__loading {
              margin-top: 60px;
              height: 70px;
              margin-right: 40px;
              margin-left: 40px;
              position: relative;
              background: $colorDarkGray;

              .Modal__updateavatar__loading__progress {
                position: absolute;
                height: 100%;
                width: 42%;
                background-color: $colorEnergyBlue;
              }

              .Modal__updateavatar__loading__percentage {
                position: absolute;
                font-weight: 900;
                right: 10px;
                font-size: 30px;
                line-height: 70px;
                color: $colorBackground2;
              }

              .Modal__updateavatar__loading__fileinfo {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;

                .Modal__updateavatar__loading__top {
                  position: relative;
                  font-size: 18px;
                  text-align: left;
                  padding-left: 15px;
                }

                .Modal__updateavatar__loading__bottom {
                  position: relative;
                  font-size: 12px;
                  text-align: left;
                  font-weight: 300;
                  padding-left: 15px;
                }
              }
            }

            .Modal__updateavatar__title2 {
              padding-top: 10px;
              font-weight: 700;
              font-size: 20px;
              width: 100%;
              color: white;
              position: relative;
            }

            .Modal__updateavatar__button {
              cursor: pointer;
              padding-top: 10px;
              color: $colorEnergyBlue;
              font-size: 14px;
              font-weight: 700;
            }

            .Modal__updateavatar__avatar {
              color: #fff;
              display: flex;
              width: 140px;
              height: 140px;
              align-items: center;
              justify-content: center;
              justify-self: center;
              margin-right: auto;
              margin-left: auto;
              font-family: "Lato", sans-serif;
              position: relative;

              .Modal__updateavatar__avatar--container {
                position: relative;
                max-width: 130px;
                max-height: 130px;
                width: 100%;
                height: 100%;
                border-radius: 100%;
                background: linear-gradient(to bottom, #02bdad, #036ccf);

                .Modal__updateavatar__avatar--container-avatar {
                  width: calc(100% - 6px);
                  height: calc(100% - 6px);
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  padding: 0;
                  margin: 0;
                  border-radius: 100%;
                  background-repeat: no-repeat;
                  background-size: contain;
                }
              }
            }
          }
        }
      }

      .Modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 99;
        display: flex;
        align-items: center;
        justify-content: center;
        visibility: hidden;
        opacity: 0;
        //    transition: 200ms ease all;
        overflow-x: hidden;
        background: rgba(0, 0, 0, 0.5);

        .Modal__layer {
          // background: rgba(0,0,0,0.5);
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          cursor: pointer;
        }

        align-items: flex-start;

        @media screen and (min-width: 640px) {
          align-items: center;
        }

        .Modal__wrapper {
          width: 90%;
          height: 90%;
          position: relative;
          text-align: center;
          align-items: center;
          justify-content: center;
          transform: scale(0.8);
          margin: 5vw 0 5vh;

          @media screen and (min-width: 640px) {
            margin: 0;
          }

          .Modal__updateavatar__border {
            position: absolute;
            width: 92%;
            height: 92%;
            border: 2px dashed $colorBackground2;

            &.active {
              border: 2px solid $colorEnergyBlue;
            }
          }

          .icon {
            width: 50px;
            margin: 30px auto;

            img {
              width: 100%;
            }
          }

          .Modal__title {
            font-size: 25px;
            letter-spacing: 2px;
            margin: 20px 0 0;
          }

          .Modal__close-btn {
            width: 35px;
            height: 35px;
            margin-left: auto;
            position: absolute;
            right: 25px;
            top: 25px;
            cursor: pointer;
            z-index: 10;
            $crossColor: $colorBackground2;

            &:before {
              content: "";
              width: 3px;
              height: 90%;
              background: $crossColor;
              display: block;
              position: absolute;
              left: 50%;
              // top: 50%;
              transform-origin: center;
              transform: rotate(45deg);
            }

            &:after {
              content: "";
              width: 3px;
              height: 90%;
              background: $crossColor;
              display: block;
              position: absolute;
              left: 50%;
              // top: 50%;
              transform-origin: center;
              transform: rotate(-45deg);
            }
          }
        }

        &.active {
          visibility: visible;
          opacity: 1;

          .Modal__wrapper {
            transform: scale(1);
            //transition: 200ms ease all;
          }
        }
      }
    }
  }

}