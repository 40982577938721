@import '../../../../utils/_variables';

a {
  color: white;
}

span {
  &.error {
    color: $colorCrimson;

    a {
      color: $colorCrimson;
    }
  }

  &.success {
    color: $colorLifeGreen;

    a {
      color: $colorLifeGreen;
    }
  }

  &.pay_success {
    color: #87C641;
  }

  &.info {
    color: $colorEnergyBlue;
  }

  &.warning {
    color: #ffd300;

    a {
      color: #ffd300;
    }
  }
}

.small-btn {
  background: rgba(255, 255, 255, 0.05);
  border: none;
  padding: 5px 8px;
  border-radius: 3px;
  color: #FFF;
  letter-spacing: 1px;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 11px;
  text-decoration: none;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: inline-block;
}

.toggleable {
  display: none;
}

.NoQuestTiered {
  padding: 30px 40px;
}

.ToggleTable__header-buttons * {
  margin-left: 10px;
  // padding-top: 20px;
}

.tab-bar {
  border-bottom: solid 2px #2b2e38;
  font-family: Roboto;
  font-weight: bold;
  letter-spacing: 1px;
  width: 100%;

  @include vw-pixels(('font-size': 18,
      'padding-bottom': 30), $vwPixelsScale);

  @media #{$mobileOnly} {
    @include vw-pixels(('font-size': 18,
        'padding-bottom': 30));
  }

  @media #{$macroScreens} {
    font-size: 18px;
    padding-bottom: 30px
  }

  .tab-span {
    cursor: pointer;

    @include vw-pixels(('margin-right': 50), $vwPixelsScale);

    @media #{$mobileOnly} {
      @include vw-pixels(('margin-right': 50));
    }

    @media #{$macroScreens} {
      margin-right: 50px
    }

    &.active {
      border-bottom: solid 2px #a142ff;
      color: #a142ff
    }
  }
}

.custom-dialog-title {
  text-transform: none;
  font-weight: 800;
  margin: 0 0 -5px 0;
  font-size: 20px;
}

.platform-twitch-btn {
  background: #242424;
  border: none;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 8px;
  width: auto;
}

.platform-twitch-btn.active {
  background: #47BCD3;
}

.platform-twitch-btn-icon {
  width: 20px;
  height: 20px;
  fill: currentColor;
}

.SecondRowNew {
  display: flex;
  flex-direction: row;
  align-items: center;

  .SecondRowNew__left {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    background-color: #00B0F0;
    border-radius: 6px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    padding: 10px 30px;
  }

  .SecondRowNew__right {
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    background-color: #C00000;
    border-radius: 6px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    padding: 10px 15px;
  }

  .SecondRowNew__test1 {
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    background-color: #87C641;
    border-radius: 6px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    padding: 10px 15px;
  }

  .SecondRowNew__test2 {
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    background-color: #87C641;
    border-radius: 6px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    padding: 10px 15px;
  }
}

.actions_edit_amount {
  background-color: #242424;
  // padding: 10px;
  width: auto;
}

.dialogTitle {
  color: #4FD1C5;
  padding: 10px;
  font-weight: bolder;
  text-align: center;
  font-size: 45px;
  line-height: 1.5;
  font-family: "Roboto", "Arial", sans-serif;
}