@import "../../utils/_variables";

.CCQuests3 {
  margin: 24px;

  .CCQuests__create--btn {
    width: 300px;
    height: 50px;
    letter-spacing: 1.2px;
    margin: 20px 0 20px 0;
  }

  .ToggleTable {
    margin-bottom: 30px;

    .ToggleTable__header {
      background: #2b2e38;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 30px;
      border-bottom: 1px solid #454545;
      border-top: 1px solid #454545;

      .ToggleTable__header-title {
        padding: 0;
        margin: 0;
        font-size: 22px;
        letter-spacing: 2.5px;
      }

      .dot-status {
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background-color: #ef3937;
        box-shadow: 0 0 9px 0 #ff987f;
        margin-left: 10px;
      }

      i {
        font-size: 30px;
        line-height: 18px;
        margin-left: auto;
      }
    }

    .ToggleTable__header__right {
      display: flex;
      margin-left: auto;
      margin-top: 6px;
      justify-content: flex-end;
      align-items: center;
      font-size: 14px;


      button {
        margin-right: 4px;
        border-radius: 4px;
        padding: 10px 20px;
        background: #202227;
        color: #FFF;
        border: none;
        outline: none;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 22px;
        letter-spacing: 1.2px;
        cursor: pointer;
        margin-left: 10px;

        &:hover {
          background: rgba(255, 255, 255, 0.2);
        }
      }

      i {
        margin-left: auto;
      }
    }

    .ToggleTable__table-wrapper {
      background: #202227;
      padding: 20px;

      .no-results {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 44px;
        margin-top: 30px;
      }

      .row {
        border-bottom: 1px solid #1e1f25;
        padding: 0 10px;

        &:hover {
          background: rgba(255, 255, 255, 0.025);

          &:first-child {
            background: rgba(255, 255, 255, 0);
          }
        }

        &:last-child {
          border-bottom: none;
        }

        .table-cell {
          justify-content: center;

          &.headline {
            color: #9b9b9b;
            font-weight: 400;
            letter-spacing: 1.2px;
            font-size: 14px;
          }

          &:first-child {
            justify-content: flex-start;
          }

          &:last-child {
            justify-content: flex-end;
          }

          .remove-key {
            i {
              margin-right: 0;
            }

            display: flex;
            align-items: center;
            background: rgba(255, 255, 255, 0.05);
            border: none;
            padding: 5px 8px;
            border-radius: 3px;
            color: #FFF;
            line-height: 1;
            letter-spacing: 1px;
            font-weight: 600;
            text-transform: uppercase;
            cursor: pointer;
            font-size: 11px;
            text-decoration: none;
            margin-right: 5px;

            &:last-child {
              margin-right: 0;
            }

            &:hover {
              background: rgba(255, 255, 255, .2);
            }
          }

          .removing-key {
            background: rgba(255, 255, 255, 0);
            border: none;
            padding: 5px 8px;
            border-radius: 3px;
            color: #e91e63;
            letter-spacing: 1px;
            font-weight: 600;
            text-transform: uppercase;
            font-size: 11px;
            text-decoration: none;
            cursor: pointer;
          }
        }
      }

      .MyQuestPage__totalEarned--firstTop {
        margin-top: 20px;

        i {
          color: $colorLifeGreen;
          font-size: 20px;
        }

        .balance_max {
          color: $colorLifeGreen;
          font-size: 50px;
          margin-left: 10px;
          font-weight: bolder;
          display: inline;
        }

        a {
          text-decoration: none;
          margin-left: 360px;

          button {
            background-image: $purpleGradient;
            color: #ffffff;
            font-weight: 900;
            font-size: 16px;
            padding: 10px 30px;
            line-height: 34px;
            border-radius: 26px;
            cursor: pointer;
            display: inline;
            position: relative;
            margin-left: 0px;
            width: 220px;
            font-family: 'Roboto', sans-serif !important;
          }
        }
      }
    }

    .NoQuests {
      padding: 10px 30px;
      text-align: center;
      font-size: 16px;
    }

    .group_1 {
      background-color: rgba(89, 82, 97, 1);
      width: 100%;
      height: 2px;
      margin-top: 28px;
    }

    .group_2 {
      background-color: rgba(44, 39, 51, 1);
      border-radius: 10px 10px 9px 9px;
      width: 100%;
      height: 152px;
      margin: 6px 0px 16px 0px;
      cursor: pointer;
    }

    .group_3 {
      background-color: rgba(48, 31, 42, 1);
      border-radius: 9px;
      width: 132px;
      height: 132px;
      margin: 10px 0 0 12px;
    }

    .group_4 {
      background-color: #211C27;
      border-radius: 10px 10px 9px 9px;
      width: 2px;
      height: 114px;
      margin: 19px 0 0 41px;
    }

    .group_5 {
      background-color: #211C27;
      border-radius: 10px 10px 9px 9px;
      width: 2px;
      height: 114px;
      margin: 19px 0 0 54px;
    }

    .group_6 {
      background-color: #211C27;
      border-radius: 10px 10px 9px 9px;
      width: 2px;
      height: 114px;
      margin: 19px 0 0 36px;
    }

    .text-wrapper_4 {
      width: 336px;
      height: 53px;
      margin: 53px 0 0 28px;
    }

    .text-wrapper_5 {
      width: 70px;
      height: 15px;
      overflow-wrap: break-word;
      font-size: 0;
      font-family: Adobe Heiti Std R;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
      margin: 68px 0 0 47px;
    }

    .text-wrapper_6 {
      width: 15px;
      height: 13px;
      overflow-wrap: break-word;
      font-size: 0;
      font-family: ArialMT;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
      margin: 69px 0 0 34px;
    }

    .text-wrapper_7 {
      height: 36px;
      width: 107px;
      margin: 58px auto 0;
      a{
        font-size:14px;
        color:#5A575D;
        cursor: pointer;
        background: none;
        margin:10px 0px 0px 0px;
        text-decoration:underline;
        font-weight: 600;
        width: 107px;
        text-align: center;
      }
    }

    .text_5 {
      width: 100%;
      height: 24px;
      overflow-wrap: break-word;
      color: rgba(253, 253, 253, 1);
      font-size: 24px;
      font-family: Arial-BoldMT;
      font-weight: 700;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .text_6 {
      width: 100%;
      //height: 18px;
      overflow-wrap: break-word;
      color: rgba(205, 204, 208, 1);
      font-size: 14px;
      font-family: ArialMT;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
      margin: 16px 0 0 2px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .text_7 {
      width: 70px;
      height: 15px;
      overflow-wrap: break-word;
      color: rgba(255, 175, 201, 1);
      font-size: 18px;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
    }

    .text_8 {
      width: 70px;
      height: 15px;
      overflow-wrap: break-word;
      color: rgba(255, 175, 201, 1);
      font-size: 18px;
      font-family: Arial-BoldMT;
      font-weight: 700;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
    }

    .text_9 {
      width: 15px;
      height: 13px;
      overflow-wrap: break-word;
      color: rgba(205, 204, 208, 1);
      font-size: 12px;
      font-family: ArialMT;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
    }

    .text_10 {
      width: 15px;
      height: 13px;
      overflow-wrap: break-word;
      color: rgba(205, 204, 208, 1);
      font-size: 18px;
      font-family: ArialMT;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
    }

    .text_11 {
      overflow-wrap: break-word;
      color: rgba(241, 145, 51, 1);
      background: #F1913320;
      padding: 4px 12px;
      border-radius: 16px;
      font-size: 18px;
      font-family: Adobe Heiti Std R;
      font-weight: normal;
      text-align: center;
      white-space: nowrap;
      //margin: 9px 0 0 17px;
      width:107px;
      cursor: default;
    }

    .group_7 {
      background-color: rgba(67, 60, 74, 0.6);
      width: 100%;
      height: 422px;
      position: relative;
      top: -20px;
    }

    .text-wrapper_8 {
      width: 256px;
      height: 17px;
      margin: 54px 0 0 144px;
    }

    .text_12 {
      width: 98px;
      height: 16px;
      overflow-wrap: break-word;
      color: rgba(139, 138, 144, 1);
      font-size: 16px;
      font-family: ArialMT;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
      margin-top: 1px;
    }

    .text_13 {
      width: 144px;
      height: 13px;
      overflow-wrap: break-word;
      color: rgba(205, 204, 208, 1);
      font-size: 16px;
      font-family: ArialMT;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
      margin-left: 30px;
    }

    .text-wrapper_9 {
      width: 257px;
      height: 14px;
      margin: 16px 0 0 143px;
    }

    .text_14 {
      width: 65px;
      height: 13px;
      overflow-wrap: break-word;
      color: rgba(139, 138, 144, 1);
      font-size: 16px;
      font-family: ArialMT;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
      margin-top: 1px;
    }

    .text_15 {
      width: 144px;
      height: 13px;
      overflow-wrap: break-word;
      color: rgba(205, 204, 208, 1);
      font-size: 16px;
      font-family: ArialMT;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
      margin-left: 63px;
    }

    .text-wrapper_10 {
      width: 283px;
      height: 15px;
      margin: 17px 0 0 143px;
    }

    .text_16 {
      width: 84px;
      height: 13px;
      overflow-wrap: break-word;
      color: rgba(139, 138, 144, 1);
      font-size: 16px;
      font-family: ArialMT;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
      margin-top: 1px;
    }

    .text_17 {
      width: 169px;
      height: 15px;
      overflow-wrap: break-word;
      color: rgba(205, 204, 208, 1);
      font-size: 16px;
      font-family: ArialMT;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
      margin-left: 45px;
    }

    .section_1 {
      background-color: rgba(239, 57, 55, 1);
      height: 2px;
      margin: 30px 0 0 144px;
    }

    .text_18 {
      width: 148px;
      height: 14px;
      overflow-wrap: break-word;
      color: rgba(139, 138, 144, 1);
      font-size: 14px;
      font-family: ArialMT;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
      margin: 23px 0 0 146px;
    }

    .section_2 {
      height: 54px;
      margin: 22px 0 0 140px;
      width: 80%;
    }

    .group_8 {
      background-color: rgba(33, 28, 39, 0.9);
      border-radius: 9px;
      width: 40%;
      height: 54px;
    }

    .group_9 {
      background-color: rgba(239, 57, 55, 1);
      border-radius: 50%;
      width: 10px;
      height: 10px;
      border: 1px solid rgba(255, 255, 255, 1);
      margin: 22px 0 0 12px;
    }

    .text-wrapper_11 {
      background-color: rgba(239, 57, 55, 1);
      border-radius: 1px;
      height: 24px;
      width: 24px;
      margin: 16px 0 0 6px;
    }

    .text_19 {
      width: 24px;
      height: 7px;
      overflow-wrap: break-word;
      color: rgba(253, 253, 253, 1);
      font-size: 8px;
      font-family: ArialMT;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 9px;
      margin: 7px 0 0 1px;
    }

    .text-wrapper_12 {
      width: 210px;
      height: 18px;
      overflow-wrap: break-word;
      font-size: 0;
      font-family: ArialMT;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
      margin: 18px 169px 0 14px;
    }

    .text_20 {
      width: 210px;
      height: 18px;
      overflow-wrap: break-word;
      color: rgba(239, 57, 55, 1);
      font-size: 16px;
      font-family: ArialMT;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
    }

    .text_21 {
      width: 210px;
      height: 18px;
      overflow-wrap: break-word;
      color: rgba(239, 57, 55, 1);
      font-size: 16px;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
    }

    .text_22 {
      width: 210px;
      height: 18px;
      overflow-wrap: break-word;
      color: rgba(239, 57, 55, 1);
      font-size: 16px;
      font-family: ArialMT;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
    }

    .group_10 {
      background-color: rgba(33, 28, 39, 0.9);
      border-radius: 9px;
      width: 40%;
      height: 54px;
    }

    .box_4 {
      background-color: rgba(43, 38, 49, 1);
      border-radius: 50%;
      width: 10px;
      height: 10px;
      border: 1px solid rgba(255, 255, 255, 0.52);
      margin: 22px 0 0 12px;
    }

    .image-text_1 {
      width: 84px;
      height: 21px;
      margin: 16px 330px 0 6px;
    }

    .box_5 {
      background-color: rgba(0, 41, 134, 1);
      border-radius: 1px;
      font-size: 8px;
      width: 24px;
      height: 24px;
      line-height: 24px;
    }

    .text-group_1 {
      width: 49px;
      height: 16px;
      overflow-wrap: break-word;
      color: #fff;
      font-size: 16px;
      font-family: ArialMT;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
      margin-top: 4px;
    }

    .section_3 {
      width: 698px;
      height: 40px;
      margin: 57px 0 47px 168px;
    }

    .text-wrapper_13 {
      height: 40px;
      background-color: #ef3937;
      border-radius: 20px;
      width: 334px;
      cursor: pointer;
      margin-left:100px;
    }

    .text_23 {
      width: 148px;
      height: 18px;
      overflow-wrap: break-word;
      color: rgba(255, 255, 255, 1);
      font-size: 18px;
      font-weight: normal;
      font-family: Arial;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
      margin: 12px 0 0 93px;
    }

    .text-wrapper_14 {
      height: 40px;
      background-color: #595261;
      border-radius: 20px;
      width: 334px;
      margin-right:100px;
      cursor: pointer;
    }

    .text_24 {
      width: 149px;
      height: 15px;
      overflow-wrap: break-word;
      color: rgba(255, 255, 255, 1);
      font-size: 18px;
      font-family: Arial;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
      margin: 12px 0 0 92px;
    }

    .group_11 {
      box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.66);
      background-color: rgba(44, 39, 51, 1);
      border-radius: 10px 10px 9px 9px;
      width: 1158px;
      height: 152px;
      margin: 20px 0 0 26px;
    }

    .box_6 {
      background-color: rgba(248, 231, 42, 1);
      border-radius: 9px;
      width: 132px;
      height: 132px;
      margin: 10px 0 0 12px;
    }

    .text-group_2 {
      width: 160px;
      height: 53px;
      margin: 53px 0 0 28px;
    }

    .text_25 {
      width: 160px;
      height: 24px;
      overflow-wrap: break-word;
      color: rgba(253, 253, 253, 1);
      font-size: 24px;
      font-family: Arial-BoldMT;
      font-weight: 700;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
    }

    .text_26 {
      width: 134px;
      height: 13px;
      overflow-wrap: break-word;
      color: rgba(205, 204, 208, 1);
      font-size: 14px;
      font-family: ArialMT;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
      margin: 16px 0 0 2px;
    }

    .box_7 {
      box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.66);
      background-color: rgba(33, 28, 39, 1);
      border-radius: 10px 10px 9px 9px;
      width: 2px;
      height: 114px;
      margin: 19px 0 0 117px;
    }

    .text-wrapper_15 {
      width: 70px;
      height: 15px;
      overflow-wrap: break-word;
      font-size: 0;
      font-family: Adobe Heiti Std R;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
      margin: 68px 0 0 47px;
    }

    .text_27 {
      width: 70px;
      height: 15px;
      overflow-wrap: break-word;
      color: rgba(255, 175, 201, 1);
      font-size: 18px;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
    }

    .text_28 {
      width: 70px;
      height: 15px;
      overflow-wrap: break-word;
      color: rgba(255, 175, 201, 1);
      font-size: 18px;
      font-family: Arial-BoldMT;
      font-weight: 700;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
    }

    .box_8 {
      box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.66);
      background-color: rgba(33, 28, 39, 1);
      border-radius: 10px 10px 9px 9px;
      width: 2px;
      height: 114px;
      margin: 19px 0 0 54px;
    }

    .text-wrapper_16 {
      width: 15px;
      height: 13px;
      overflow-wrap: break-word;
      font-size: 0;
      font-family: ArialMT;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
      margin: 69px 0 0 64px;
    }

    .text_29 {
      width: 15px;
      height: 13px;
      overflow-wrap: break-word;
      color: rgba(205, 204, 208, 1);
      font-size: 12px;
      font-family: ArialMT;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
    }

    .text_30 {
      width: 15px;
      height: 13px;
      overflow-wrap: break-word;
      color: rgba(205, 204, 208, 1);
      font-size: 18px;
      font-family: ArialMT;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
    }

    .box_9 {
      box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.66);
      background-color: rgba(33, 28, 39, 1);
      border-radius: 10px 10px 9px 9px;
      width: 2px;
      height: 114px;
      margin: 19px 0 0 86px;
    }

    .text-wrapper_17 {
      height: 36px;
      background: url(https://lanhu-oss.lanhuapp.com/psrwdbvwpar4iyyvn2c9gzgr6qir3lcmsqgc767307d-e94b-46b0-9e1e-e9621f1e44b9) 100% no-repeat;
      background-size: 100% 100%;
      width: 107px;
      margin: 58px 136px 0 124px;
    }

    .text_31 {
      overflow-wrap: break-word;
      color: rgba(2, 209, 143, 1);
      background: #02D18F20;
      border-radius: 16px;
      padding: 8px 12px;
      font-size: 18px;
      font-family: Adobe Heiti Std R;
      font-weight: normal;
      text-align: center;
      white-space: nowrap;
      line-height: 18px;
      //margin: 9px 0 0 18px;
      cursor: default;
    }

    .group_12 {
      background-color: rgba(67, 60, 74, 0.6);
      position: relative;
      height: 166px;
      margin: 10px 0 0 0px;
      top: -20px;
    }

    .text-wrapper_18 {
      width: 100%;
      height: 88px;
      overflow-wrap: break-word;
      font-size: 0;
      font-family: Adobe Heiti Std R;
      font-weight: normal;
      text-align: left;
      line-height: 36px;
      margin: 5px 0 0 94px;
    }

    .text_32 {
      width: 230px;
      height: 88px;
      overflow-wrap: break-word;
      color: rgba(139, 138, 144, 1);
      font-size: 16px;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: left;
      line-height: 36px;
    }

    .text_33 {
      width: 230px;
      height: 88px;
      overflow-wrap: break-word;
      color: rgba(205, 204, 208, 1);
      font-size: 16px;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: left;
      line-height: 36px;
    }

    .paragraph_1 {
      width: 230px;
      height: 88px;
      overflow-wrap: break-word;
      color: rgba(205, 204, 208, 1);
      font-size: 16px;
      font-family: ArialMT;
      font-weight: normal;
      text-align: left;
      line-height: 36px;
    }

    .text_34 {
      width: 230px;
      height: 88px;
      overflow-wrap: break-word;
      color: rgba(139, 138, 144, 1);
      font-size: 16px;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: left;
      line-height: 36px;
    }

    .text_35 {
      width: 230px;
      height: 88px;
      overflow-wrap: break-word;
      color: rgba(205, 204, 208, 1);
      font-size: 16px;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: left;
      line-height: 36px;
    }

    .paragraph_2 {
      width: 230px;
      height: 88px;
      overflow-wrap: break-word;
      color: rgba(205, 204, 208, 1);
      font-size: 16px;
      font-family: ArialMT;
      font-weight: normal;
      text-align: left;
      line-height: 36px;
    }

    .text_36 {
      width: 230px;
      height: 88px;
      overflow-wrap: break-word;
      color: rgba(139, 138, 144, 1);
      font-size: 16px;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: left;
      line-height: 36px;
    }

    .text_37 {
      width: 230px;
      height: 88px;
      overflow-wrap: break-word;
      color: rgba(205, 204, 208, 1);
      font-size: 16px;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: left;
      line-height: 36px;
    }

    .text_38 {
      width: 230px;
      height: 88px;
      overflow-wrap: break-word;
      color: rgba(205, 204, 208, 1);
      font-size: 16px;
      font-family: ArialMT;
      font-weight: normal;
      text-align: left;
      line-height: 36px;
    }

    .text-wrapper_19 {
      width: 100%;
      height: 90px;
      overflow-wrap: break-word;
      font-size: 0;
      font-family: Adobe Heiti Std R;
      font-weight: normal;
      text-align: left;
      line-height: 36px;
      margin: 5px 9px 0 31px;
    }

    .text_39 {
      width: 225px;
      height: 90px;
      overflow-wrap: break-word;
      color: rgba(139, 138, 144, 1);
      font-size: 16px;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: left;
      line-height: 36px;
    }

    .paragraph_3 {
      width: 225px;
      height: 90px;
      overflow-wrap: break-word;
      color: rgba(205, 204, 208, 1);
      font-size: 16px;
      font-family: ArialMT;
      font-weight: normal;
      text-align: left;
      line-height: 36px;
    }

    .text_40 {
      width: 225px;
      height: 90px;
      overflow-wrap: break-word;
      color: rgba(139, 138, 144, 1);
      font-size: 16px;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: left;
      line-height: 36px;
    }

    .text_41 {
      width: 225px;
      height: 90px;
      overflow-wrap: break-word;
      color: rgba(205, 204, 208, 1);
      font-size: 16px;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: left;
      line-height: 36px;
    }

    .paragraph_4 {
      width: 225px;
      height: 90px;
      overflow-wrap: break-word;
      color: rgba(205, 204, 208, 1);
      font-size: 16px;
      font-family: ArialMT;
      font-weight: normal;
      text-align: left;
      line-height: 36px;
    }

    .text_42 {
      width: 225px;
      height: 90px;
      overflow-wrap: break-word;
      color: rgba(139, 138, 144, 1);
      font-size: 16px;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: left;
      line-height: 36px;
    }

    .text_43 {
      width: 225px;
      height: 90px;
      overflow-wrap: break-word;
      color: rgba(205, 204, 208, 1);
      font-size: 16px;
      font-family: ArialMT;
      font-weight: normal;
      text-align: left;
      line-height: 36px;
    }

    .section_4 {
      box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.66);
      background-color: rgba(67, 60, 74, 1);
      border-radius: 10px 10px 9px 9px;
      position: absolute;
      left: 0;
      top: -128px;
      width: 1158px;
      height: 152px;
    }

    .block_1 {
      background-color: rgba(248, 231, 42, 1);
      border-radius: 9px;
      width: 132px;
      height: 132px;
      margin: 10px 0 0 12px;
    }

    .text-group_3 {
      width: 160px;
      height: 53px;
      margin: 53px 0 0 28px;
    }

    .text_44 {
      width: 160px;
      height: 24px;
      overflow-wrap: break-word;
      color: rgba(253, 253, 253, 1);
      font-size: 24px;
      font-family: Arial-BoldMT;
      font-weight: 700;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
    }

    .text_45 {
      width: 134px;
      height: 13px;
      overflow-wrap: break-word;
      color: rgba(205, 204, 208, 1);
      font-size: 14px;
      font-family: ArialMT;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
      margin: 16px 0 0 2px;
    }

    .block_2 {
      box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.66);
      background-color: rgba(33, 28, 39, 0.3);
      border-radius: 10px 10px 9px 9px;
      width: 2px;
      height: 114px;
      margin: 19px 0 0 117px;
    }

    .text-wrapper_20 {
      width: 70px;
      height: 15px;
      overflow-wrap: break-word;
      font-size: 0;
      font-family: Adobe Heiti Std R;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
      margin: 68px 0 0 47px;
    }

    .text_46 {
      width: 70px;
      height: 15px;
      overflow-wrap: break-word;
      color: rgba(255, 175, 201, 1);
      font-size: 18px;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
    }

    .text_47 {
      width: 70px;
      height: 15px;
      overflow-wrap: break-word;
      color: rgba(255, 175, 201, 1);
      font-size: 18px;
      font-family: Arial-BoldMT;
      font-weight: 700;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
    }

    .block_3 {
      box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.66);
      background-color: rgba(33, 28, 39, 0.3);
      border-radius: 10px 10px 9px 9px;
      width: 2px;
      height: 114px;
      margin: 19px 0 0 54px;
    }

    .text-wrapper_21 {
      width: 15px;
      height: 13px;
      overflow-wrap: break-word;
      font-size: 0;
      font-family: ArialMT;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
      margin: 69px 0 0 64px;
    }

    .text_48 {
      width: 15px;
      height: 13px;
      overflow-wrap: break-word;
      color: rgba(205, 204, 208, 1);
      font-size: 12px;
      font-family: ArialMT;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
    }

    .text_49 {
      width: 15px;
      height: 13px;
      overflow-wrap: break-word;
      color: rgba(205, 204, 208, 1);
      font-size: 18px;
      font-family: ArialMT;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
    }

    .block_4 {
      box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.66);
      background-color: rgba(33, 28, 39, 0.3);
      border-radius: 10px 10px 9px 9px;
      width: 2px;
      height: 114px;
      margin: 19px 0 0 86px;
    }

    .text-wrapper_22 {
      height: 36px;
      background: url(https://lanhu-oss.lanhuapp.com/psr1ovzc56zv5oo90d0fx4t0gmfm576nucd11a6957-08f5-45b5-b15f-609cee7c0f33) 100% no-repeat;
      background-size: 100% 100%;
      width: 107px;
      margin: 58px 136px 0 124px;
    }

    .text_50 {
      width: 71px;
      height: 18px;
      overflow-wrap: break-word;
      color: rgba(2, 209, 143, 1);
      font-size: 18px;
      font-family: Adobe Heiti Std R;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
      margin: 9px 0 0 18px;
    }

    .group_13 {
      box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.66);
      background-color: rgba(44, 39, 51, 1);
      border-radius: 10px 10px 9px 9px;
      width: 1158px;
      height: 152px;
      margin: 20px 0 0 26px;
    }

    .block_5 {
      background-color: rgba(248, 231, 42, 1);
      border-radius: 9px;
      width: 132px;
      height: 132px;
      margin: 10px 0 0 12px;
    }

    .text-wrapper_23 {
      width: 219px;
      height: 53px;
      margin: 53px 0 0 27px;
    }

    .text_51 {
      width: 219px;
      height: 23px;
      overflow-wrap: break-word;
      color: rgba(253, 253, 253, 1);
      font-size: 24px;
      font-family: Arial-BoldMT;
      font-weight: 700;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
    }

    .text_52 {
      width: 134px;
      height: 13px;
      overflow-wrap: break-word;
      color: rgba(205, 204, 208, 1);
      font-size: 14px;
      font-family: ArialMT;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
      margin: 17px 0 0 3px;
    }

    .block_6 {
      box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.66);
      background-color: rgba(33, 28, 39, 1);
      border-radius: 10px 10px 9px 9px;
      width: 2px;
      height: 114px;
      margin: 19px 0 0 59px;
    }

    .text-wrapper_24 {
      width: 70px;
      height: 15px;
      overflow-wrap: break-word;
      font-size: 0;
      font-family: Adobe Heiti Std R;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
      margin: 68px 0 0 47px;
    }

    .text_53 {
      width: 70px;
      height: 15px;
      overflow-wrap: break-word;
      color: rgba(255, 175, 201, 1);
      font-size: 18px;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
    }

    .text_54 {
      width: 70px;
      height: 15px;
      overflow-wrap: break-word;
      color: rgba(255, 175, 201, 1);
      font-size: 18px;
      font-family: Arial-BoldMT;
      font-weight: 700;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
    }

    .block_7 {
      box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.66);
      background-color: rgba(33, 28, 39, 1);
      border-radius: 10px 10px 9px 9px;
      width: 2px;
      height: 114px;
      margin: 19px 0 0 54px;
    }

    .text-wrapper_25 {
      width: 15px;
      height: 13px;
      overflow-wrap: break-word;
      font-size: 0;
      font-family: ArialMT;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
      margin: 69px 0 0 64px;
    }

    .text_55 {
      width: 15px;
      height: 13px;
      overflow-wrap: break-word;
      color: rgba(205, 204, 208, 1);
      font-size: 12px;
      font-family: ArialMT;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
    }

    .text_56 {
      width: 15px;
      height: 13px;
      overflow-wrap: break-word;
      color: rgba(205, 204, 208, 1);
      font-size: 18px;
      font-family: ArialMT;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
    }

    .block_8 {
      box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.66);
      background-color: rgba(33, 28, 39, 1);
      border-radius: 10px 10px 9px 9px;
      width: 2px;
      height: 114px;
      margin: 19px 0 0 86px;
    }

    .text-wrapper_26 {
      height: 36px;
      background: url(https://lanhu-oss.lanhuapp.com/pspjn7sbpswujqjt8qp7xz3b9retxoocnob2bc91d6e-cda7-4e08-b61e-490b896d5a52) 100% no-repeat;
      background-size: 100% 100%;
      width: 107px;
      margin: 58px 136px 0 124px;
    }

    .text_57 {
      width: 107px;
      overflow-wrap: break-word;
      color: rgba(254, 64, 77, 1);
      background: #FE404D20;
      padding: 8px 12px;
      border-radius: 16px;
      font-size: 18px;
      font-family: Adobe Heiti Std R;
      font-weight: normal;
      text-align: center;
      white-space: nowrap;
      line-height: 18px;
      //margin: 9px 0 0 11px;
      cursor: default;
    }

    .group_14 {
      background-color: rgba(67, 60, 74, 0.6);
      position: relative;
      height: 127px;
      margin: 10px 0 0 0px;
      top: -20px;
    }

    .text-wrapper_27 {
      width: 630px;
      height: 52px;
      overflow-wrap: break-word;
      font-size: 0;
      font-family: Adobe Heiti Std R;
      font-weight: normal;
      text-align: left;
      line-height: 36px;
      margin: 5px 0 0 142px;
    }

    .text_58 {
      width: 230px;
      height: 52px;
      overflow-wrap: break-word;
      color: rgba(139, 138, 144, 1);
      font-size: 16px;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: left;
      line-height: 36px;
    }

    .text_59 {
      width: 230px;
      height: 52px;
      overflow-wrap: break-word;
      color: rgba(205, 204, 208, 1);
      font-size: 16px;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: left;
      line-height: 36px;
    }

    .paragraph_5 {
      width: 230px;
      height: 52px;
      overflow-wrap: break-word;
      color: rgba(205, 204, 208, 1);
      font-size: 16px;
      font-family: ArialMT;
      font-weight: normal;
      text-align: left;
      line-height: 36px;
    }

    .text_60 {
      width: 230px;
      height: 52px;
      overflow-wrap: break-word;
      color: rgba(139, 138, 144, 1);
      font-size: 16px;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: left;
      line-height: 36px;
    }

    .text_61 {
      width: 230px;
      height: 52px;
      overflow-wrap: break-word;
      color: rgba(205, 204, 208, 1);
      font-size: 16px;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: left;
      line-height: 36px;
    }

    .text_62 {
      width: 230px;
      height: 52px;
      overflow-wrap: break-word;
      color: rgba(205, 204, 208, 1);
      font-size: 16px;
      font-family: ArialMT;
      font-weight: normal;
      text-align: left;
      line-height: 36px;
    }

    .text-wrapper_28 {
      width: 625px;
      height: 52px;
      overflow-wrap: break-word;
      font-size: 0;
      font-family: Adobe Heiti Std R;
      font-weight: normal;
      text-align: left;
      line-height: 36px;
      margin: 5px 9px 0 21px;
    }

    .text_63 {
      width: 225px;
      height: 52px;
      overflow-wrap: break-word;
      color: rgba(139, 138, 144, 1);
      font-size: 16px;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: left;
      line-height: 36px;
    }

    .paragraph_6 {
      width: 225px;
      height: 52px;
      overflow-wrap: break-word;
      color: rgba(205, 204, 208, 1);
      font-size: 16px;
      font-family: ArialMT;
      font-weight: normal;
      text-align: left;
      line-height: 36px;
    }

    .text_64 {
      width: 225px;
      height: 52px;
      overflow-wrap: break-word;
      color: rgba(139, 138, 144, 1);
      font-size: 16px;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: left;
      line-height: 36px;
    }

    .text_65 {
      width: 225px;
      height: 52px;
      overflow-wrap: break-word;
      color: rgba(205, 204, 208, 1);
      font-size: 16px;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: left;
      line-height: 36px;
    }

    .block_9 {
      box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.66);
      background-color: rgba(67, 60, 74, 1);
      border-radius: 10px 10px 9px 9px;
      position: absolute;
      left: -2px;
      top: -128px;
      width: 1158px;
      height: 152px;
    }

    .section_5 {
      background-color: rgba(248, 231, 42, 1);
      border-radius: 9px;
      width: 132px;
      height: 132px;
      margin: 10px 0 0 12px;
    }

    .text-wrapper_29 {
      width: 219px;
      height: 53px;
      margin: 53px 0 0 27px;
    }

    .text_66 {
      width: 219px;
      height: 23px;
      overflow-wrap: break-word;
      color: rgba(253, 253, 253, 1);
      font-size: 24px;
      font-family: Arial-BoldMT;
      font-weight: 700;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
    }

    .text_67 {
      width: 134px;
      height: 13px;
      overflow-wrap: break-word;
      color: rgba(205, 204, 208, 1);
      font-size: 14px;
      font-family: ArialMT;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
      margin: 17px 0 0 3px;
    }

    .section_6 {
      box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.66);
      background-color: rgba(33, 28, 39, 0.3);
      border-radius: 10px 10px 9px 9px;
      width: 2px;
      height: 114px;
      margin: 19px 0 0 59px;
    }

    .text-wrapper_30 {
      width: 70px;
      height: 15px;
      overflow-wrap: break-word;
      font-size: 0;
      font-family: Adobe Heiti Std R;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
      margin: 68px 0 0 47px;
    }

    .text_68 {
      width: 70px;
      height: 15px;
      overflow-wrap: break-word;
      color: rgba(255, 175, 201, 1);
      font-size: 18px;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
    }

    .text_69 {
      width: 70px;
      height: 15px;
      overflow-wrap: break-word;
      color: rgba(255, 175, 201, 1);
      font-size: 18px;
      font-family: Arial-BoldMT;
      font-weight: 700;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
    }

    .section_7 {
      box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.66);
      background-color: rgba(33, 28, 39, 0.3);
      border-radius: 10px 10px 9px 9px;
      width: 2px;
      height: 114px;
      margin: 19px 0 0 54px;
    }

    .text-wrapper_31 {
      width: 15px;
      height: 13px;
      overflow-wrap: break-word;
      font-size: 0;
      font-family: ArialMT;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
      margin: 69px 0 0 64px;
    }

    .text_70 {
      width: 15px;
      height: 13px;
      overflow-wrap: break-word;
      color: rgba(205, 204, 208, 1);
      font-size: 12px;
      font-family: ArialMT;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
    }

    .text_71 {
      width: 15px;
      height: 13px;
      overflow-wrap: break-word;
      color: rgba(205, 204, 208, 1);
      font-size: 18px;
      font-family: ArialMT;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
    }

    .section_8 {
      box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.66);
      background-color: rgba(33, 28, 39, 0.3);
      border-radius: 10px 10px 9px 9px;
      width: 2px;
      height: 114px;
      margin: 19px 0 0 86px;
    }

    .text-wrapper_32 {
      height: 36px;
      background: url(https://lanhu-oss.lanhuapp.com/psx3uk3l6d6ffamdukr9z676e6k3ka6hg5e4ff4ee-ce11-461f-8200-6aadde1c8338) 100% no-repeat;
      background-size: 100% 100%;
      width: 107px;
      margin: 58px 136px 0 124px;
    }

    .text_72 {
      width: 51px;
      height: 18px;
      overflow-wrap: break-word;
      color: rgba(254, 64, 77, 1);
      font-size: 18px;
      font-family: Adobe Heiti Std R;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
      margin: 9px 0 0 29px;
    }

    .group_15 {
      box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.66);
      background-color: rgba(44, 39, 51, 1);
      border-radius: 10px 10px 9px 9px;
      width: 1158px;
      height: 152px;
      margin: 20px 0 0 26px;
    }

    .group_16 {
      background-color: rgba(248, 231, 42, 1);
      border-radius: 9px;
      width: 132px;
      height: 132px;
      margin: 10px 0 0 12px;
    }

    .text-group_4 {
      width: 136px;
      height: 53px;
      margin: 53px 0 0 28px;
    }

    .text_73 {
      width: 123px;
      height: 24px;
      overflow-wrap: break-word;
      color: rgba(253, 253, 253, 1);
      font-size: 24px;
      font-family: Arial-BoldMT;
      font-weight: 700;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
    }

    .text_74 {
      width: 134px;
      height: 13px;
      overflow-wrap: break-word;
      color: rgba(205, 204, 208, 1);
      font-size: 14px;
      font-family: ArialMT;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
      margin: 16px 0 0 2px;
    }

    .group_17 {
      box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.66);
      background-color: rgba(33, 28, 39, 1);
      border-radius: 10px 10px 9px 9px;
      width: 2px;
      height: 114px;
      margin: 18px 0 0 141px;
    }

    .text-wrapper_33 {
      width: 70px;
      height: 15px;
      overflow-wrap: break-word;
      font-size: 0;
      font-family: Adobe Heiti Std R;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
      margin: 68px 0 0 47px;
    }

    .text_75 {
      width: 70px;
      height: 15px;
      overflow-wrap: break-word;
      color: rgba(255, 175, 201, 1);
      font-size: 18px;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
    }

    .text_76 {
      width: 70px;
      height: 15px;
      overflow-wrap: break-word;
      color: rgba(255, 175, 201, 1);
      font-size: 18px;
      font-family: Arial-BoldMT;
      font-weight: 700;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
    }

    .group_18 {
      box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.66);
      background-color: rgba(33, 28, 39, 1);
      border-radius: 10px 10px 9px 9px;
      width: 2px;
      height: 114px;
      margin: 18px 0 0 54px;
    }

    .text-wrapper_34 {
      width: 15px;
      height: 13px;
      overflow-wrap: break-word;
      font-size: 0;
      font-family: ArialMT;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
      margin: 69px 0 0 64px;
    }

    .text_77 {
      width: 15px;
      height: 13px;
      overflow-wrap: break-word;
      color: rgba(205, 204, 208, 1);
      font-size: 12px;
      font-family: ArialMT;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
    }

    .text_78 {
      width: 15px;
      height: 13px;
      overflow-wrap: break-word;
      color: rgba(205, 204, 208, 1);
      font-size: 18px;
      font-family: ArialMT;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
    }

    .group_19 {
      box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.66);
      background-color: rgba(33, 28, 39, 1);
      border-radius: 10px 10px 9px 9px;
      width: 2px;
      height: 114px;
      margin: 18px 0 0 86px;
    }

    .text-wrapper_35 {
      height: 36px;
      background: url(https://lanhu-oss.lanhuapp.com/ps18bbou1hpzwl41afzq0esn1y6dhli07yf7e2f8ec6-e74f-49af-a2c3-a05bde01ccd8) 100% no-repeat;
      background-size: 100% 100%;
      width: 107px;
      margin: 58px 136px 0 124px;
    }

    .text_79 {
      width: 54px;
      height: 18px;
      overflow-wrap: break-word;
      color: rgba(241, 145, 51, 1);
      font-size: 18px;
      font-family: Adobe Heiti Std R;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
      margin: 9px 0 0 27px;
    }

    .group_20 {
      background-color: rgba(239, 57, 55, 1);
      border-radius: 10px;
      width: 1215px;
      height: 2px;
      margin-top: 98px;
    }

    .group_21 {
      box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.66);
      background-color: rgba(67, 60, 74, 1);
      border-radius: 10px 10px 9px 9px;
      position: absolute;
      left: 26px;
      top: 294px;
      width: 1158px;
      height: 152px;
    }

    .box_10 {
      background-color: rgba(248, 231, 42, 1);
      border-radius: 9px;
      width: 132px;
      height: 132px;
      margin: 10px 0 0 12px;
    }

    .text-group_5 {
      width: 136px;
      height: 53px;
      margin: 53px 0 0 28px;
    }

    .text_80 {
      width: 115px;
      height: 24px;
      overflow-wrap: break-word;
      color: rgba(253, 253, 253, 1);
      font-size: 24px;
      font-family: Arial-BoldMT;
      font-weight: 700;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
    }

    .text_81 {
      width: 134px;
      height: 13px;
      overflow-wrap: break-word;
      color: rgba(205, 204, 208, 1);
      font-size: 14px;
      font-family: ArialMT;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
      margin: 16px 0 0 2px;
    }

    .box_11 {
      box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.66);
      background-color: rgba(33, 28, 39, 0.3);
      border-radius: 10px 10px 9px 9px;
      width: 2px;
      height: 114px;
      margin: 19px 0 0 141px;
    }

    .text-wrapper_36 {
      width: 70px;
      height: 15px;
      overflow-wrap: break-word;
      font-size: 0;
      font-family: Adobe Heiti Std R;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
      margin: 68px 0 0 47px;
    }

    .text_82 {
      width: 70px;
      height: 15px;
      overflow-wrap: break-word;
      color: rgba(255, 175, 201, 1);
      font-size: 18px;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
    }

    .text_83 {
      width: 70px;
      height: 15px;
      overflow-wrap: break-word;
      color: rgba(255, 175, 201, 1);
      font-size: 18px;
      font-family: Arial-BoldMT;
      font-weight: 700;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
    }

    .box_12 {
      box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.66);
      background-color: rgba(33, 28, 39, 0.3);
      border-radius: 10px 10px 9px 9px;
      width: 2px;
      height: 114px;
      margin: 19px 0 0 54px;
    }

    .text-wrapper_37 {
      width: 15px;
      height: 13px;
      overflow-wrap: break-word;
      font-size: 0;
      font-family: ArialMT;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
      margin: 69px 0 0 64px;
    }

    .text_84 {
      width: 15px;
      height: 13px;
      overflow-wrap: break-word;
      color: rgba(205, 204, 208, 1);
      font-size: 12px;
      font-family: ArialMT;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
    }

    .text_85 {
      width: 15px;
      height: 13px;
      overflow-wrap: break-word;
      color: rgba(205, 204, 208, 1);
      font-size: 18px;
      font-family: ArialMT;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
    }

    .box_13 {
      box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.66);
      background-color: rgba(33, 28, 39, 0.3);
      border-radius: 10px 10px 9px 9px;
      width: 2px;
      height: 114px;
      margin: 19px 0 0 86px;
    }

    .text-wrapper_38 {
      height: 36px;
      background: url(https://lanhu-oss.lanhuapp.com/ps16j7pqainmdcgm8gdpzo6jhknjwi3hadf631b5a9-c8ef-4ce6-b9c9-51728c8cc44b) 100% no-repeat;
      background-size: 100% 100%;
      width: 107px;
      margin: 58px 136px 0 124px;
    }

    .text_86 {
      width: 54px;
      height: 18px;
      overflow-wrap: break-word;
      color: rgba(241, 145, 51, 1);
      font-size: 18px;
      font-family: Adobe Heiti Std R;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
      margin: 9px 0 0 27px;
    }

    .box_14 {
      background-color: rgba(33, 28, 39, 1);
      position: absolute;
      left: 0;
      top: 2219px;
      width: 1920px;
      height: 342px;
    }

    .block_10 {
      background-color: rgba(50, 45, 56, 1);
      width: 1916px;
      height: 2px;
    }

    .block_11 {
      width: 1196px;
      height: 48px;
      margin: 73px 0 0 266px;
    }

    .image_1 {
      width: 122px;
      height: 46px;
    }

    .text_87 {
      width: 733px;
      height: 16px;
      overflow-wrap: break-word;
      color: rgba(205, 204, 208, 1);
      font-size: 18px;
      font-family: ArialMT;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 18px;
      margin-top: 32px;
    }

    .image_2 {
      width: 196px;
      height: 75px;
      margin: 9px 0 0 229px;
    }

    .block_12 {
      background-color: rgba(50, 45, 56, 1);
      width: 1916px;
      height: 2px;
      margin-top: 62px;
    }

    .text_88 {
      width: 503px;
      height: 14px;
      overflow-wrap: break-word;
      color: rgba(98, 94, 103, 1);
      font-size: 14px;
      font-family: ArialMT;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 14px;
      margin: 18px 0 39px 272px;
    }

    .box_15 {
      background-color: rgba(33, 28, 39, 1);
      position: absolute;
      left: 0;
      top: 0;
      width: 1920px;
      height: 78px;
    }

    .image_3 {
      width: 90px;
      height: 34px;
      margin: 20px 0 0 266px;
    }

    .box_16 {
      width: 65px;
      height: 19px;
      margin: 35px 0 0 71px;
    }

    .text_89 {
      width: 65px;
      height: 13px;
      overflow-wrap: break-word;
      color: rgba(244, 244, 244, 1);
      font-size: 16px;
      font-family: ArialMT;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 16px;
    }

    .box_17 {
      background-color: rgba(250, 54, 51, 1);
      width: 63px;
      height: 2px;
      margin: 4px 0 0 2px;
    }

    .text_90 {
      width: 78px;
      height: 13px;
      overflow-wrap: break-word;
      color: rgba(205, 204, 208, 1);
      font-size: 16px;
      font-family: ArialMT;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 16px;
      margin: 35px 0 0 55px;
    }

    .text-wrapper_39 {
      height: 31px;
      background: url(https://lanhu-oss.lanhuapp.com/psxff8tmob1poo4s0mlv63vbmtieip0igl9b89d568b-62c9-4ff8-8c4d-9fd9af8b69cb) -4px -4px no-repeat;
      background-size: 189px 40px;
      width: 180px;
      margin: 25px 0 0 659px;
    }

    .text_91 {
      width: 106px;
      height: 13px;
      overflow-wrap: break-word;
      color: rgba(205, 204, 208, 1);
      font-size: 16px;
      font-family: ArialMT;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 16px;
      margin: 10px 0 0 35px;
    }

    .box_18 {
      width: 180px;
      height: 31px;
      background: url(https://lanhu-oss.lanhuapp.com/psh75sr1vv6hre6393q307i5xpbqqm1w4vo6373a781-70d4-4a49-8c84-4c86ea936d33) -4px -4px no-repeat;
      background-size: 189px 40px;
      margin: 25px 258px 0 18px;
    }

    .image_4 {
      width: 21px;
      height: 20px;
      margin: 6px 0 0 18px;
    }

    .text_92 {
      width: 89px;
      height: 13px;
      overflow-wrap: break-word;
      color: rgba(205, 204, 208, 1);
      font-size: 16px;
      font-family: ArialMT;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 16px;
      margin: 10px 0 0 10px;
    }

    .flex-row {
      display: flex;
      flex-direction: row;
      flex: none;
    }

    .flex-col {
      display: flex;
      flex-direction: column;
      flex: none;
    }

    .justify-between {
      display: flex;
      justify-content: space-between;
    }
  }

  .ModalMaster {
    &.active {
      visibility: visible;
      opacity: 1;

      .Modal__wrapper {
        transform: scale(1);
      }
    }

    .Modal {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 99;
      display: flex;
      align-items: center;
      justify-content: center;
      visibility: hidden;
      opacity: 0;
      overflow-x: hidden;
      background: rgba(0, 0, 0, .5);
      align-items: flex-start;

      @media screen and (min-width: 640px) {
        align-items: center;
      }

      &.active {
        visibility: visible;
        opacity: 1;

        .Modal__wrapper {
          // transform: scale(1);
          top: -50%;
          transform: scale(1) translateY(calc(50% + 10vh));
          margin: 10vh 0;

          @media #{$mobileOnly} {
            transform: none !important;
            margin: 5vh 0 !important;
            top: 0 !important;
          }
        }
      }

      .Modal__layer {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        cursor: pointer;
      }

      .Modal__wrapper {
        width: 90%;
        max-width: 825px;
        background: #202227;
        padding: 0;
        position: relative;
        text-align: center;
        align-items: center;
        justify-content: center;
        transform: scale(0.8);

        // margin: 5vw 0 5vh 0;
        @media screen and (min-width: 640px) {
          margin: 0;
        }
      }

      .Copy {
        padding: 30px 0;
        color: $colorLight;

        select {
          min-width: 200px;
          max-width: 300px;
          background-color: transparent;
          border: none;
          border-bottom: 2px solid #83858C;
          border-radius: 0px;
          color: white;
          display: inline-block;
          padding-left: 0;
          text-align: left;
          margin: 0;
          width: 100%;

          option {
            background-color: #202227;
          }

          &:focus {
            border-bottom: 2px solid #3A74E0;
            box-shadow: none;
          }
        }

        button {
          color: $colorLight;
          background: $colorBackground2;
          padding: 4px 7px;
          margin-right: 5px;
          border: 1px solid $colorBackground2;
          border-radius: 5px;
          cursor: pointer;

          &.active {
            border: 1px solid $colorLight;
          }

          &:focus {
            outline: 0;
          }
        }

        .Modal__copy__header {}

        .Modal__copy__body {
          margin-top: 20px;
        }

        .Modal__copy__footer {
          margin-top: 20px;
        }
      }
    }
  }

  .ToggleTable__table-last {
    background: #202227;
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;

    i {
      color: #49e1ca;
      font-size: 25px;
      margin-left: 15px;
    }

    .balance_min_title {
      font-size: 14px;
      margin-left: 10px;
      font-weight: bold;
      margin-top: 4px;
    }

    .balance_min {
      color: $colorLifeGreen;
      font-size: 25px;
      margin-left: 10px;
      font-weight: bolder;
      margin-right: 60px;
    }
  }
}
