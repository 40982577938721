@import "../../../../utils/_variables";

.PortalLeft {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media #{$mobileAndTabletOnly} {
    width: 100%;
  }
}
