@import  '../../../../utils/_variables';

.AGameFeatured{
  .Modal__close-btn{
    width: 25px;
    height: 25px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 10px;
    cursor: pointer;
    outline: none;
    &:hover{
      &::after,
      &::before{
        background: #6d7179;
      }
    }
    &::before{
      content: "";
      width: 3px;
      height: 90%;
      background: #37393D;
      display: block;
      position: absolute;
      left: 50%;
      transform-origin: center;
      transform: rotate(45deg);
      transition: ease 300ms background;
    }
    &::after{
      content: "";
      width: 3px;
      height: 90%;
      background: #37393D;
      display: block;
      position: absolute;
      left: 50%;
      transform-origin: center;
      transform: rotate(-45deg);
      transition: ease 300ms background;
    }
  }
  .info-tab-wrap{
    width:100% !important;
    .gallery__add{
      margin-bottom: 10px;
      span {
        cursor: pointer;
        padding: 5px;
        border-radius: 5px;
        color: $colorLight;
        border: 1px solid $colorLight;
      }
    }
    .gallery__item{
      border-radius: 10px;
      border: 1px dashed $colorLight;
      padding: 10px;
      margin-bottom: 10px;
      position: relative;
    }
  }
  .info-tabs{
    div.tab{
     display:inline-block;
     padding:10px 30px;
     background-color:#444;
     &:hover{
       cursor:pointer;
       background-color:#282930;
     }
     &.selected{
       background-color:#282930;
     }
    }
  }
  .main_desc .mce-path,
  .main_desc .mce-container{
    background-color:#2D2D33 !important;
  }
  .main_desc .mce-panel{
    border:0 solid #2D2D33 !important;
  }
  .AGameFeatured__wrapper{
    margin: 0 auto;
		padding: 30px;
    padding-top: 120px;
    .category__field{
      margin-bottom: 10px;
      padding-top: 20px;
      span{
        width: 120px;
        color: #ececec;
        border: 1px solid #ececec;
        padding: 4px 10px;
        margin-right: 10px;
        cursor: pointer;
        i{
          width: 14px;
          text-align: center;
        }
        &.checked{
          color: #4BE0CA;
          border: 1px solid #4BE0CA;
        }}
      
    }
    .button-style{
      width: 300px;
      height: 50px;
      border-radius: 30px;
      background: linear-gradient(to right, #45cacd, #47b8d4);
      color: #FFF;
      border: none;
      outline: none;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 22px;
      letter-spacing: 1.2px;
      margin: 20px 0 20px 0;
      cursor: pointer;
    }
    .AGameFeatured__content{
      margin: 0 10%;
      .AGameFeatured__title{
        font-weight: 500;
        letter-spacing: 3.5px;
        text-align: left;
        text-transform: uppercase;
        @include vw-pixels((
          'font-size': 35
        ));
      }
      .AGameFeatured__actions{
        button{
          @extend .button-style;
        }
      }
      form.admin-game-form>* {
        padding-right: 20px;
      }
      form.admin-game-form{
        background-color: #282930;
        padding: 20px;
        width: 100%;
        label{
          color:white;
        }
        .input-wrapper{
          position: relative;
          .error-message{
            color: #dc4f4f;
            font-size: 12px;
            position: absolute;
            right: 0;
            bottom: -17px;
          }
          .error-icon{
            color: #dc4f4f;
            display: none;
            position: absolute;
            right: 10px;
            top: 10px;
          }
          .ok-icon{
            position: absolute;
            right: 10px;
            bottom: 5px;
            color: #49E1CA;
          }
        }
        
        input, select{
          background-color: #202227;
          border: none;
          border-bottom: 2px solid #83858C;
          border-radius: 0px;
          color: white;
          display: inline-block;
          padding-left: 0;
          text-align: left;
          margin: 0;
          width: 100%;
        }
        input.error, select.error{
          border-bottom: 2px solid #dc4f4f;
        }
        select {
          background-image: none;
        }
        a {
          text-decoration: none;
        }
        ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: rgb(131, 133, 140);
          opacity: 1; /* Firefox */
        }
    
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: rgb(131, 133, 140);
        }
    
        ::-ms-input-placeholder { /* Microsoft Edge */
          color: rgb(131, 133, 140);
        }
        .first-option{
          color: rgb(131, 133, 140);
        }
        input:focus, select:focus{
          border-bottom: 2px solid #3A74E0;
          box-shadow: none;
        }
        input:placeholder-shown + label {
          cursor: text;
          max-width: 66.66%;
          color:#d1d1d1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          transform-origin: left bottom;
          transform: translate(0, 1.9rem) scale(1.5);
          &:after{
            content: attr(data-label);
          }
        }
        .input-wrapper {
          display: flex;
          flex-flow: column-reverse;
          margin-bottom: 1em;
          position: relative;
        }
        input, select{
          z-index:1;
          background-color: transparent;
        }
        select option{
          background-color: #202227;
        }
        label{
          z-index:0;
        }
        label, input {
          transition: all 0.2s;
          touch-action: manipulation;
        }
        .form__footer{
          .AGameForm__save--btn{
            @extend .button-style;
          }
        }
      }
    }
  }
}