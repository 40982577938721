.GameItem__tooltip {
  position: absolute;
  top: 50%; /* Center vertically */
  left: 105%; /* Position to the left of the image */
  transform: translateY(-50%); /* Center vertically */
  width: 300px; /* Adjust as needed */
  background-color: #211c27; /* White background */
  color: black; /* Black text color */
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.66);
  z-index: 10;
  pointer-events: none; /* Ensure the tooltip does not interfere with mouse events */
  .GameItem__tooltip__bg{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: .4;
    filter: blur(10px);
    background-position: center;
    background-size: cover;
    z-index: -1;
  }
}
.GameItem__tooltip::after {
  content: "";
  position: absolute;
  left: -9px;
  top: 50%;
  border-style: solid;
  border-width: 10px 10px 10px 0;
  border-color: transparent #211c27 transparent transparent;
}

/* 新增：最右侧条目的 tooltip 居左显示 */
.GameItem__tooltip.tooltip-left {
  left: auto;
  right: 105%;
}
.GameItem__tooltip.tooltip-left::after {
  left: auto;
  right: -10px;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent #211c27;
}


.game-title {
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 8px;
}

.game-info {
  font-size: 12px;
  margin-bottom: 8px;
  color: #8f98a0;
}

.container {
  margin-top: 10px;
  position: relative;
  height: 140px;
}
.container  h3 {
  margin: 0;
  font-size: 16px;
}

.container p {
  margin: 5px 0 0;
  font-size: 14px;
}


.gallery-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.rating {
  height: 100%;
  margin-top: 8px;
  padding: 8px;
  background: rgba(33, 150, 243, 0.2);
  border-radius: 4px;
  font-size: 12px;
  line-height: 1.5;
  color: #ffffff;
}

.game-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-top: 8px;
  height: 40px;
}

.tag {
  background: rgba(81, 45, 168, 0.4);
  color: #67c1f5;
  padding: 2px 6px;
  border-radius: 2px;
  font-size: 11px;
  height: fit-content;
}