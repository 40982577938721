@import  '../../../../utils/_variables';


  .StreamActivityTable{
    width: 100%;
    border: 1px solid rgba(0,0,0,0.2);
    margin: 20px 10px;
    .table-cell{
      &.headline{
        font-size: 14px;
        color: #9b9b9b;
        font-weight: bold;
        letter-spacing: 1.2px;
      }
      &:first-child{
        justify-content: flex-start;
        max-width: initial;
      }
      
    }
  }
                
  .ModalPreapproval {
    &.active{
      visibility: visible;
      opacity: 1;
      .Modal__wrapper{
        transform: scale(1);
      }
    }

    .Modal{
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 99;
      display: flex;
      align-items: center;
      justify-content: center;
      visibility: hidden;
      opacity: 0;
      overflow-x: hidden;
      background: rgba(0,0,0,.5);
      align-items: flex-start;
      @media screen and (min-width: 640px){
        align-items: center;
      }
      
      .Modal__download {
        max-width: 700px;
        .Modal__download--spinner {
          padding-top: 140px;
        }
        .Modal__download--content {
          padding-bottom: 50px;
          .Modal__download--header {
            padding: 50px 0 30px 0;
            font-size: 26px;
          }
          .Modal__download--body {
            .Modal__download--row {
              padding: 15px 0;
            }
          }
          .Modal__download--buttons {
            margin: auto;
            padding: 50px 0 15px 0;
            display: flex;
            flex-direction: row;
            justify-content: center;
          }
          .Modal__download--footer {
            padding: 50px 0 15px 0;
          }
          .Modal__download--cta {
            cursor: pointer;
            padding: 5px 10px;
            border-radius: 5px;
            font-size: 18px;
            color: white;
            border: 1px solid white;
            text-decoration: none;
            // flex: 1;
          }
          a {
            padding: 5px 10px;
            border-radius: 5px;
            font-size: 18px;
            color: white;
            border: 1px solid white;
            text-decoration: none;
            margin: 0px 5px;
          }
        }
      }

      .Filters__close {
        margin: 20px;
        button {
          color: #ececec;
          background: #444546;
          padding: 4px 7px;
          margin-right: 5px;
          border: 1px solid #444546;
          border-radius: 5px;
          cursor: pointer;
        }
      }
      
      input {
        background-color: #202227;
        border: none;
        border-bottom: 2px solid #83858C;
        border-radius: 0px;
        color: white;
        display: inline-block;
        padding-left: 0;
        text-align: left;
        margin: 0;
      }

      input:focus{
        box-shadow: none;
      }

      &.active{
        visibility: visible;
        opacity: 1;
        .Modal__wrapper{
          // transform: scale(1);
          top: -50%;
          transform: scale(1) translateY(calc(50% + 10vh));
          margin: 10vh 0;
          padding: 30px;
          @media #{$mobileOnly}{
            transform: none !important;
            margin:5vh 0 !important;
            top:0 !important;
          }
        }
      }
      .Modal__layer{
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        cursor: pointer;
      }
      .Modal__wrapper{
        width: 90%;
        max-width: 825px;
        background: #202227;
        padding: 0;
        position: relative;
        text-align: center;
        align-items: center;
        justify-content: center;
        transform: scale(0.8);
        // margin: 5vw 0 5vh 0;
        @media screen and (min-width: 640px){
          margin: 0;
        }
      }
      }}
      
.CCQuestPreapproval {
  .MuiTableRow-root {
    .MuiTableCell-body {
      padding: 20px 5px;
      max-width: 160px;
      overflow-wrap: break-word;
    }

    &.approved {
      .MuiTableCell-body, a {
        color: $colorLifeGreen;
      }
    }
    &.declined {
      .MuiTableCell-body, a {
        color: red;
      }
    }
    &.leftQuest {
      .MuiTableCell-body, a {
        color: #ffd300;
      }
    }
  }

  h2{
    margin: 0 0 10px 0;
  }
  .CCQuestPreapproval__subtab {
    cursor: pointer;
    color: #5c5c5c;
    border-bottom: 1.5px solid #202227;
    cursor: pointer;
    padding: 5px 10px;
    margin-right: 10px;
    outline: 0;
    border-radius: 0;
    background-color: transparent;
    &.active {
      color: $colorLifeGreen; 
      border-bottom: 1.5px solid $colorLifeGreen;
    }
    &:hover {
      color: $colorLifeGreen;  
    }
  }
  .CCQuestPreapproval__loading{
    background: transparent;
    text-align: center;
    padding: 50px 0;
    i {
      text-align: center;
    }
  }
    .buttonStyle{
      height: 50px;
      border-radius: 30px;
      background: linear-gradient(to right, #45cacd, #47b8d4);
      color: #FFF;
      border: none;
      outline: none;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 22px;
      letter-spacing: 1.2px;
      margin: 20px 0 20px 0;
      cursor: pointer;
    }
    .CCQuests__back--btn{
      @extend .buttonStyle;
      width: 300px;
    }
    .CCQuests__save--btn{
      @extend .buttonStyle;
      width: 100%;
    }
  
    .ToggleTable {
      background: #202227;
      .row {
        &.first-row {
          // justify-content: flex-end;
          padding: 20px;
          display: flex;
          a {
            color: white;
            margin-left: auto;
          }
          .pagination {
            margin-left: auto;

            span.search {   
              color: #9b9b9b;
              font-weight: 400;
              letter-spacing: 1.2px;
              margin: 0px 10px;
            }
            .right{
              margin-left: auto;
              button{
                margin-right: 4px;
                border-radius: 4px;
                padding: 5px 15px;
                background: #202227;
                color: #FFF;
                border: none;
                outline: none;
                font-family: Roboto;
                font-size: 14px;
                font-weight: 500;
                font-style: normal;
                font-stretch: normal;
                line-height: 22px;
                letter-spacing: 1.2px;
                cursor: pointer;
                margin-left: auto;
                &:hover{
                  background: rgba(255, 255, 255, 0.2);
                }
              }
              i{
                margin-left: auto;
              }
            }
          }
        }
      }
      .ToggleTable__header {
        background: #202227;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 30px;
        border-bottom: 1px solid #454545;
        .ToggleTable__header-title {
          padding: 0;
          margin: 0;
          font-size: 22px;
          letter-spacing: 2.5px;
        }
        .dot-status{
          width: 10px;
          height: 10px;
          border-radius: 100%;
          background-color: #ef3937;
          box-shadow: 0 0 9px 0 #ff987f;
          margin-left: 10px;
        }
        i{
          font-size: 30px;
          line-height: 18px;
          margin-left: auto;
        }
      }
      .ToggleTable__table-wrapper {
        background: #202227;
        padding: 30px;
        .no-results {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 44px;
          margin-top: 30px;
        }
        .row{
          border-bottom: 1px solid #1e1f25;
          padding: 0 10px;
          &.toggable{
            flex-wrap: wrap;
            .row-basic{
              width: 100%;
              display: flex;
              flex-flow: row nowrap;
              color: white;
              &.green {
                color: #49e1ca;
              }
              &.red {
                color: red;
              }
              &.yellow {
                color: #ffd300;
              }
            }
            .row-details{
              width: 100%;
              display: none;
            }
            &.open{
              .row-details{
                display: flex;
                .StreamActivityTable{
                  width: 100%;
                  border: 1px solid rgba(0,0,0,0.2);
                  margin: 20px 10px;
                  .table-cell{
                    &.headline{
                      font-size: 14px;
                      color: #9b9b9b;
                      font-weight: bold;
                      letter-spacing: 1.2px;
                    }
                    &:first-child{
                      justify-content: flex-start;
                      max-width: initial;
                    }
                    
                  }
                }
              }
            }
          }
          &:hover {
            background: rgba(255,255,255,0.025);
            &:first-child {
              background: rgba(255,255,255,0);
            }
          }
          &:last-child{
            border-bottom: none;
          }
          .table-cell{
            text-align: center;
            justify-content: center;
            a{
              color: inherit;
            }
            button {
              color: white;
              border-radius: 5px;
              padding: 5px 10px;
              margin-left: 5px;
              background-color: #444546;
              // border: 1px solid white;
              cursor: pointer;
            }
            &.headline{
              color: #9b9b9b;
              font-weight: 400;
              letter-spacing: 1.2px;
              align-items: center;
            }
            &:first-child{
              justify-content: flex-start;
              max-width: 50px;
            }
            &:last-child{
              // justify-content: flex-end;
              align-items: center;
            }
            .small-btn {
              background: rgba(255,255,255,0.05);
              border: none;
              padding: 5px 8px;
              border-radius: 3px;
              color: #FFF;
              letter-spacing: 1px;
              font-weight: 800;
              text-transform: uppercase;
              cursor: pointer;
              font-size: 17px;
              text-decoration: none;
              margin-right: 5px;
              &.open{
                background: rgba(255,255,255,.15);
              }
              &:last-child{
                margin-right: 0;
              }
              &:hover{
                background: rgba(255,255,255,.2);
              }
  
            }
            .small-btn.waiting {
              background: rgba(255,255,255,0);
              border: none;
              padding: 5px 8px;
              border-radius: 3px;
              color: #e91e63;
              letter-spacing: 1px;
              font-weight: 600;
              text-transform: uppercase;
              font-size: 11px;
              text-decoration: none;
              cursor: pointer;
            }
          }
        }
      }

      .ToggleTable__footer {
        background: #202227;
        height: 100px;
        button{
          cursor: pointer;
          i{
            font-size: 60px;
            color: white;
          }
        }
        .ToggleTable__footer__left{
          float: left;
          padding: 0px 30px 30px 30px;
          width: 50%;
        }
        .ToggleTable__footer__right{
          float: right;
          text-align: right;
          padding: 0px 30px 30px 30px;
          width: 50%;
        }
      }
    }
    .NoQuestSale{
        padding-top: 10px;
    }
    .dooDooList {
      color: rgb(182, 55, 55);
      font-weight: 450;
      cursor: pointer;
    }
    .snowflake {
      min-width: 28px;
      max-height: 25px;
      width: 28px;
      height: auto;
      cursor: pointer;
    }

    .deadhead {
      min-width: 38px;
      max-height: 35px;
      width: 38px;
      height: auto;
      cursor: pointer;
    }

    .blueTip {
      background-color: #d6ecef; color: black;

      
    }

    .blueTip[class$="arrow"] {
      border-top: 5px solid #d6ecef !important;
    }

  .past-quest {
    display: flex !important;
    cursor: pointer !important;
    flex-direction: column !important;  
    div {
      text-align: left;
    }
  }
  .passedQuest {
    color: $colorLifeGreen;
    font-weight: 800;
    font-size: 18;
  }
  .failedQuest {
    color: red;
    font-weight: 800;
    font-size: 18;
    
  }
  .warning {
    color: #ffd300;
  }

  .numUsersSection {
    margin: 0px 20px;
    padding: 20px 0px;
    align-items: center;
    display: flex;
    justify-content: flex-end;

    span {
      margin: 0px 10px;
    }
  }
}
  
.avg30 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}