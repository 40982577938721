@import '../../../utils/_variables';

.CompleteRegistration {
	.Modal .Modal__wrapper1 .Modal__right .Modal__content {
		.Modal__tabs {
			margin-bottom: 35px !important;
			margin-top: 0;

			.Modal__tab {
				text-transform: uppercase;
			}
		}

		.input-container {
			width: 100%;
			min-height: 72px;
			position: relative;

			.input-wrapper {
				display: flex;
				flex-direction: row;
				align-items: center;
				background-color: #2E2933;
				border: 2px solid #83858C;
				border-radius: 12px;
				padding: 4px 12px;

				&.error {
					border-color: #dc4f4f;
				}

				label {
					text-align: right;
					margin-left: auto; // 让 label 靠右
				}

				.prefix-icon {
					width: 24px;
					height: 24px;
					margin-right: 12px;
				}

				select {
					width: 100%;
					background-color: transparent;
					border: none;
					outline: none;
					color: white;
					&::placeholder {
					  color: #595261;
					  font-size: 18px;
					}
					option {
						background-color: #202227;
						color: white;
					}
					&:-webkit-autofill,
					&:-webkit-autofill:hover,
					&:-webkit-autofill:focus,
					&:-webkit-autofill:active {
						transition: background-color 5000s ease-in-out 0s;
						-webkit-box-shadow: 0 0 0 30px transparent inset !important;
						-webkit-text-fill-color: white !important;
						background-color: transparent !important;
					}
				}

				input {
					color: white;
					background-color: transparent;
					display: inline-block;
					text-align: left;
					margin: 0;
					width: 100%;
					border: none; // 去掉边框
					outline: none; // 去掉点击时的轮廓
					// 由于 caret-width 不是标准属性，改用其他方式控制光标样式
					caret-color: #F4F4F4;

					&:focus {
						border: none; // 确保聚焦时也没有边框
						outline: none; // 确保聚焦时也没有轮廓
					}

					&:-webkit-autofill,
					&:-webkit-autofill:hover,
					&:-webkit-autofill:focus,
					&:-webkit-autofill:active {
						transition: background-color 5000s ease-in-out 0s;
						-webkit-box-shadow: 0 0 0 30px transparent inset !important;
						-webkit-text-fill-color: white !important;
						background-color: transparent !important;
					}
				}

				::placeholder,
				::-webkit-input-placeholder,
				:-ms-input-placeholder,
				::-ms-input-placeholder {
					font-size: 18px;
					color: #595261;
					opacity: 1;
					transition: opacity 0.2s ease;
				}



				.ok-icon {
					color: #49E1CA;
				}
			}

			.error-message {
				text-align: right;
			}
		}

		.completeregistration {
			label {
				text-transform: uppercase;
				font-size: 14px;
				color: #595261;
				margin-left: 5px;
				line-height: 19px;
			}

			.item-row {
				display: flex;
				gap: 10px;
			}

			.Profile__info__form__single {
				input[disabled] {
					cursor: default;
					color: #83858c;
				}

				.form_value {
					margin-top: 10px;
					display: flex;
					align-items: center;
					gap: 20px;
				}

				.value__item {
					display: flex;
					align-items: baseline;
					gap: 10px;
					color: #7E7B82;
				}
			}

			.form__footer {
				position: relative;
				top: 38px;
			}
		}
	}
}

label.consoles {
	color: #83858c !important;
	text-align: left;
	font-size: 12px;
	line-height: 12px;
	margin-top: 10px;
	margin-bottom: 10px;
}