@import '../../../../utils/_variables';

.CCQuestTiered {
  &.loading {
    text-align: center;
    padding-top: 20px;
  }

  h2 {
    margin: 0 0 10px 0;
  }

  .buttonStyle {
    height: 50px;
    border-radius: 30px;
    background: linear-gradient(to right, #45cacd, #47b8d4);
    color: #FFF;
    border: none;
    outline: none;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 22px;
    letter-spacing: 1.2px;
    margin: 20px 0 20px 0;
    cursor: pointer;
  }

  .CCQuests__back--btn {
    @extend .buttonStyle;
    width: 300px;
  }

  .CCQuests__save--btn {
    @extend .buttonStyle;
    width: 100%;
  }

  .ToggleTable {
    background: #202227;

    .ToggleTable__header {
      background: #202227;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 30px;

      .ToggleTable__header-title {
        padding: 0;
        margin: 0;
        font-size: 22px;
        letter-spacing: 2.5px;
      }

      .ToggleTable__header--buttons {
        display: flex;
        margin-left: auto;
      }

      .ToggleTable__header--button {
        color: white;
        padding: 5px 10px;
        border: 1px solid white;
        margin-right: 5px;
        border-radius: 3px;
        cursor: pointer;

        &.no-padding {
          padding: 0;
        }
      }

      .dot-status {
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background-color: #ef3937;
        box-shadow: 0 0 9px 0 #ff987f;
        margin-left: 10px;
      }

      a {
        line-height: 18px;
        margin-left: auto;
        color: $colorLight;
      }
    }

    .ToggleTable__body-wrapper {
      background: #202227;
      padding: 20px;

      .ToggleTable__body {
        .ToggleTable__body-row {
          display: flex;
          justify-content: space-evenly;
          margin: 30px 0;

          .divider {
            min-height: 2px;
            background-color: $colorGray;
            border-radius: 2px;
          }

          .ToggleTable__body-rowItem {
            flex: 1;
            text-align: center;

            .ToggleTable__body-itemBot {
              font-size: 35px;

              &.green {
                color: #49e1ca;
              }

              &.red {
                color: red;
              }
            }
          }
        }
      }
    }

    .ToggleTable__table-wrapper {
      background: #202227;
      padding: 30px;

      .ToggleTable__table--title {
        font-size: 20px;
        font-weight: 600;
        padding: 0 10px 10px 10px;
      }

      .ToggleTable__table--section {
        margin-bottom: 50px;
      }

      .no-results {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 44px;
        margin-top: 30px;
      }

      .row {
        border-bottom: 1px solid #1e1f25;
        padding: 0 10px;

        &.toggable {
          flex-wrap: wrap;

          .row-basic {
            width: 100%;
            display: flex;
            flex-flow: row nowrap;
          }

          .row-details {
            width: 100%;
            display: none;
          }

          &.open {
            .row-details {
              display: flex;

              .flex-table {
                width: 100%;
                border: 1px solid rgba(0, 0, 0, 0.2);
                margin: 20px 10px;

                .table-cell {
                  position: relative;

                  a {
                    color: white;
                  }

                  &.headline {
                    font-size: 14px;
                    color: #9b9b9b;
                    font-weight: bold;
                    letter-spacing: 1.2px;
                  }

                  &:first-child {
                    justify-content: flex-start;
                    max-width: inherit;
                  }

                  &.last {
                    justify-content: flex-end;
                  }

                  &.success {
                    color: #49e1ca;

                    a {
                      color: #49e1ca;
                    }
                  }

                  &.warning {
                    color: #ffd300;

                    a {
                      color: #ffd300;
                    }
                  }

                  &.error {
                    color: #e91e63;

                    a {
                      color: #e91e63;
                    }
                  }
                }
              }
            }
          }
        }

        &:hover {
          background: rgba(255, 255, 255, 0.025);

          &:first-child {
            background: rgba(255, 255, 255, 0);
          }
        }

        &:last-child {
          border-bottom: none;
        }

        .table-cell {
          position: relative;
          justify-content: center;
          align-items: center;
          text-align: center;

          a {
            color: white;
          }

          &.headline {
            color: #9b9b9b;
            font-weight: 400;
            letter-spacing: 1.2px;
          }

          &.cell-left {
            justify-content: center;
          }

          &.sortable {
            cursor: pointer;
          }

          .reason {
            color: red;
            font-weight: 800;
            cursor: pointer;
          }

          .table-cell-sort {
            display: flex;
            flex-direction: column;

            i {
              color: rgba(155, 155, 155, 0.2);
              padding: 0 0 0 5px;
              height: 9px;

              &.selected {
                color: #9b9b9b;
              }
            }
          }

          &:first-child {
            max-width: 50px;

            &.first {
              max-width: inherit;
            }
          }

          &.last {
            justify-content: flex-end;
          }

          i {
            padding: 4px 0 0 6px;
          }

          span {
            &.error {
              color: $colorCrimson;
            }

            &.success {
              color: $colorLifeGreen;
            }

            &.info {
              color: $colorEnergyBlue;
            }
          }

          .small-btn {
            background: rgba(255, 255, 255, 0.05);
            border: none;
            padding: 5px 8px;
            border-radius: 3px;
            color: #FFF;
            letter-spacing: 1px;
            font-weight: 600;
            text-transform: uppercase;
            cursor: pointer;
            font-size: 11px;
            text-decoration: none;
            margin-right: 5px;
            display: flex;
            align-items: center;
            justify-content: center;

            &.open {
              background: rgba(255, 255, 255, .15);
            }

            &:last-child {
              margin-right: 0;
            }

            &:hover {
              background: rgba(255, 255, 255, .2);
            }

            &.red {
              color: red;
            }

            &.green {
              color: #49e1ca;
            }

            i {
              padding: 0;
            }
          }

          .small-btn.waiting {
            background: rgba(255, 255, 255, 0);
            border: none;
            padding: 5px 8px;
            border-radius: 3px;
            color: #e91e63;
            letter-spacing: 1px;
            font-weight: 600;
            text-transform: uppercase;
            font-size: 11px;
            text-decoration: none;
            cursor: pointer;
          }
        }
      }
    }
  }

  .NoQuestSale {
    padding-top: 10px;
  }

  .tooltip {
    position: relative;
    display: inline-block;
    color: white;
  }

  .tooltiptext {
    font-size: 10px;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 200px;
    cursor: default;
    background-color: $colorBackground;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    bottom: 30px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 100;

    &.tooltiptext--100 {
      bottom: 100%;
    }

    &.nodecoration {
      &:before {
        display: none;
      }
    }

    &:before {
      content: "";
      z-index: 100;
      position: absolute;
      width: 11px;
      height: 11px;
      bottom: -4.5px;
      left: 0;
      right: 70px;
      margin-left: auto;
      margin-right: auto;
      background-color: $colorBackground;
      border-top: solid 1px $colorBackground;
      border-left: solid 1px $colorBackground;
      transform: rotate(45deg);
    }
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
}

.edit-icon {
  cursor: pointer;
  margin-left: 6px;
}

.RejectReason--input {
  input {
    box-shadow: none !important;
    border: none;
    outline: none;
    width: 400px;
  }

  input:focus {
    outline: none !important;
    border: none !important;
  }
}

.Summary_auto_second_content {
  display: flex;
  flex-direction: column;

  .Summary_auto_second_buttons {

    .platform-btn {
      background: #242424;
      border: none;
      color: #ffffff;
      padding: 10px 20px;
      border-radius: 20px;
      cursor: pointer;
      transition: all 0.3s ease;
      display: flex;
      align-items: center;
      gap: 8px;
      width: auto;
    }

    .platform-btn.active {
      background: #47BCD3;
    }

    .platform-icon {
      width: 20px;
      height: 20px;
      fill: currentColor;
    }
  }

  .Summary_auto_second_header {
    background: #202227;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    padding: 20px;
    border-radius: 10px;

    .rank_table {
      margin-top: 30px;
    }

    .Summary_auto_second_header_rank {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .Summary_auto_second_header_title_right {
        font-size: 22px;
        font-weight: 600;
        color: #a0a0a0;
        display: flex;
        align-items: center;
        flex-direction: row;

        .Summary_auto_second_header_prizepool {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          margin-left: 20px;

          .rank_header_title {
            color: #a0a0a0;
            font-size: 14px;
          }

          .rank_header_content {
            color: #ffffff;
            font-size: 14px;
          }
        }
      }

      .Summary_auto_second_header_title_left {
        display: flex;
        flex-direction: row; // 改为水平布局
        align-items: center; // 垂直居中对齐
        justify-content: flex-start;
        padding: 12px 16px;
        background: rgba(32, 34, 39, 0.6);
        border-radius: 8px;
        // margin: 15px 0;
        min-width: 260px;

        .Summary_auto_second_header_countdown_title {
          font-size: 14px;
          font-weight: 500;
          color: #a0a0a0;
          margin-right: 12px; // 添加右侧间距
          margin-bottom: 0; // 移除底部间距
          white-space: nowrap; // 防止标题换行
          display: flex;
          align-items: center;

          // 添加时钟图标
          &::before {
            content: "\f017";
            font-family: FontAwesome;
            margin-right: 8px;
            font-size: 16px;
            color: #47BCD3;
          }

          // 根据标题内容调整样式
          &:contains("Status") {
            &::before {
              content: "\f05a"; // 信息图标
            }
          }
        }

        .Summary_auto_second_header_countdown_content {
          font-size: 18px;
          font-weight: 700;
          border-radius: 6px;
          padding: 6px 14px;
          letter-spacing: 0.5px;
          position: relative;
          text-align: center;
          flex: 1; // 允许内容区域扩展

          // 默认样式（Next Leaderboard Update）
          background: rgba(71, 188, 211, 0.15);
          color: #47BCD3;
          border-left: 3px solid #47BCD3;

          // 紧急倒计时样式（24小时内）
          &:contains("h"):not(:contains("Next Leaderboard")) {
            background: rgba(255, 159, 67, 0.15);
            color: #FF9F43;
            border-left: 3px solid #FF9F43;
            animation: pulse 2s infinite;
          }

          // 任务结束样式
          &:contains("Final Leaderboard") {
            background: rgba(40, 167, 69, 0.15);
            color: #28a745;
            border-left: 3px solid #28a745;
            font-weight: 600;
          }
        }

        // 响应式调整 - 在小屏幕上转为纵向布局
        @media (max-width: 560px) {
          flex-direction: column;
          align-items: flex-start;

          .Summary_auto_second_header_countdown_title {
            margin-right: 0;
            margin-bottom: 8px;
          }

          .Summary_auto_second_header_countdown_content {
            width: 100%;
          }
        }
      }
    }

    .Summary_auto_second_header_title {
      font-size: 22px;
      font-weight: 600;
      color: #a0a0a0;
      align-items: center;
      display: flex;
    }

    .Summary_auto_second_split_item {
      margin-top: 20px;
      display: grid;
      grid-template-columns: repeat(3, 1fr); // Divides into 5 equal columns
      background-color: rgba(255, 255, 255, 0.05);
      border-radius: 10px;
      padding: 20px;

      .Summary_auto_second_split_item_left {
        display: flex;
        align-items: flex-start;
        flex-direction: row;

        .Summary_auto_second_split_item_left_level {
          color: #fff;
          font-size: 24px;
          font-weight: 600;
        }

        .Summary_auto_second_split_item_left_content {
          margin-top: 10px;
          margin-left: 20px;
          color: #a0a0a0;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          height: 100%;
        }

        .Summary_auto_second_split_item_left_content_hours {
          margin-top: 5px;
        }
      }

      .Summary_auto_second_split_item_middle {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .Summary_auto_second_split_item_middle_title {
          color: #47BCD3;
          font-size: 26px;
          font-weight: 600;
        }

        .Summary_auto_second_split_item_middle_content {
          color: #47BCD3;
          font-size: 34px;
          font-weight: 600;
        }
      }

      .Summary_auto_second_split_item_right {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        width: 100%;

        .Summary_auto_second_split_item_right_prize {
          color: #47BCD3;
          font-size: 18px;
          font-weight: 800;
        }

        .Summary_auto_second_split_item_right_maxRevenue {
          // margin-top: 10px;
          color: #a0a0a0;
          font-weight: 600;
        }
      }
    }

    .Summary_auto_second_header_content {
      margin-top: 20px;
      display: grid;
      grid-template-columns: repeat(4, 1fr); // Divides into 5 equal columns
      gap: 20px;

      .Summary_auto_second_header_card {
        background-color: rgba(255, 255, 255, 0.05);
        border-radius: 10px;
        padding: 20px;

        .Summary_auto_second_header_card_title {
          color: #a0a0a0;
          font-size: 18px;
        }

        .Summary_auto_second_header_card_content {
          margin-top: 10px;
          color: #47BCD3;
          font-size: 34px;
          font-weight: 600;
        }
      }
    }
  }

}