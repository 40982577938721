.SignUp {
	.ModalMaster {
		.Modal .Modal__wrapper1 .Modal__right .Modal__content {
			.link__items {
				display: flex;
			}

			.input-container {
				width: 100%;
				height: 85px;
				position: relative;

				.input-wrapper {
					display: flex;
					flex-direction: row;
					align-items: center;
					background-color: #2E2933;
					border: 2px solid #83858C;
					border-radius: 12px;
					padding: 4px 12px;

					&.error {
						border-color: #dc4f4f;
					}

					.prefix-icon {
						width: 24px;
						height: 24px;
						margin-right: 12px;
					}

					input {
						color: white;
						background-color: transparent;
						display: inline-block;
						font-size: 18px;
						text-align: left;
						margin: 0;
						width: 100%;
						border: none; // 去掉边框
						outline: none; // 去掉点击时的轮廓
						// 由于 caret-width 不是标准属性，改用其他方式控制光标样式
						caret-color: #F4F4F4;

						&:focus {
							border: none; // 确保聚焦时也没有边框
							outline: none; // 确保聚焦时也没有轮廓
						}

						&:-webkit-autofill,
						&:-webkit-autofill:hover,
						&:-webkit-autofill:focus,
						&:-webkit-autofill:active {
							transition: background-color 5000s ease-in-out 0s;
							-webkit-box-shadow: 0 0 0 30px transparent inset !important;
							-webkit-text-fill-color: white !important;
							background-color: transparent !important;
						}
					}


					::placeholder,
					::-webkit-input-placeholder,
					:-ms-input-placeholder,
					::-ms-input-placeholder {
						font-size: 18px;
						color: #595261;
						opacity: 1;
						transition: opacity 0.2s ease;
					}

					.ok-icon {
						color: #49E1CA;
						width: 14px;
					}
				}

				.error-message {
					text-align: right;
				}
			}

			.i_agree_wrap {
				display: flex;
				color: #7E7B82;
				white-space: pre;
				align-items: baseline;
				font-size: 14px;

				.i_agree__content {
					width: 100%;
					display: flex;
					flex-wrap: wrap;
				}
				.input-container {
					width: auto;
					height: auto;
					position: relative;
					top: 4px;
					.input-wrapper {
						border: none;
						padding-left: 0;
						padding-right: 20px;
						background-color: #322D38;
						input[type="checkbox"] {
							width: 14px;
							height: 14px;
						}
					}

					.error-message {
						display: none;
					}
				}

				a {
					color: #fff;
					text-decoration: none;
				}

				.ok-icon {
					display: none;
				}
			}

			.form__footer {
				top: 100px;
			}

			// .i_agree_error {
			//   border: 2px solid #dc4f4f;
			// }

			.i_agree {
				display: inline-block;
				width: 20px;
				margin-left: -20px;
			}
		}
	}
}