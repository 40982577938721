@import  '../../utils/_variables';
.CCRoleOuts{
  .CCQuests__create--btn{
    width: 300px;
    height: 50px;
    letter-spacing: 1.2px;
    margin: 20px 0 20px 0;
  }
  .ToggleTable {

    .ToggleTable__header {
      background: #2b2e38;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 30px;
      border-bottom: 1px solid #454545;
      // border-top: 1px solid #1d1e23;
      border-top: 1px solid #454545;
      .ToggleTable__header-title {
        padding: 0;
        margin: 0;
        font-size: 22px;
        letter-spacing: 2.5px;
      }
      .dot-status{
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background-color: #ef3937;
        box-shadow: 0 0 9px 0 #ff987f;
        margin-left: 10px;
      }
      i{
        font-size: 30px;
        line-height: 18px;
        margin-left: auto;
      }
    }
    .ToggleTable__table-wrapper1 {
      .no-results {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 44px;
        margin-top: 30px;
      }
      .row{
        border-bottom: 1px solid #1e1f25;
        &:hover {
          background: rgba(255,255,255,0.025);
          &:first-child {
            background: rgba(255,255,255,0);
          }
        }
        &:last-child{
          border-bottom: none;
        }
        .table-cell{
          justify-content: center;
          font-weight: 500;
          color: #CDCCD0;
          font-size: 14px;
          &.headline{
            color: #ffffff;
            background-color: #211C27;
            font-weight: 400;
            
            letter-spacing: 1.2px;
            padding:10px 10px;
          }
          &:first-child{
            justify-content: center;
          }
          &:last-child{
            justify-content: center;
          }
          .remove-key {
            i {
              margin-right: 0;
            }
            display: flex;
            align-items: center;
            background: rgba(255,255,255,0.05);
            border: none;
            padding: 5px 8px;
            border-radius: 3px;
            color: #FFF;
            line-height: 1;
            letter-spacing: 1px;
            font-weight: 600;
            text-transform: uppercase;
            cursor: pointer;
            font-size: 11px;
            text-decoration: none;
            margin-right: 5px;
            &:last-child{
              margin-right: 0;
            }
            &:hover{
              background: rgba(255,255,255,.2);
            }
          }
          .removing-key {
            background: rgba(255,255,255,0);
            border: none;
            padding: 5px 8px;
            border-radius: 3px;
            color: #e91e63;
            letter-spacing: 1px;
            font-weight: 600;
            text-transform: uppercase;
            font-size: 11px;
            text-decoration: none;
            cursor: pointer;
          }
        }
      }
      .NoQuests{
        padding:10px 30px;
        text-align: center;
        font-size: 16px;
      }
    }
    .ToggleTable__footer {
      background: #202227;
      height: 100px;
      button {
        cursor: pointer;
        i {
          font-size: 60px;
          color: white;
        }
      }
      .ToggleTable__footer__left {
        float: left;
        text-align: left;
        padding: 30px;
        width: 50%;
      }
      .ToggleTable__footer__right {
        float: right;
        text-align: right;
        padding: 30px;
        width: 50%;
      }
    }
  }

  .ToggleTable__header__right {
    display: flex;
    margin-left: auto;
    .regular_title{
      margin:5px 10px;
    }
    button {
      margin-right: 4px;
      border-radius: 4px;
      padding: 5px 15px;
      background: #202227;
      color: #fff;
      border: none;
      outline: none;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 22px;
      letter-spacing: 1.2px;
      cursor: pointer;
      margin-left: auto;
      &:hover {
        background: rgba(255, 255, 255, 0.2);
      }
    }
    i {
      margin-left: auto;
    }
  }
  .ModalMaster{
    &.active{
      visibility: visible;
      opacity: 1;
      .Modal__wrapper{
        transform: scale(1);
      }
    }
    .Modal{
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 99;
      display: flex;
      align-items: center;
      justify-content: center;
      visibility: hidden;
      opacity: 0;
      overflow-x: hidden;
      background: rgba(0,0,0,.5);
      align-items: flex-start;
      @media screen and (min-width: 640px){
        align-items: center;
      }
      &.active{
        visibility: visible;
        opacity: 1;
        .Modal__wrapper{
          // transform: scale(1);
          top: -50%;
          transform: scale(1) translateY(calc(50% + 10vh));
          margin: 10vh 0;
          @media #{$mobileOnly}{
            transform: none !important;
            margin:5vh 0 !important;
            top:0 !important;
          }
        }
      }
      .Modal__layer{
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        cursor: pointer;
      }
      .Modal__wrapper{
        width: 90%;
        max-width: 825px;
        background: #202227;
        padding: 0;
        position: relative;
        text-align: center;
        align-items: center;
        justify-content: center;
        transform: scale(0.8);
        // margin: 5vw 0 5vh 0;
        @media screen and (min-width: 640px){
          margin: 0;
        }
      }
      .Modal__download {
        max-width: 700px;
        .Modal__download--spinner {
          padding-top: 140px;
        }
        .Modal__download--content {
          padding-bottom: 50px;
          .Modal__download--header {
            padding: 50px 0 30px 0;
            font-size: 26px;
          }
          .Modal__download--body {
            .Modal__download--row {
              padding: 15px 0;
            }
          }
          .Modal__download--footer {
            padding: 50px 0 15px 0;
            .Modal__download--cta {
              cursor: pointer;
              padding: 5px 10px;
              border-radius: 5px;
              font-size: 18px;
              color: white;
              border: 1px solid white;
              text-decoration: none;
            }
          }
          a {
            padding: 5px 10px;
            border-radius: 5px;
            font-size: 18px;
            color: white;
            border: 1px solid white;
            text-decoration: none;
            margin: 0px 5px;
          }
        }
      }
      .Copy{
        padding: 30px 0;
        color: $colorLight;
        select{
          min-width: 200px;
          max-width: 300px;
          background-color: transparent;
          border: none;
          border-bottom: 2px solid #83858C;
          border-radius: 0px;
          color: white;
          display: inline-block;
          padding-left: 0;
          text-align: left;
          margin: 0;
          width: 100%;
          option{
            background-color: #202227;
          }
          &:focus{
            border-bottom: 2px solid #3A74E0;
            box-shadow: none;
          }
        }
        button{
          color: $colorLight;
          background: $colorBackground2;
          padding: 4px 7px;
          margin-right: 5px;
          border: 1px solid $colorBackground2;
          border-radius: 5px;
          cursor: pointer;
          &.active{
            border: 1px solid $colorLight;
          }
          &:focus{
            outline:0;
          }
        }
        .Modal__copy__header{}
        .Modal__copy__body{
          margin-top: 20px;
        }
        .Modal__copy__footer{
          margin-top: 20px;
        }
      }
    }
  }
  .RollRecord_SearchContainer{
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    font-weight: 500;
    font-size: 12px;
    color: #CDCCD0;
  }
}
