@import '../../utils/_variables';

$itemHeight: 84;
$offset: 2;

.cdkey-item-all {
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  transition: transform 0.3s ease;
  border-radius: 8px; /* Add border radius to top-left corner */
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.66); /* Right shadow */
  // z-index: 1; /* Ensure the parent element has a lower z-index */
}

// 使用新的类名：.cdkey-cover-image 替换原来的 .cdkey-cover
.cdkey-cover-image {
  width: 100%;
  height: 192px;
  display: block;
  // object-fit: cover;
  z-index: -1;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  transition: transform 0.3s ease;
}

.tooltip-cdkey {
  width: 250px;
  /* Adjust as needed */
  background-color: #211c27; /* White background */
  color: black; /* Black text color */
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.66);
  z-index: 10;
  pointer-events: none; 
  /* Ensure the tooltip does not interfere with mouse events */
}

// 封面图片容器
.cdkey-cover-container {
  position: relative;
  // overflow: hidden;
  // cursor: pointer;

  // 如果 tooltip 需要最高层显示，可在此调整
  z-index: 1;
}

.red-line {
  width: 100%;
  height: 3px;
  background-color: $colorMainOrange;
}

.bottom {
  background-color: #211c27;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  .bottom-price{
    display: flex;
    flex-direction: row;
  }
  .section-discount{
    background-color: rgba(239, 57, 55, 1);
    left: 70px;
    top: 11px;
    width: 104px;
    height: 23px;
    display: flex;
    flex-direction: row;
    margin-left:10px;
    .section-block{
      background-color: rgba(60, 57, 64, 1);
      position: relative;
      //width: 48px;
      height: 23px;
      display: flex;
      flex-direction: row;
      flex:1;
      .text-price{
        width: 100%;
        height: 16px;
        overflow-wrap: break-word;
        color: rgba(125, 122, 129, 1);
        font-size: 16px;
        font-family: ArialMT;
        font-weight: NaN;
        text-align: center;
        white-space: nowrap;
        //line-height: 16px;
        //margin: 4px 0 0 10px;
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          transform: rotate(18deg);
          width:100%;
          height: 2px;
          background-color: #322D38;
          transform-origin: 0 100%;
        }
      }
      img{
        position: absolute;
        left: 8px;
        top: 7px;
        width: 30px;
        height: 10px;
      }
    }
    .text-sale{
      width: 100%;
      height: 13px;
      overflow-wrap: break-word;
      color: rgba(255, 255, 255, 1);
      font-size: 16px;
      font-family: ArialMT;
      font-weight: NaN;
      text-align: center;
      white-space: nowrap;
      //line-height: 16px;
      //margin: 5px 5px 0 4px;
      flex:1;
    }
  }
}

.price {
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
}

.remaining {
  font-size: 12px;
  font-weight: 300;
  color: #ffffff;
}
