@import '../../utils/_variables';

$itemHeight: 84;
$offset: 2;

.game-item {
  width: 100%;
  // padding: 3px; /* Adjust the padding as needed */
  position: relative; /* Ensure the tooltip is positioned relative to the game item */
  box-sizing: border-box;
}
.game-cover {
  display: block;
  object-fit: cover;
  border-radius: 8px; /* Optional: Add border radius for rounded corners */
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.66); /* Shadow with 66% opacity, 5px size, 3px distance, 0 spread */
  transition: transform 0.3s ease; /* Smooth transition for the transform property */
  will-change: transform;
  width: calc(218 * (100vw / 1920));
  height: calc(332 * (100vw / 1920));
  /* 防止在超大屏幕上无限增长 */
  max-width: 218px;
  max-height: 332px;
  position: relative; // 新增定位上下文
  z-index: 1;
}

.game-cover.hovered{
  transform: scale(1.05); /* Scale the image to 1.2 times its original size */
  z-index: 2;
}
