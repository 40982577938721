@import "../../../../utils/_variables";

@keyframes rotating {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(180deg);
  }
}

.CustomInput {
  display: flex;
  flex-direction: column;
  
}

.CustomInput__Header {
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.16px;
  margin-bottom: 10px;
  text-align: left;
}

.InputClass {
  background-color: #211C27;
  border: 2px solid transparent;
  border-radius: 4px;
  box-shadow: none;
  color: $colorWhite;
  padding: 4px;
  font-size: 16px;

  &:focus {
    border: 2px solid $colorCyan;
    outline: none;
  }
}

.Error {
  border: 2px solid $colorCandyRed;

  &:focus {
    border: 2px solid $colorCandyRed;
  }
}

.Checked {
  border-radius: 50%;
  height: 22px;
  width: 22px;
}

.CustomInput__TextField {
  height: 52px;
  width: 100%;
}

.CustomInput__TextArea {
  height: 112px;
  overflow: auto;
  width: 100%;
}

.CustomInput__Radio {
  align-items: center;
  display: flex;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  input[type="radio"].Radio,
  input[type="checkbox"].Radio {
    -webkit-appearance: none;
    appearance: none;
    border: 0.15em solid currentColor;
    border-radius: 50%;
    color: $colorWhite;
    cursor: pointer;
    display: grid;
    font: inherit;
    height: 22px;
    margin: 0;
    place-content: center;
    width: 22px;

    &::before {
      border-radius: 50%;
      box-shadow: inset 1em 1em var(--form-control-color);
      content: "";
      height: 0.65em;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      width: 0.65em;
    }

    &:checked::before {
      transform: scale(1);
    }

    &:checked {
      background-image: linear-gradient(to bottom, #62e841, #4be0ca);
      border: none;
    }
  }

  .CustomInput__Radio_Label {
    color: $colorWhite;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    margin-left: 12px;
    text-align: left;
    width: 93%;
  }
}

.CustomInput__Dropdown {
  -webkit-appearance: none;
  appearance: none;
  text-align: center;
}

.CustomInput__Date {
  display: flex;
  height: 52px;
  width: 100%;
  & > * {
    margin-right: 10px;
  }

  .Month {
    width: 175px;
  }

  .Day,
  .Year {
    width: 81px;
  }
}

.CustomGroupSelection {
  align-items: center;
  display: flex;
  justify-content: space-between;

  .CustomGroupSelection__Caret {
    @include transform-scale(1.3);
    cursor: pointer;
    transition: transform 0.2s ease-in-out;

    svg {
      height: 18px;
      width: auto;
    }
  }
}

.CustomGroupSelection__Content {
  background-color: $colorCDarkGray;
  display: flex;
  flex-direction: column;
  max-height: 0px;
  overflow: auto;
  -webkit-transition: max-height 1s;
  -moz-transition: max-height 1s;
  -ms-transition: max-height 1s;
  -o-transition: max-height 1s;
  transition: max-height 1s;
  width: 100%;

  .CustomGroupSelection__Group {
    margin-left: 3%;
    padding: 10px;

    .CustomGroupSelection__Group__Header {
      font-size: 20px;
      font-stretch: normal;
      font-style: normal;
      font-weight: bolder;
      letter-spacing: normal;
      line-height: normal;
      text-align: left;
    }

    .CustomGroupSelection__Group__Options {
      .CustomGroupSelection__Group__Option {
        align-items: center;
        border-bottom: 2px solid transparent;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        margin-left: 5%; 
        width: 90%;

        svg {
          @include transform-scale;
          height: 20px;
          width: auto;
        }

        &:hover {
          border-bottom: 2px solid $colorCGray;
        }
      }
    }
  }

  &.Expanded {
    max-height: 156px;
  }
}

.CustomModal {
  background-color: $colorDarkGray;
  border-radius: 8px;
  box-shadow: 24;
  left: 50%;
  max-width: 758px;
  min-height: 40%;
  outline: none;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 62%;

  @media screen and (max-width: 1024px) {
    width: 84%;
  }

  .CustomModal__Content {
    height: 100%;
    position: relative;
    width: 100%;

    .CustomModal__Close {
      @include center-content;
      background-color: white;
      border-radius: 50%;
      cursor: pointer;
      height: 50px;
      position: absolute;
      right: 20px;
      top: 20px;
      width: 50px;

      svg {
        height: 17px;
        width: auto;
      }
    }
  }
}
