@import '../../utils/variables';

$shiftAmount: 10px;

.loading-spinner {
  left: 50%;
  position: absolute;
  top: 38%;
  transform: translate(-50%, -50%);
}

.no-quests {
  margin: 30px auto 0 auto;
  max-width: 1024px;
  text-align: center;

  a {
    color: white;
    text-decoration: underline !important;
  }
}

.quest-index {
  // background-color: #18191d;
  background-color: $colorMainBlack;
  margin: 0 auto;
  width: 100%;
  // max-width: $macroScreen;

  .featured-games {
    // margin-bottom: 3em;
    position: relative;

    .carousel.carousel-slider {
      overflow: visible;
    }

    .carousel {
      .control-dots {
        // bottom: -29px;
        height: 10px;
        margin: 0;

        @media #{$mobileOnly} {
          // bottom: -28px;
        }
      }
    }

    .slide {
      @media #{$mobileOnly} {
        margin-top: 0;
        padding-bottom: 0;
        padding-top: $shiftAmount;
        transition: all 700ms ease-in-out;
      }

      .QuestCard {
        margin-top: $shiftAmount;
        transition: all 700ms ease-in-out;

        @media #{$mobileOnly} {
          margin-top: 0;
        }
      }
    }

    .slide.selected {
      display: list-item;

      @media #{$mobileOnly} {
        padding-bottom: $shiftAmount;
        padding-top: 0;
      }

      .QuestCard {
        margin-top: 0;
      }
    }
  }

  .tab-bar-new {
    // background: #28222E;
    background: linear-gradient(to bottom, #2E2834, #221C28);
    padding: 10px 269px;

    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
    color: #CAC9CE;
    text-transform: uppercase;
    font-size: 16px;
    display: flex;
    // align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 768px) {
      padding: 10px 25px;
    }

    .left {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .search-bar {
      position: relative;
      width: 235px;
      max-width: 400px;
      margin-left: auto;
    }

    .search-input {
      padding: 10px 10px 10px 40px;
      border: 3px solid #595261;
      border-radius: 20px;
      font-size: 14px;
      outline: none;
      color: #fff;
      font-weight: 500;
      font-family: Arial, Helvetica, sans-serif;
      background: linear-gradient(to bottom, #2E2834, #221C28);

      &::-webkit-input-placeholder {
        /* Chrome/Safari/Opera */
        color: #595261;
      }

      &::-moz-placeholder {
        /* Firefox 19+ */
        color: #595261;
        opacity: 1;
      }

      &:-ms-input-placeholder {
        /* IE 10+ */
        color: #595261;
      }

      &:-moz-placeholder {
        /* Firefox 18- */
        color: #595261;
      }

      &::placeholder {
        color: #595261;
      }

      &:focus::placeholder {
        color: transparent;
      }

      @media screen and (max-width: 768px) {
        font-size: 12px;
      }
    }

    .search-icon {
      position: absolute;
      top: 50%;
      left: 10px;
      /* Position the icon at the beginning */
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      pointer-events: none;
    }

    .tab-span {
      cursor: pointer;

      padding: 5px 10px;
      border-radius: 10px;

      @include vw-pixels(('margin-right': 30), $vwPixelsScale);

      @media #{$mobileOnly} {
        @include vw-pixels(('margin-right': 30));
      }

      @media #{$macroScreens} {
        margin-right: 30px
      }

      &.active {
        background-color: #3A3440;
        color: $colorMainOrange
      }
    }
  }

  .tab-line {
    background-color: $colorMainBackgroud;
    height: 2px;
    width: 100%;
  }

  .cdkey_list_content {
    background-color: $colorMainBackgroud;
    padding: 40px 269px;

    @media screen and (max-width: 768px) {
      padding: 20px 25px;
    }

  }

  .quest_list_content {
    background-color: $colorMainBackgroud;
    padding: 65px 269px;

    @media screen and (max-width: 768px) {
      padding: 20px 25px;
    }

    .in_progress,
    .past {
      flex: 1;

      .content_dots {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: $colorMainOrange;
      }

      .no-quests {
        font-size: 16px;
      }

      .content_text {
        margin-top: 12px;
        color: #E7E4E9;
        font-size: 24px;
        font-weight: 600;
        font-family: Arial, Helvetica, sans-serif;
        text-transform: uppercase;
      }
    }
  }

  .tab-bar {
    border-bottom: solid 2px #2b2e38;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    letter-spacing: 1px;
    padding: 0 250px;
    width: 100%;

    @include vw-pixels(('font-size': 18,
        'padding-bottom': 30), $vwPixelsScale);

    @media #{$mobileOnly} {
      @include vw-pixels(('font-size': 18,
          'padding-bottom': 30));
    }

    @media #{$macroScreens} {
      font-size: 18px;
      padding-bottom: 30px
    }

    .tab-span {
      cursor: pointer;

      @include vw-pixels(('margin-right': 50), $vwPixelsScale);

      @media #{$mobileOnly} {
        @include vw-pixels(('margin-right': 50));
      }

      @media #{$macroScreens} {
        margin-right: 50px
      }

      &.active {
        border-bottom: solid 2px #a142ff;
        color: #a142ff
      }
    }
  }
}