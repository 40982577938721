@import  '../../../utils/_variables';

.Log{

  .Log__wrapper {
	align-items: center;
	display: flex; 
	height: auto;
	justify-content: center;
	width: 100vw;
		.Log__title {
			font-weight: bold;
			letter-spacing: 1px;
			text-align: center;
			text-transform: uppercase;
			font-size: 40px;
		}

		.Log__container {
			display: flex;
			flex-direction: column;
			// margin-top: 30px;
			align-items: center;
			justify-content: center;
		}
  	}

}

.LogContainer{
	margin-top: 100px;
	// max-width: 1800px;
    // width: 88%;
	display: flex;
	flex-direction: column;
	// margin-top: 30px;
	align-items: center;
	justify-content: center;
}
