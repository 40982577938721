@import '../../utils/_variables';

.Contact {
  .bg-button {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #FFF;
    position: relative;
    background-color: #3a74e0;
    background: linear-gradient(180deg, #3a74e0 0%, #3a74e0 50%, #6762e8 100%);
    border-radius: 2px;
    font-weight: 900;
    text-decoration: none;
    width: 100%;
  }

  .Hero {
    display: flex;
    flex-direction: column;
    background-image: url(./assets/FloatingElements.png);
    background-size: 110vh;
    background-position: 30% 0;
    position: relative;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -ms-flex-align: start;
    align-items: flex-start;
    padding: 0 5vw 12vw;
    height: 340px;
    background-color: #0d1116;

    // @media screen and (min-width: 1024px) {
    //   // padding: 0 5vw 5vh;
    //   // height: 60vh;
    //   // background-size: 110vh;
    //   // background-position: 0 0;
    //   padding: 0 5vw 5vh;
    //   height: 60vh;
    //   background-size: 100%;
    //   background-position: 0 20%;
    //   background-repeat: no-repeat;
    // }

    .Hero__icons-container {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 0;
      overflow: hidden;

      .icon {
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        width: 10%;
        height: 10%;
        position: absolute;
        transition: opacity 1200ms ease-out;
        z-index: 1;

        &.dimmed {
          opacity: 0.16;
        }

        &.ghost {
          background-image: url('../../images/particles/icon-ghost.png');
          width: 7.55%;
          height: 8.8vw;
        }

        &.potion {
          background-image: url('../../images/particles/icon-potion.png');
          width: 5.42%;
          height: 5.42vw;
        }

        &.controller {
          background-image: url('../../images/particles/icon-controller.png');
          width: 7.03%;
          height: 4.63vw;
        }

        &.dpad {
          background-image: url('../../images/particles/icon-dpad.png');
          width: 6.8765%;
          height: 6.8765vw;
        }

        &.crown {
          background-image: url('../../images/particles/icon-crown.png');
          width: 4.9%;
          height: 4.11vw;
        }

        &.scroll {
          background-image: url('../../images/particles/icon-scroll.png');
          width: 5%;
          height: 5.21vw;
        }
      }

      .sword-container {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }

      .boxes-background {
        z-index: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        .layer1 {
          left: 32.1785% !important;
          top: -3vw !important;
        }

        .layer2 {
          left: 35% !important;
          top: 18% !important;
        }

        .layer3 {
          left: 70% !important;
          top: 23% !important;
        }

        .layer4 {
          left: 82% !important;
          top: 44% !important;
        }

        .layer5 {
          left: 12% !important;
          top: 54% !important;
        }

        .layer6 {
          left: 97% !important;
          top: 74% !important;
        }

        .layer7 {
          left: 24% !important;
          top: 80% !important;
        }

        .box {
          background: #202227;
          width: 5.58vw;
          height: 5.58vw;
          position: absolute;

          &.box1 {
            animation: boxBaubleTwo 4000ms infinite linear;
          }

          &.box2 {
            animation: boxBaubleTwo 2600ms infinite linear;
          }

          &.box3 {
            animation: boxBaubleTwo 3200ms infinite linear;
          }

          &.box4 {
            animation: boxBaubleTwo 4000ms infinite linear;
          }

          &.box5 {
            animation: boxBaubleTwo 5600ms infinite linear;
          }

          &.box6 {
            animation: boxBaubleTwo 6400ms infinite linear;
          }

          &.box7 {
            animation: boxBaubleTwo 2400ms infinite linear;
          }
        }
      }

      .boxes-foreground {
        z-index: 2;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        .layer1 {
          left: 8% !important;
          top: 48% !important;
          width: 6.2vw;
          height: 6.2vw;
        }

        .layer2 {
          left: 84% !important;
          top: 35% !important;
          width: 8.33vw;
          height: 8.33vw;
        }

        .layer3 {
          left: 28% !important;
          top: 58% !important;
          width: 1.04vw;
          height: 1.04vw;
        }

        .layer4 {
          left: 75% !important;
          top: 59% !important;
          width: 0.785vw;
          height: 0.785vw;
        }

        .layer5 {
          left: 17% !important;
          top: 63% !important;
          width: 1.04vw;
          height: 1.04vw;
        }

        .layer6 {
          left: 69% !important;
          top: 81% !important;
          width: 2.08vw;
          height: 2.08vw;
        }

        .layer7 {
          left: 23% !important;
          top: 79% !important;
          width: 1.98vw;
          height: 1.98vw;
        }

        .layer8 {
          left: 47% !important;
          top: 88% !important;
          width: 2.08vw;
          height: 2.08vw;
        }

        .box {
          position: absolute;
          width: 100%;
          height: 100%;

          &.box1 {
            animation: boxBauble 4400ms infinite linear;
            background: $colorLifeGreen;
          }

          &.box2 {
            animation: boxBauble 5600ms infinite linear;
            background: $colorEnergyBlue;
          }

          &.box3 {
            animation: boxBauble 3200ms infinite linear;
            background: $colorEnergyBlue;
          }

          &.box4 {
            animation: boxBauble 4200ms infinite linear;
            background: $colorEnergyBlue;
          }

          &.box5 {
            animation: boxBauble 2800ms infinite linear;
            background: $colorFluidPink;
          }

          &.box6 {
            animation: boxBauble 3000ms infinite linear;
            background: $colorFluidPink;
          }

          &.box7 {
            animation: boxBauble 4800ms infinite linear;
            background: $colorPurpleRay;
          }

          &.box8 {
            animation: boxBauble 3600ms infinite linear;
            background: transparent;
            border: 0.5vw solid $colorLifeGreen;
          }
        }
      }
    }

    .Hero__content {
      z-index: 2;

      @media screen and (min-width: 1024px) {
        max-width: 1280px;
        margin: 0 auto;
        width: 100%;
        position: relative;
      }

      .Hero__title {
        font-weight: 900;
        line-height: 114%;
        font-size: 13vw;
        margin: 0 0 0 0;

        @media screen and (min-width: 768px) {}

        @media screen and (min-width: 1024px) {
          font-weight: 900;
          line-height: 110%;
          font-size: 6vw;
          margin: 0 0 2vw 0;
        }

        @media (min-aspect-ratio: 16/9) {
          font-size: 10vh;
          line-height: 100%;
          margin: 0 0 2.5vh 0;
        }
      }
    }
  }

  .MainContact {
    background: #F4F4F4;
    color: #1b1e22;
    display: flex;
    flex-direction: column;
    padding: 76px 0;
    .MainContact__content {
      display: flex;
      flex-direction: column;
      position: relative;
      justify-content: center;
      align-items: flex-start;
      margin: 0 auto;
      .MainContact__content--right {
        .group_6 {
          position: relative;
          width: 772px;
          height: 567px;
          background: url(./assets/concat-bg.png) 100% no-repeat;
          background-size: 100% 100%;
          margin-left: -62px;
          // 移动端
          @media screen and (max-width: 767px) {
            width: 100%;
            height: auto;
            aspect-ratio: 772/567;
            margin: 0 auto;
          }

          // 平板端
          @media screen and (min-width: 768px) and (max-width: 1023px) {
            max-width: 90%;
            margin: 0 auto;
          }
        }

        .text-wrapper_3 {
          position: absolute;
          left: 200px;
          top: 150px;
          width: 429px;
          overflow-wrap: break-word;
          font-size: 0;
          font-family: ArialMT;
          font-weight: normal;
          text-align: center;
          line-height: 32px;
        }

        .text_8 {
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 22px;
          font-family: ArialMT;
          font-weight: normal;
          text-align: left;
          line-height: 32px;
        }

        .text_9 {
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 22px;
          font-family: Arial-BoldMT;
          font-weight: 700;
          text-align: left;
          line-height: 32px;

        }

        a {
          text-decoration: none;
        }

        .paragraph_1 {
          width: 428px;
          height: 96px;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 22px;
          font-family: ArialMT;
          font-weight: normal;
          text-align: left;
          line-height: 36px;
        }

        .box_2 {
          position: absolute;
          left: 395px;
          top: 327px;
          background-color: rgba(239, 57, 55, 1);
          width: 204px;
          height: 29px;
        }

        .paragraph_2 {
          position: absolute;
          left: 240px;
          top: 295px;
          width: 354px;
          height: 54px;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 22px;
          font-family: Arial-BoldMT;
          font-weight: 700;
          text-align: center;
          line-height: 30px;
        }


        .MainContact__content--title {
          font-family: 'Roboto', sans-serif;
          font-size: 24px;
          color: #322D38;
          text-align: center;
          font-weight: 600;
          margin: 0 0 4px 0;
          text-transform: uppercase;
          border-bottom: 2px solid transparent;
          border-image: linear-gradient(to right, transparent 43%, #FA3633 43% 57%, transparent 57%);
          border-image-slice: 1;
        }

        .MainContact__content--description {
          font-family: 'Roboto', sans-serif;
          font-size: 5vw;
          color: #1b1e22;
          margin: 0 0 15vw 0;

          @media screen and (min-width: 1024px) {
            font-size: 1.2vw;
            margin-bottom: 2vw;
            line-height: 1.8vw;
            padding-right: 25%;
          }

          a {
            text-decoration: none;
            color: #47dbc4;
            font-weight: bold;
          }
        }

        .MainContact__art {
          width: 100%;
          position: relative;

          &:before {
            content: '';
            display: block;
            padding-top: 100%;
          }

          .MainContact__decoration {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            .d1 {
              bottom: calc(15% - 6%);
              left: 6%;
              width: 6%;
              height: 6%;
              z-index: 2;
              background: #4f85e9;
            }

            .d2 {
              bottom: 15%;
              left: 0;
              width: 6%;
              height: 6%;
              z-index: 1;
              background: #a142ff;
            }

            .d3 {
              right: 0;
              bottom: 0;
              width: 6%;
              height: 6%;
              z-index: 1;
              background: #fd8d82;
            }

            .d4 {
              left: 0px;
              bottom: 0px;
              width: 65%;
              height: 15%;
              z-index: 1;
              background: #FFF;
            }

            .d5 {
              left: 65%;
              bottom: 15%;
              width: 7%;
              height: 7%;
              z-index: 1;
              background: #FFF;
            }

            .d6 {
              left: 0;
              top: 0;
              width: 6%;
              height: 6%;
              z-index: 1;
              background: #FFF;
            }

            .d7 {
              right: 0;
              top: -2%;
              width: 25%;
              height: 25%;
              background: #47dbc4;
            }
          }

          img.MainContact__art-img {
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            display: block;
          }
        }

      }
    }
  }


}