@import "../../utils/_variables";

.quest-agreement-title {
  font-family: Arial;
  font-weight: bold;
  font-size: 16px;
  color: #FDFBFF;
  text-align: center;
  margin-top: 0;
}

.payment-confirmation-warning {
  // make the font red and italic and make it not displace an elements
  font-style: italic;
  color: red;
  font-size: 12px;
  margin-bottom: 15px;
}

.payment-confirmation-input {
  // remove input box shadow
  margin-top: 20px;
  background: #211C27;
  padding: 8px 10px;
  width: 100%;
  align-self: center;
  box-shadow: none !important;
  outline: none;
  color: white;
  -webkit-appearance: none;
  font-size: 16px;
  -moz-appearance: none;
  appearance: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 25px !important;
  border-radius: 4px;
  border-right: none;
  transition: background-color 0.1s ease-in-out;
}



.QuestModal {
  background: #322D38;
  box-shadow: 1px 5px 24px 0px rgba(0, 0, 0, 0.49);
  border-bottom: 3px solid #EF3937;
  border-radius: 10px;
  width: 460px;
  max-width: 760px;
  flex-direction: column;
  position: fixed;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 50px 20px 10px;
  z-index: 100;
  top: 20%;


  &.open {
    display: flex;
    transition: all 0.4s ease;
  }

  .question {
    display: none;

    &.active {
      display: flex;
      flex-direction: column;
    }
  }

  i.close_icon {
    font-size: 20px;
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }

  .questAgreement {
    display: none;

    &.active {
      display: flex;
      flex-direction: column;
    }
  }

  h1 {
    font-family: Arial;
    font-weight: bold;
    font-size: 24px;
    color: #FDFBFF;
    text-align: center;
    margin: 0 0 40px 0;
  }

  textarea {
    margin: 10px 0 48px 0;
    border-radius: 3px;
    border: solid 2px #2c3239;
    background-color: #202227;
    padding: 17px;
    min-height: 120px;
  }

  .agreementCopy {
    max-height: 360px;
    border: solid 2px #2c3239;
    background-color: #211C27;
    border-radius: 4px;
    overflow-y: scroll;
    padding-right: 6px; // 新增内边距为滚动条预留空间

    &::-webkit-scrollbar {
      width: 6px;
      background: transparent; // 使轨道背景透明
    }

    &::-webkit-scrollbar-track {
      background: transparent; // 透明轨道
      margin: 4px 0; // 上下留出空隙
    }

    &::-webkit-scrollbar-thumb {
      background: #595261;
      border-radius: 3px;
      border: 1px solid #202227; // 添加边框与背景融合
    }

    section.QuestParticipationAgreement {
      padding-top: 20px;
      font-family: Arial;
      h1 {
        margin: 0;
        color: #CDCCD0;
        font-size: 18px;
      }

      p {
        font-weight: 400;
        font-size: 14px;
      }
    }
  }

  .buttonsBar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0 0 32px 0;
    @media #{$mobileOnly} {
      flex-direction: column;
    }


    button {
      width: 160px;
      text-transform: uppercase;
      color: #FDFBFF;
      font-weight: bold;
      height: 40px;
      &:last-child:not(:disabled) {
        background-color: #EF3937; // 最后一个按钮使用主题红色
      }

      @media #{$mobileOnly} {
        margin-bottom: 20px;
        min-width: 100px;
      }
    }
  }

  .indicators {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    span.indicator {
      border-radius: 50%;
      min-width: 6px;
      background-color: beige;
      height: 6px;
      margin: 0 6px;
      background-color: rgba(255, 255, 255, 0.25);
      -moz-transition: all 0.25s ease-in;
      -o-transition: all 0.25s ease-in;
      width: auto;
      -webkit-transition: all 0.25s ease-in;
      transition: all 0.15s ease-in;

      &.active {
        min-width: 8px;
        height: 8px;
        background-color: $colorPurpleRay;
      }
    }
  }

  @media #{$mobileOnly} {
    width: 100%;
  }
}

.checkbox-container-questmodal {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 10px 0px;
}