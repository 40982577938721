@import "../../utils/_variables";

.CCQuests1 {
  .wallet_box {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;

    a button {
      background: #2E2933;
      color: #EF3937;
      border: 2px solid #EF3937;
      padding: 12px 62px;
      border-radius: 24px;
      font-size: 18px;
      cursor: pointer;
    }

    .money {
      font-family: Arial;
      font-weight: bold;
      font-size: 60px;
      color: #EF3937;
    }
    .fa{
      line-height: 87px;
    }

    .box {
      background: #2C2733;
      padding: 32px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }

    .right {
      display: flex;
      flex-direction: column;
      gap: 20px;
      justify-content: space-between
    }

    .balance {
      display: flex;
      background: #2C2733;
      padding: 32px;
      align-items: center;
      gap: 20px;

      .icon {
        width: 55px;
        margin-right: 20px;
      }
    }


    .title {
      font-family: Arial;
      font-weight: 400;
      font-size: 18px;
      color: #7E7B82;
      line-height: 19px;
    }

    .balance_min {
      font-family: Arial;
      font-weight: bold;
      font-size: 36px;
      color: #CDCCD0;
      padding-top: 8px;
    }

    .money {
      display: flex;
      margin: 45px 0;
    }
  }

  .CCQuests__create--btn {
    width: 300px;
    height: 50px;
    letter-spacing: 1.2px;
    margin: 20px 0 20px 0;
  }

  .ToggleTable {
    .ToggleTable__header {
      background: #2b2e38;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 30px;
      border-bottom: 1px solid #454545;
      // border-top: 1px solid #1d1e23;
      border-top: 1px solid #454545;

      .ToggleTable__header-title {
        padding: 0;
        margin: 0;
        font-size: 22px;
        letter-spacing: 2.5px;
      }

      .dot-status {
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background-color: #ef3937;
        box-shadow: 0 0 9px 0 #ff987f;
        margin-left: 10px;
      }

      i {
        font-size: 30px;
        line-height: 18px;
        margin-left: auto;
      }
    }

    .ToggleTable__table-wrapper {
      background: #202227;
      padding: 20px;

      .no-results {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 44px;
        margin-top: 30px;
      }

      .row {
        border-bottom: 1px solid #1e1f25;
        padding: 0 10px;

        &:hover {
          background: rgba(255, 255, 255, 0.025);

          &:first-child {
            background: rgba(255, 255, 255, 0);
          }
        }

        &:last-child {
          border-bottom: none;
        }

        .table-cell {
          justify-content: center;
          font-size: 14px;

          &.headline {
            color: #9b9b9b;
            font-weight: 400;
            letter-spacing: 1.2px;
            
          }

          &:first-child {
            justify-content: flex-start;
          }

          &:last-child {
            justify-content: flex-end;
          }

          .remove-key {
            i {
              margin-right: 0;
            }

            display: flex;
            align-items: center;
            background: rgba(255, 255, 255, 0.05);
            border: none;
            padding: 5px 8px;
            border-radius: 3px;
            color: #FFF;
            line-height: 1;
            letter-spacing: 1px;
            font-weight: 600;
            text-transform: uppercase;
            cursor: pointer;
            font-size: 11px;
            text-decoration: none;
            margin-right: 5px;

            &:last-child {
              margin-right: 0;
            }

            &:hover {
              background: rgba(255, 255, 255, .2);
            }
          }

          .removing-key {
            background: rgba(255, 255, 255, 0);
            border: none;
            padding: 5px 8px;
            border-radius: 3px;
            color: #e91e63;
            letter-spacing: 1px;
            font-weight: 600;
            text-transform: uppercase;
            font-size: 11px;
            text-decoration: none;
            cursor: pointer;
          }
        }
      }

      .MyQuestPage__totalEarned--firstTop {
        margin-top: 20px;

        i {
          color: $colorLifeGreen;
          font-size: 20px;
        }

        .balance_max {
          color: $colorLifeGreen;
          font-size: 50px;
          margin-left: 10px;
          font-weight: bolder;
          display: inline;
        }

        a {
          text-decoration: none;
          margin-left: 360px;

          button {
            background-image: $purpleGradient;
            color: #ffffff;
            font-weight: 900;
            font-size: 16px;
            padding: 10px 30px;
            line-height: 34px;
            border-radius: 26px;
            cursor: pointer;
            display: inline;
            position: relative;
            margin-left: 0px;
            width: 220px;
            font-family: 'Roboto', sans-serif !important;
          }
        }
      }
    }

  }

  .ModalMaster {
    &.active {
      visibility: visible;
      opacity: 1;

      .Modal__wrapper {
        transform: scale(1);
      }
    }

    .Modal {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 99;
      display: flex;
      align-items: center;
      justify-content: center;
      visibility: hidden;
      opacity: 0;
      overflow-x: hidden;
      background: rgba(0, 0, 0, .5);
      align-items: flex-start;

      @media screen and (min-width: 640px) {
        align-items: center;
      }

      &.active {
        visibility: visible;
        opacity: 1;

        .Modal__wrapper {
          // transform: scale(1);
          top: -50%;
          transform: scale(1) translateY(calc(50% + 10vh));
          margin: 10vh 0;

          @media #{$mobileOnly} {
            transform: none !important;
            margin: 5vh 0 !important;
            top: 0 !important;
          }
        }
      }

      .Modal__layer {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        cursor: pointer;
      }

      .Modal__wrapper {
        width: 90%;
        max-width: 825px;
        background: #202227;
        padding: 0;
        position: relative;
        text-align: center;
        align-items: center;
        justify-content: center;
        transform: scale(0.8);

        // margin: 5vw 0 5vh 0;
        @media screen and (min-width: 640px) {
          margin: 0;
        }
      }

      .Copy {
        padding: 30px 0;
        color: $colorLight;

        select {
          min-width: 200px;
          max-width: 300px;
          background-color: transparent;
          border: none;
          border-bottom: 2px solid #83858C;
          border-radius: 0px;
          color: white;
          display: inline-block;
          padding-left: 0;
          text-align: left;
          margin: 0;
          width: 100%;

          option {
            background-color: #202227;
          }

          &:focus {
            border-bottom: 2px solid #3A74E0;
            box-shadow: none;
          }
        }

        button {
          color: $colorLight;
          background: $colorBackground2;
          padding: 4px 7px;
          margin-right: 5px;
          border: 1px solid $colorBackground2;
          border-radius: 5px;
          cursor: pointer;

          &.active {
            border: 1px solid $colorLight;
          }

          &:focus {
            outline: 0;
          }
        }

        .Modal__copy__header {}

        .Modal__copy__body {
          margin-top: 20px;
        }

        .Modal__copy__footer {
          margin-top: 20px;
        }
      }
    }
  }

  .ToggleTable__table-last {
    background: #202227;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    i {
      color: #49e1ca;
      font-size: 25px;
      margin-left: 15px;
    }

    .balance_min_title {
      color: #ffffff;
      font-size: 14px;
      margin-left: 10px;
      font-weight: bold;
      margin-top: 4px;
    }

    .balance_min {
      color: $colorLifeGreen;
      font-size: 25px;
      margin-left: 10px;
      font-weight: bolder;
      margin-right: 60px;
    }
  }
}