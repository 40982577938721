@import "../../../../../utils/_variables";

.ProductDetail__Wrapper {
  display: flex;
  flex-direction: column;

  width: 100%;

  .ProductDetailMasthead {
    // display: block;
    position: relative;

    @media #{$mobileAndTabletOnly} {
      // min-height: 800px;
      min-height: 660px;

      padding-bottom: 30px;
      -ms-overflow-style: none;
      /* IE and Edge */
      overflow-y: auto;
      scrollbar-width: none;

      /* Firefox */
      &::-webkit-scrollbar {
        display: none;
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }

    min-height: 600px;
    width: 100%;

    background-color: $colorBackground;

    .ProductDetailMasthead__Edit {
      @include hazy-button;

      // background-color: $colorBackground;

      top: 30px;

      position: absolute;
      right: 20px;

      // height: 67px;
      // width: 130px;
      padding: 15px;

      z-index: 99;

      svg {
        height: 21px;
        width: auto;
      }

      .ProductDetailMasthead__Edit__Text {
        margin-left: 7px;

        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
      }
    }

    .ProductDetailMasthead__Warning {
      @include hazy-warning;
      height: 88px;
      width: 88px;
      position: absolute;
      top: 60px;
      right: 20px;

      svg {
        height: auto;
        width: 38px;
      }

      z-index: 9999;
    }

    .ProductDetailMasthead__Cover {
      width: 100%;
      object-fit: cover;
      border-radius: 8px;

      -webkit-mask-image: -webkit-gradient(linear,
          left top,
          left bottom,
          from(rgba(0, 0, 0, 1)),
          to(rgba(0, 0, 0, 0)));
      mask-image: linear-gradient(to bottom,
          rgba(0, 0, 0, 1),
          rgba(0, 0, 0, 0));
    }

    .ProductDetailMasthead__Fade {
      min-height: fit-content;
      min-width: 100%;

      position: absolute;
      top: 0;

      background-image: linear-gradient(to bottom,
          rgba(23, 25, 28, 0),
          rgb(23, 25, 28));
    }

    .ProductDetailMasthead__Details {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: absolute;

      @media #{$mobileAndTabletOnly} {
        @include col-start;
        padding: 0px 25px;
        top: 0;
        margin-top: 100px;
      }

      @media #{$desktopAndLarger} {
        bottom: 0;
        padding: 30px 25px;
      }

      .ProductDetailMasthead__Details__Left {
        @include row-center;
        gap: 10px;
        background-color: #EF3937;
        border-radius: 16px;
        height: 32px;
        @media #{$mobileAndTabletOnly} {
          min-width: none;
          max-width: 300px;
          width: 100%;
          @include col-center;
          margin-bottom: 25px;
          margin-right: 0;
        }
        min-width: 213px;
        max-width: 213px;
        margin-right: 30px;
        cursor: pointer;

        .ProductDetailMasthead__Details__Left__Portrait {
          width: 20px;
          @media #{$mobileAndTabletOnly} {
            // background-size: cover;
            // max-width: 213px;
          }
          
        }

        .ProductDetailMasthead__Details__Left__Website {
          color: #211C27;
          font-size: 16px;
        }
      }

      .ProductDetailMasthead__Details__Right {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 100%;

        .ProductDetailMasthead__Details__Right__Name {
          font-size: 24px;
          font-weight: 900;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: 0.4px;
          text-align: left;
          text-transform: uppercase;
        }

        .ProductDetailMasthead__Details__Right__ReleaseDate {
          color: #CDCCD0;
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: 0.24px;
          text-align: left;
        }

        .ProductDetailMasthead__Details__Right__Description {
          color: #CDCCD0;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: 0.14px;
          text-align: left;
        }

        .ProductDetailMasthead__Details__Right__Developer {
          display: flex;
          align-items: center;
          margin-top: 10px;

          .ProductDetailMasthead__Details__Right__Developer__Logo {
            height: auto;
            width: 98px;
            object-fit: cover;
          }

          .ProductDetailMasthead__Details__Right__Developer__Name {
            margin-left: 15px;
            font-size: 12px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: 0.24px;
            text-align: left;
          }
        }
      }
    }
  }
}