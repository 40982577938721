@import "../../../utils/_variables";

.Portal__MainView {
  background: #211C27;
  border-radius: 10px;
  display: flex;
  height: auto;
  margin-top: 22px;
  margin-bottom: 130px;
  position: relative;
  width: 100%;
  border-bottom: 2px solid #EF3937;

  @media #{$mobileAndTabletOnly} {
    @include col-start;
  }
}