@import '../../utils/_variables';

.Store {
  margin-top: 200px;
  padding: 0 5vw;
  .Store__container {
    margin: 0 auto;
    max-width: 1024px;
  }
  .Store__title {
    font-size: 30px;
    font-weight: 700;
  }
  .Store__games {
    margin-bottom: 80px;
    .Store__games--desktop {
      display: block;
      @media #{$mobileOnly} {
        display: none;
      }
      .Store__games--row {
        display: flex;
        // justify-content: space-between;
      }
    }
    .Store__games--mobile {
      display: none;
      @media #{$mobileOnly} {
        display: block;
      }
    }
    .Store__game {
      margin-top: 50px;
      &.notLast {
        margin-right: 3.5%;
      }
      &.desktop {
        width: 31%;
      }
      .Store__game--header {
        height: 200px;
        position: relative;
        .Store__game--image {
          position: absolute;
          z-index: 2;
          height: 100%;
          width: 100%;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
        }
        .Store__game--figure1 {
          z-index: 1;
          position: absolute;
          height: 100px;
          width: 100px;
          top: -5px;
          left: 60px;
          background-color: $colorEnergyBlue;
        }
        .Store__game--figure2 {
          position: absolute;
          height: 50px;
          width: 40%;
          right: -5px;
          bottom: -5px;
          background-color: $colorPurpleRay;
        }
      }
      .Store__game--body {
        .Store__game--name {
          margin-top: 20px;
          font-size: 22px;
          font-weight: 700;
        }
        .Store__game--description {
          margin-top: 10px;
          color: #83858c;
          font-size: 14px;
          min-height: 104px;
        }
      }
      .Store__game--footer {
        margin-top: 20px;
        .Store__game--save {
          position: relative;
          margin-bottom: 40px;
          height: 30px;
          .Store__game--saveText {
            position: absolute;
            height: calc(100% + 6px);
            width: 100%;
            left: 0;
            top: 0;
            background-color: #18191d;
            z-index: 3;
            padding: 7px 0 0 15px;
          }
          .Store__game--figure3 {
            position: absolute;
            height: calc(100% + 10px);
            width: calc(30% + 5px);
            left: -5px;
            top: -5px;
            background-color: $colorLifeGreen;
            z-index: 2;
          }
        }
        button {
          background-image: linear-gradient(to bottom, #3a74e0, #4a6fe6, #5d69ea, #7061ec, #8358ed);
          color: #fff;
          cursor: pointer;
          text-decoration: none;
          text-align: center;
          font-weight: normal;
          border-radius: 2px;
          font-weight: 600;
          font-size: 14px;
          padding: 14px 30px;
        }
      }
    }
  }
  .Store__tabs {
    margin-top: 60px;
    width: 1024px;
    margin-right: auto;
    margin-left: auto;
    ul {
      margin-top: 0;
      margin-bottom: 15px;
      padding: 0;
      text-align: left;
      li {
        display: inline-block;
        font-size: 30px;
        color: #36373B;
        padding-right: 30px;
        button {
          color: white;
          display: inline-block;
          background: initial;
          outline:none;
          padding: initial;
          text-transform: inherit;
          transition: 300ms ease background;
          position: relative;
          cursor: pointer;
          z-index: 1;
          font-weight: 900;
          text-decoration: none;
          &:after {
            content: "";
            display: block;
            width: 0%;
            position: absolute;
            bottom: 0px;
            height: 8px;
            z-index: -1;
            transition: 200ms ease all;
          }
          &:hover:after,
          &.active:after {
            background: #49E1CA;
            z-index: -1;
            width: 100%;

            transition: 200ms ease all;
          }
        }
      }
      li a.active {
        color: white;
      }
      li a:hover {
        color: white;
      }
    }
  }
}