@import '../../utils/_variables';

.custom-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); // Semi-transparent background
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.custom-modal-content {
    background: $colorMainBackgroud;
    padding: 30px 0;
    width: 24%;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 1001;
}

.custom-modal-close {
    position: absolute;
    top: 0px;
    right: 8px;
    background: none;
    border: none;
    font-size: 24px;
    color: #fff;
    cursor: pointer;
}