@import '../../utils/_variables';

.LogIn {
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #202227;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #49E1CA;
  }

  align-items: center;
  background-image: url("../images/bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  font-family: Roboto;
  justify-content: center;
  margin-top: -70px;
  min-height: 100vh;
  min-width: 100%;
  padding-top: 70px;

  .errors-container {
    display: flex;
    justify-content: space-around;
    color: #EF3937;
    font-size: 18px;
    text-align: left;
    padding: 0px 25px 0px 10px;
    border-radius: 24px;
    border: 2px solid #EF3937;
    position: relative;
    margin: 0;
    background: rgba(255, 255, 255, 0.01);
    align-items: center;
    visibility: hidden;
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: ease 300ms opacity, ease 300ms visibility, ease 300ms height, ease 300ms padding, ease 300ms margin;

    &.active {
      visibility: visible;
      opacity: 1;
      max-height: inherit;
      min-height: 35px;
      padding: 5px 25px 5px 10px;
      margin: 0 0 30px 0;
    }

    &:hover {
      background: rgba(255, 255, 255, 0.03)
    }

    .error-dismiss {
      position: absolute;
      width: 18px;
      height: 18px;
      border-radius: 100%;
      right: 10px;
      background: rgba(220, 79, 79, 0.22);
      cursor: pointer;
      outline: none;
      transition: ease 200ms all;

      &::before {
        content: '';
        height: 1px;
        width: 10px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
        background: rgba(255, 255, 255, 0.5);
        transition: ease 200ms all;
      }

      &::after {
        content: '';
        height: 1px;
        width: 10px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        background: rgba(255, 255, 255, 0.5);
        transition: ease 200ms all;
      }

      &:hover {
        background: rgba(220, 79, 79, 0.5);

        &::before {
          background: rgba(255, 255, 255, 0.7);
        }

        &::after {
          background: rgba(255, 255, 255, 0.7);
        }
      }
    }
  }

  .success-container {
    color: rgb(79, 220, 79);
    font-size: 13px;
    text-align: left;
    padding: 5px 25px 5px 10px;
    border-radius: 1px;
    border: 1px solid rgb(79, 220, 79);
    border-radius: 16px;
    position: relative;
    margin: 0 0 30px 0;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: ease 300ms opacity, ease 300ms visibility, ease 300ms height, ease 300ms padding, ease 300ms margin;

    &.active {
      visibility: visible;
      opacity: 1;
      max-height: inherit;
      min-height: 35px;
      padding: 5px 25px 5px 10px;
      margin: 0 0 30px 0;
    }

    &:hover {
      background: rgba(255, 255, 255, 0.03)
    }

    .success-dismiss {
      position: absolute;
      width: 18px;
      height: 18px;
      border-radius: 100%;
      top: 8px;
      right: 10px;
      background: rgba(79, 220, 79, 0.22);
      cursor: pointer;
      outline: none;
      transition: ease 200ms all;

      &::before {
        content: '';
        height: 1px;
        width: 10px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
        background: rgba(255, 255, 255, 0.5);
        transition: ease 200ms all;
      }

      &::after {
        content: '';
        height: 1px;
        width: 10px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        background: rgba(255, 255, 255, 0.5);
        transition: ease 200ms all;
      }

      &:hover {
        background: rgba(79, 220, 79, 0.5);

        &::before {
          background: rgba(255, 255, 255, 0.7);
        }

        &::after {
          background: rgba(255, 255, 255, 0.7);
        }
      }
    }
  }

  // Client Portal
  form.client-portal {
    input {
      border: solid 2px #313337;
      background-color: #202227;
      margin: 10px 0 25px 0;
      color: #fff;
    }

    input.error,
    select.error {
      border: 2px solid #dc4f4f;
    }

    .fa-exclamation,
    .fa-check,
    error-icon {
      display: none;
    }

    .error-message {
      color: #dc4f4f;
      font-size: 12px;
      position: absolute;
      margin: -20px 0px 0 0;
      right: 40px;
    }
  }

  .ModalMaster {
    .Modal {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 99;
      display: flex;
      align-items: center;
      justify-content: center;
      visibility: hidden;
      opacity: 0;
      overflow-x: hidden;
      // background: #312C37;

      .Modal__layer {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
      }

      align-items: flex-start;

      @media screen and (min-width: 640px) {
        align-items: center;
      }

      .Modal__wrapper1 {
        width: 900px;
        height: 100%;
        background: #322D38;
        position: relative;
        align-items: center;
        display: flex;
        flex-direction: column;

        @media #{$nonDesktop} {
          width: 100%;
        }

        .icon {
          width: 50px;
          margin: 30px auto 30px auto;

          img {
            width: 100%;
          }
        }

        .Modal__content {
          padding: 70px 195px 0;

          @media #{$mobileOnly} {
            padding: 70px 0 0;
          }

          .datepicker-label {
            position: absolute;
            top: -5px;
            z-index: 0;
          }

          .date {
            margin-top: 8px !important;
          }
        }

        .Modal__left1 {
          width: 100%;
          height: 80px;
          background-color: #211C27;
          display: flex;
          align-items: center;
          justify-content: space-around;

          .logo {
            align-self: center;
            background-repeat: no-repeat;
            background-size: contain;
            height: 46px;
            width: 122px;
          }

          @media #{$mobileOnly} {
            width: 100%;
            height: 8vh;
          }

        }
        .Modal__right {
          width: 100%;
          position: relative;
          height: 100%;

          @media #{$mobileOnly} {
            width: 88%;
            height: auto;
          }

          ul.Modal__tabs {
            margin-bottom: 120px;
            padding: 0;
            text-align: center;
            display: flex;
            justify-content: space-evenly;


            li {
              display: inline-block;
              font-size: 24px;
              color: #36373B;

              a {
                display: inline-block;
                background: initial;
                padding: initial;
                text-transform: inherit;
                transition: 300ms ease background;
                position: relative;
                cursor: pointer;
                z-index: 1;
                font-weight: 500;
                text-decoration: none;
                color: #CDCCD0;

                &.Modal__tab:after {
                  content: "";
                  display: block;
                  width: 0%;
                  position: absolute;
                  bottom: 8px;
                  height: 4px;
                  z-index: -1;
                  transition: 200ms ease all;
                }

                &.Modal__tab:hover:after,
                &.Modal__tab.active:after {
                  background: #EF3937;
                  z-index: -1;
                  width: 100%;
                  transition: 200ms ease all;
                  top: 36px;
                  height: 2px;
                  left: 13px;
                  width: 70%;
                }
              }
            }

            li a.active {
              color: white;
            }

            li a:hover {
              color: white;
            }
          }

          form.signup {
            width: 100%;
          }
        }
        .Modal__close-btn {
          width: 35px;
          height: 35px;
          margin-left: auto;
          position: absolute;
          right: 30px;
          top: 20px;
          cursor: pointer;

          &:before {
            content: "";
            width: 3px;
            height: 90%;
            background: #37393D;
            display: block;
            position: absolute;
            left: 50%;
            transform-origin: center;
            transform: rotate(45deg);
          }

          &:after {
            content: "";
            width: 3px;
            height: 90%;
            background: #37393D;
            display: block;
            position: absolute;
            left: 50%;
            transform-origin: center;
            transform: rotate(-45deg);
          }

          @media #{$mobileOnly} {
            position: fixed;
            top: 30px;

            &:before,
            &:after {
              background: #fff;
            }
          }
        }

        .form__message {
          color: #7E7B82;
          font-size: 14px;
          text-align: center;
          line-height: 19px;
        }
        .form__footer {
          position: relative;
          top: 250px;
          text-align: center;
          .form__footer__left {
            .cta-button {
              background: #EF3937;
              color: #fff;
              cursor: pointer;
              text-align: center;
              border-radius: 20px;
              font-size: 18px;
              font-family: Arial;
              padding: 10px 100px;
            }
          }

          .form__footer__right {
            font-size: 12px;
            padding-top: 10px;
            border: 2px solid #202227;

            @media #{$tabletOnly} {
              padding-left: 20px;
            }

            @media #{$mobileOnly} {
              text-align: left;
              margin-bottom: 20px;
            }

            a {
              color: #3a74e0;
              font-weight: 600;
            }
          }

          .form__footer__right__2 {
            font-size: 12px;
            padding-top: 20px;

            button {
              color: #3a74e0;
              cursor: pointer;
              font-weight: 600;
            }
          }
        }

        .blurb {
          display: block;
          color: #595261;
          width: 100%;
          font-size: 16px;
          text-align: center;
          position: absolute;
          bottom: 34px;
        }
      }

      &.active {
        visibility: visible;
        opacity: 1;

        .Modal__wrapper {
          transform: scale(1);
        }
      }
    }
  }
}