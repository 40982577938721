@import '../../utils/_variables';

.Main {
  background: $colorMainBlack;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;

  .Main_Container {
    margin-bottom: auto;
    min-height: 100vh;
    background-color: #322D38;
  }
}