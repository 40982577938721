@import "../../../../utils/_variables";
.APaymentList{
  .Filters__section {
    margin-left: 30px;
    input {
      width: 300px;
    }
  }
  .APaymentList__wrapper{
    margin: 0 auto;
    padding: 30px;
    .button-style{
      width: 300px;
      height: 50px;
      border-radius: 30px;
      background: linear-gradient(to right, #45cacd, #47b8d4);
      color: #FFF;
      border: none;
      outline: none;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 22px;
      letter-spacing: 1.2px;
      margin: 20px 0 20px 0;
      cursor: pointer;
    }
    .PaymentList__loading{
      padding-top: 50px;
      text-align: center;
    }

    .APaymentList__title{
      font-weight: 500;
      letter-spacing: 3.5px;
      text-align: left;
      text-transform: uppercase;
      @include vw-pixels(
        (
          "font-size": 35,
        )
      );
    }
    .APaymentList__actions{
      button{
        @extend .button-style;
      }
    }
    .ToggleTable {
      text-align: center;
      .ToggleTable__header {
        background: #18191d;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 10px 0;
        .ToggleTable__header__left{
          padding: 0;
          margin: 0;
          font-size: 22px;
          letter-spacing: 2.5px;
          display: flex;
          flex-direction: row;
          align-items: center;

          .Search_Input {
            display: flex;
            align-items: center;

            width: 582px;
            height: 35px;

            background-color: #000;
            border-radius: 5px;
            // border-width: 1px;
            // border-style: solid;
            // border-color: #9b9b9b;

            margin-right: 17px;
            padding: 0 10px;

            svg {
              width: 20px;
              height: 21px;

              margin-right: 5px;
            }

            #search-bar-input {
              border: none;
              background: none;
              margin-bottom: 1px;
              box-shadow: none;
              width: 100%;
              margin-left: 10px;

              color: #ffffff;

              &:focus {
                outline: none;
              }

              &:disabled {
                cursor: not-allowed;
              }
            }

            &.Disabled {
              @include no-select;

              cursor: not-allowed;
            }
          }

          .date_input_head_style{
            color: white;
            font-size: 14px;
            margin-left: 20px;
          }

          .date_input_style{
            background-color: #FFFFFF;
            color: #000000;
            border-color: #333333;
            border-style: solid;
            border-width: 1px;
            border-radius: 3px;
            margin-left: 10px;
            outline: none;
            height: 30px;
            text-align: center;
          }

          button{
            margin-right: 4px;
            border-radius: 4px;
            padding: 5px 15px;
            background: #202227;
            color: #FFF;
            border: none;
            outline: none;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 22px;
            letter-spacing: 1.2px;
            cursor: pointer;
            margin-left: auto;
            &:hover{
              background: rgba(255, 255, 255, 0.2);
            }
          }
          i{
            margin-left: auto;
          }
        }
        .ToggleTable__header__right{
          margin-left: auto;
          button{
            margin-right: 4px;
            border-radius: 4px;
            padding: 5px 15px;
            background: #202227;
            color: #FFF;
            border: none;
            outline: none;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 22px;
            letter-spacing: 1.2px;
            cursor: pointer;
            margin-left: auto;
            &:hover{
              background: rgba(255, 255, 255, 0.2);
            }
          }
          i{
            margin-left: auto;
          }
        }
      }
      .ToggleTable__table-wrapper {
        background: #202227;
        padding: 30px;
        min-height: 686px;
        .no-results {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 44px;
          margin-top: 30px;
        }
        .row{
          border-bottom: 1px solid rgba(254, 254, 254, 0.1);
          padding: 0 10px;
          &:hover {
            background: rgba(255,255,255,0.025);
            &:first-child {
              background: rgba(255,255,255,0);
            }
          }
          &:last-child{
            border-bottom: none;
          }
          .table-cell{
            justify-content: center;
            &.headline{
              color: #9b9b9b;
              font-weight: 400;
              letter-spacing: 1.2px;
            }
            &.headline-clickable{
              cursor: pointer;
            }
            &:first-child{
              justify-content: flex-start;
              text-align: left;
            }
          }
        }
        button{
          border-radius: 5px;
          color:#FFFFFF;
          font-size:14px;
          outline:none;
          cursor: pointer;
          padding:5px 10px;
        }
      }
      .ToggleTable__footer {
        background: #202227;
        height: 100px;
        button{
          cursor: pointer;
          i{
            font-size: 60px;
            color: white;
          }
        }
        .ToggleTable__footer__left{
          float: left;
          text-align: left;
          padding: 30px;
          width: 50%;
        }
        .ToggleTable__footer__right{
          float: right;
          text-align: right;
          padding: 30px;
          width: 50%;
        }
      }
    }
  }
  .ModalMaster{
    &.active{
      visibility: visible;
      opacity: 1;
      .Modal__wrapper{
        transform: scale(1);
      }
    }
    .Modal{
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 99;
      display: flex;
      align-items: center;
      justify-content: center;
      visibility: hidden;
      opacity: 0;
      overflow-x: hidden;
      background: rgba(0,0,0,.5);
      align-items: flex-start;
      @media screen and (min-width: 640px){
        align-items: center;
      }
      &.active{
        visibility: visible;
        opacity: 1;
        .Modal__wrapper{
          // transform: scale(1);
          top: -50%;
          transform: scale(1) translateY(calc(50% + 10vh));
          margin: 10vh 0;
          @media #{$mobileOnly}{
            transform: none !important;
            margin:5vh 0 !important;
            top:0 !important;
          }
        }
      }
      .Modal__layer{
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        cursor: pointer;
      }
      .Modal__wrapper{
        width: 90%;
        max-width: 825px;
        background: #202227;
        padding: 0;
        position: relative;
        text-align: center;
        align-items: center;
        justify-content: center;
        transform: scale(0.8);
        // margin: 5vw 0 5vh 0;
        @media screen and (min-width: 640px){
          margin: 0;
        }
      }
      .Modal__download {
        max-width: 700px;
        .Modal__download--spinner {
          padding-top: 140px;
        }
        .Modal__download--content {
          padding-bottom: 50px;
          .Modal__download--header {
            padding: 50px 0 30px 0;
            font-size: 26px;
          }
          .Modal__download--body {
            .Modal__download--row {
              padding: 15px 0;
            }
          }
          .Modal__download--footer {
            padding: 50px 0 15px 0;
            .Modal__download--cta {
              cursor: pointer;
              padding: 5px 10px;
              border-radius: 5px;
              font-size: 18px;
              color: white;
              border: 1px solid white;
              text-decoration: none;
            }
          }
          a {
            padding: 5px 10px;
            border-radius: 5px;
            font-size: 18px;
            color: white;
            border: 1px solid white;
            text-decoration: none;
            margin: 0px 5px;
          }
        }
      }
      .Copy{
        padding: 30px 0;
        color: $colorLight;
        select{
          min-width: 200px;
          max-width: 300px;
          background-color: transparent;
          border: none;
          border-bottom: 2px solid #83858C;
          border-radius: 0px;
          color: white;
          display: inline-block;
          padding-left: 0;
          text-align: left;
          margin: 0;
          width: 100%;
          option{
            background-color: #202227;
          }
          &:focus{
            border-bottom: 2px solid #3A74E0;
            box-shadow: none;
          }
        }
        button{
          color: $colorLight;
          background: $colorBackground2;
          padding: 4px 7px;
          margin-right: 5px;
          border: 1px solid $colorBackground2;
          border-radius: 5px;
          cursor: pointer;
          &.active{
            border: 1px solid $colorLight;
          }
          &:focus{
            outline:0;
          }
        }
        .Modal__copy__header{}
        .Modal__copy__body{
          margin-top: 20px;
        }
        .Modal__copy__footer{
          margin-top: 20px;
        }
      }
    }
  }
}

.NoPayments {
  margin-top: 20px;
}

i {
  &.green {
    color: #49e1ca;
  }
  &.red {
    color: #E23645;
  }
}

.date__section {
  text-align: left;
  font-size: 10px;

  .date__picker {
    display: flex;
    width: 175px !important;
    color: white;
    background: #202228;
  }
}
