@import "../../../../../utils/_variables";

.Portraits {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 24px;
  background-color: #211C27;
  // background-color: aqua;

  .Portraits__Title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;

    // margin-bottom: 25px;

    .Portraits__Title_name {
      font-size: 24px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: 0.24px;
      text-align: left;
      text-transform: uppercase;
    }

    .Portraits__Title_View_All {
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;

      span {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.36;
        letter-spacing: normal;
        text-align: right;
        color: #B5B5B5;
        text-transform: uppercase;
      }

      img {
        width: 14px;
        height: 10px;
      }
    }
  }

  .Portraits__List {
    display: flex;
    flex-wrap: wrap;
    gap: 14px;
    width: 100%;

    @media #{$mobileAndTabletOnly} {
      @include col-evenly;
      width: 100%;
    }

    .Portraits__Card {
      @include col-between;
      align-items: flex-start;
      width: 100%;
      max-width: 188px;
      height: 286px;
      background: #2D2833;
      box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.66);
      border-radius: 8px;

      @media #{$mobileAndTabletOnly} {
        max-width: 350px;
      }
    }

    .PortraitBlank {
      justify-content: center;
      align-items: center;
      @include transform-scale(1.1);
      cursor: pointer;

      .Portraits__Blank_Icon {
        @include center-content;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: #211C27;

        svg {
          width: 30px;
          height: 30px;
        }
      }

      .Portraits__Blank_Text {
        margin-top: 10px;
        font-size: 18px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        color: #7E7B82;
        line-height: 1.33;
        letter-spacing: 0.18px;
        text-align: left;
      }
    }

    .PortraitCard {
      border-radius: 8px;
      background-color: $colorBDarkGray;

      a {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .Portraits__Card_Image {
        position: relative;
        height: 175px;
        width: 100%;

        @media #{$mobileAndTabletOnly} {
          width: 100%;
          max-width: none;
        }

        .Portraits__Card_Edit {
          @include hazy-button;

          height: 46px;
          width: 44px;
          position: absolute;
          top: 5px;
          right: 5px;

          svg {
            height: auto;
            width: 19px;
          }
        }

        .Portraits__Card_Warning {
          @include hazy-warning;

          height: 44px;
          width: 44px;
          position: absolute;
          top: 5px;
          right: 5px;

          svg {
            height: auto;
            width: 19px;
          }
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 8px 8px 0 0;
        }
      }

      .Portraits__Card_Details {
        display: flex;
        flex-direction: column;
        width: 100%;

        .Portraits__Card_Title {
          font-size: 16px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0.16px;
          text-align: left;
          border-bottom: 1px solid #EF3937;
          padding-left: 10px;
          display: flex;
          height: 40px;
          align-items: center;
        }

        .Portraits__Card_Subtitle {
          display: flex;
          align-items: center;
          padding-left: 10px;
          font-size: 12px;
          font-weight: bold;
          line-height: 3;
          letter-spacing: 0.24px;
          text-align: left;

          .Portraits__Card_Subtitle_Icon {
            width: 14px;
            height: 16px;
            margin-right: 10px;
          }
        }
      }

      .Portraits__Card_View {
        width: 100%;
        min-height: 35px;
        line-height: 35px;
        align-self: center;
        font-size: 16px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
        background: #EF3937;
        color: #322D38;
        border-radius: 0 0 8px 8px;
        cursor: pointer;
      }
    }
  }

  .Portraits__List::after {
    content: "";
    flex: auto;
  }
}