@import "../../../utils/variables";

.BrowserBack {
  align-items: center;
  display: flex;
  width: fit-content;
  padding: 12px 18px;
  background:#595261;
  color:#CDCDCD;
  border-radius: 16px 0 0 16px;
  position: absolute;
  top: 20px;
  z-index: 2;
  right: 0px;
  cursor: pointer;

  .text {margin-right: 6px;
    font-size: 16px;
  }

  svg {
    height: 16px;
    width: 16px;
  }

  &.Hover {
    &:hover {
      background: #EF3937;
      color:#fff;
     

      svg {
        fill: #fff;
      }
    }
  }
}