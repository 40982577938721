@import "../../utils/_variables";

.CCQuests1 {
  margin: 24px;

  .CCQuests__create--btn {
    width: 300px;
    height: 50px;
    letter-spacing: 1.2px;
    margin: 20px 0 20px 0;
  }

  .ToggleTable {
    margin-bottom: 30px;

    .ToggleTable__header {
      background: #2b2e38;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 30px;
      border-bottom: 1px solid #454545;
      // border-top: 1px solid #1d1e23;
      border-top: 1px solid #454545;

      .ToggleTable__header-title {
        padding: 0;
        margin: 0;
        font-size: 22px;
        letter-spacing: 2.5px;
      }

      .dot-status {
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background-color: #ef3937;
        box-shadow: 0 0 9px 0 #ff987f;
        margin-left: 10px;
      }

      i {
        font-size: 30px;
        line-height: 18px;
        margin-left: auto;
      }
    }

    .ToggleTable__table-wrapper2 {
      padding: 24px;
      background: #2C2733;

      .no-results {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 44px;
        margin-top: 30px;
      }

      .row {
        border-bottom: 1px solid #1e1f25;
        padding: 0 10px;

        &:hover {
          background: rgba(255, 255, 255, 0.025);

          &:first-child {
            background: rgba(255, 255, 255, 0);
          }
        }

        &:last-child {
          border-bottom: none;
        }

        .table-cell {
          justify-content: center;

          &.headline {
            color: #9b9b9b;
            font-weight: 400;
            letter-spacing: 1.2px;
          }

          &:first-child {
            justify-content: flex-start;
          }

          &:last-child {
            justify-content: flex-end;
          }

          .remove-key {
            i {
              margin-right: 0;
            }

            display: flex;
            align-items: center;
            background: rgba(255, 255, 255, 0.05);
            border: none;
            padding: 5px 8px;
            border-radius: 3px;
            color: #FFF;
            line-height: 1;
            letter-spacing: 1px;
            font-weight: 600;
            text-transform: uppercase;
            cursor: pointer;
            font-size: 11px;
            text-decoration: none;
            margin-right: 5px;

            &:last-child {
              margin-right: 0;
            }

            &:hover {
              background: rgba(255, 255, 255, .2);
            }
          }

          .removing-key {
            background: rgba(255, 255, 255, 0);
            border: none;
            padding: 5px 8px;
            border-radius: 3px;
            color: #e91e63;
            letter-spacing: 1px;
            font-weight: 600;
            text-transform: uppercase;
            font-size: 11px;
            text-decoration: none;
            cursor: pointer;
          }
        }
      }

      .MyQuestPage__totalEarned--firstTop {
        display: flex;
        margin-top: 20px;
        align-items: center;
        font-size: 48px;

        i {
          color: #EF3937;
        }

        .balance_max {
          color: #EF3937;
          font-size: 50px;
          margin-left: 10px;
          font-weight: bolder;
          display: inline;
        }

        button {
          background-color: #1da1f2;
          color: #ffffff;
          font-weight: 900;
          font-size: 16px;
          padding: 10px 30px;
          line-height: 34px;
          border-radius: 10px;
          display: inline;
          position: relative;
          margin-left: 390px;
          width: 400px;
        }
      }
    }

    .withdraw_head {
      display: flex;
      justify-content: space-between;
      font-size: 18px;
      color: #7E7B82;


      .withdraw_title {
        width: 50%;
      }

      .withdraw_ins a {
        width: 50%;
        font-size: 18px;
        font-weight: 400;
        color: #7E7B82;
        
        text-align: right;
        
      }

      .remove-key {
        border: none;
        padding: 5px 8px;
        border-radius: 3px;
        font-size: 18px;
        font-weight: 400;
        color: #7E7B82;
        text-decoration: none;
        cursor: pointer;

        &:hover {
          background: rgba(255, 255, 255, .2);
        }
      }

    }
  }

  .ModalMaster {
    &.active {
      visibility: visible;
      opacity: 1;

      .Modal__wrapper {
        transform: scale(1);
      }
    }

    .Modal {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 99;
      display: flex;
      align-items: center;
      justify-content: center;
      visibility: hidden;
      opacity: 0;
      overflow-x: hidden;
      background: rgba(0, 0, 0, .5);
      align-items: flex-start;

      @media screen and (min-width: 640px) {
        align-items: center;
      }

      &.active {
        visibility: visible;
        opacity: 1;

        .Modal__wrapper {
          // transform: scale(1);
          top: -50%;
          transform: scale(1) translateY(calc(50% + 10vh));
          margin: 10vh 0;

          @media #{$mobileOnly} {
            transform: none !important;
            margin: 5vh 0 !important;
            top: 0 !important;
          }
        }
      }

      .Modal__layer {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        cursor: pointer;
      }

      .Modal__wrapper {
        width: 90%;
        max-width: 825px;
        background: #202227;
        padding: 0;
        position: relative;
        text-align: center;
        align-items: center;
        justify-content: center;
        transform: scale(0.8);

        // margin: 5vw 0 5vh 0;
        @media screen and (min-width: 640px) {
          margin: 0;
        }
      }

      .Copy {
        padding: 30px 0;
        color: $colorLight;

        select {
          min-width: 200px;
          max-width: 300px;
          background-color: transparent;
          border: none;
          border-bottom: 2px solid #83858C;
          border-radius: 0px;
          color: white;
          display: inline-block;
          padding-left: 0;
          text-align: left;
          margin: 0;
          width: 100%;

          option {
            background-color: #202227;
          }

          &:focus {
            border-bottom: 2px solid #3A74E0;
            box-shadow: none;
          }
        }

        button {
          color: $colorLight;
          background: $colorBackground2;
          padding: 4px 7px;
          margin-right: 5px;
          border: 1px solid $colorBackground2;
          border-radius: 5px;
          cursor: pointer;

          &.active {
            border: 1px solid $colorLight;
          }

          &:focus {
            outline: 0;
          }
        }

        .Modal__copy__header {}

        .Modal__copy__body {
          margin-top: 20px;
        }

        .Modal__copy__footer {
          margin-top: 20px;
        }
      }
    }
  }

  .ToggleTable__table-last-withdraw {
    background: #2C2733;
    padding: 20px;
    display: flex;
    flex-direction: column;
    color: #7E7B82;
    font-size: 18px;

    i {
      color: #49e1ca;
      font-size: 25px;
      margin-left: 15px;
    }

    .balance_min_title {
      font-size: 20px;
      margin-left: 10px;
      font-weight: bold;
    }

    .balance_min {
      color: $colorLifeGreen;
      font-size: 25px;
      margin-left: 10px;
      font-weight: bolder;
      margin-right: 60px;
    }

    .pay_div {
      margin: 36px 0;
      img {
        width: 130px;
        background-color: #ffffff;
        &.active,
        &:hover {
          width: 100%;
          border-color: #1da1f2;
          border-width: 3px;
          border-style: solid;
          border-radius: 10px;
          transition: 200ms ease all;
          padding: 8px;
        }
      }
    }

    .withdraw_nobind {
      display: flex;
      flex-direction: column;
      margin-top: 80px;

      .withdraw_tip {
        color: #ff0000;
        font-size: 18px;
        margin-top: 30px;
        width: 100%;
        align-self: center;
        margin-left: 30px;
        text-align: center
      }

      .nobind_btn {

        background-color: #339900;
        color: #ffffff;
        font-weight: 300;
        font-size: 16px;
        padding: 1px 3px;
        line-height: 34px;
        border-radius: 10px;
        position: relative;
        width: 160px;
        height: 40px;
        align-self: center;
        top: 10px;
        cursor: pointer;
      }

      a {
        text-decoration: none;
        text-align: center;
        width: 200px;
        align-self: center;
      }
    }

    .withdraw_bind {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;


      .withdraw_bind_row {
        flex: 0.6 0 0;
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-right: 32px;

        .withdraw_bind_line {
          display: flex;
          justify-content: space-between;
          color: #7E7B82;
        }
      }

      .withdraw_bind_value {
        color: #ffffff;
        font-size: 14px;
        background: #211C27;
        border-radius: 8px;
        padding: 8px 16px;
      }

      .link a {
        color: #25E2C5;
        text-decoration: none;
        font-size: 16px;
      }

      .withdraw_bind_tool {
        width: 20%;
        color: #00ccff;
        font-size: 13px;
        margin-top: 10px;
      }

      .withdraw_bind_input_wrap {
        .withdraw_bind_input {
          width: 100%;
          color: #ffffff;
          font-size: 16px;
          background: #211C27;
          border-radius: 8px;
          padding: 8px 16px;
        }
      }

      .withdraw_tip {
        color: #ff0000;
        font-size: 18px;
        margin-top: 70px;
        width: 100%;
        align-self: center;
        margin-left: 0px;
        text-align: center
      }

      .bind_btn {
        width: 100%;
        align-self: center;
        margin: 16px 0 48px;
        text-align: center;

        button {
          background: #EF3937 !important;
          border: 2px solid #EF3937;
          color: #ffffff;
          font-weight: 400;
          font-size: 18px;
          padding: 1px 3px;
          line-height: 34px;
          border-radius: 20px;
          width: 334px;
          height: 40px;
          cursor: pointer;
        }
      }
    }
  }
}