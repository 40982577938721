@import '../../utils/_variables';
@import '../../utils/_util';


@mixin AboutTitle {
  font-family: 'Arial', sans-serif;
  color: #F4F4F4;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  font-size: 30px;
  margin-bottom: 80px;
}

@mixin BgPaddingBlock {
  padding-block: 80px;
}

@mixin AboutUsDetails {
  font-size: 24px;
}

.AboutUs {

  .Hero {
    align-items: flex-start;
    background-color: #0d1116;
    background-image: url('../about-us/assets/FloatingElements.png');
    background-position: 30% 0;
    background-size: 110vh;
    display: flex;
    -ms-flex-align: start;
    flex-direction: column;
    -ms-flex-pack: end;
    height: 340px;
    justify-content: flex-end;
    position: relative;
    .Hero__icons-container {
      height: 100%;
      left: 0;
      overflow: hidden;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 0;

      .icon {
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 10%;
        position: absolute;
        transition: opacity 1200ms ease-out;
        width: 10%;
        z-index: 1;

        &.dimmed {
          opacity: 0.16;
        }

        &.ghost {
          background-image: url('../../images/particles/icon-ghost.png');
          height: 8.8vw;
          width: 7.55%;
        }

        &.potion {
          background-image: url('../../images/particles/icon-potion.png');
          height: 5.42vw;
          width: 5.42%;
        }

        &.controller {
          background-image: url('../../images/particles/icon-controller.png');
          height: 4.63vw;
          width: 7.03%;
        }

        &.dpad {
          background-image: url('../../images/particles/icon-dpad.png');
          height: 6.8765vw;
          width: 6.8765%;
        }

        &.crown {
          background-image: url('../../images/particles/icon-crown.png');
          height: 4.11vw;
          width: 4.9%;
        }

        &.scroll {
          background-image: url('../../images/particles/icon-scroll.png');
          height: 5.21vw;
          width: 5%;
        }
      }

      .sword-container {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }

      .boxes-background {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 0;

        .layer1 {
          left: 32.1785% !important;
          top: -3vw !important;
        }

        .layer2 {
          left: 35% !important;
          top: 18% !important;
        }

        .layer3 {
          left: 70% !important;
          top: 23% !important;
        }

        .layer4 {
          left: 82% !important;
          top: 44% !important;
        }

        .layer5 {
          left: 12% !important;
          top: 54% !important;
        }

        .layer6 {
          left: 97% !important;
          top: 74% !important;
        }

        .layer7 {
          left: 24% !important;
          top: 80% !important;
        }

        .box {
          background: #202227;
          height: 5.58vw;
          position: absolute;
          width: 5.58vw;

          &.box1 {
            animation: boxBaubleTwo 4000ms infinite linear;
          }

          &.box2 {
            animation: boxBaubleTwo 2600ms infinite linear;
          }

          &.box3 {
            animation: boxBaubleTwo 3200ms infinite linear;
          }

          &.box4 {
            animation: boxBaubleTwo 4000ms infinite linear;
          }

          &.box5 {
            animation: boxBaubleTwo 5600ms infinite linear;
          }

          &.box6 {
            animation: boxBaubleTwo 6400ms infinite linear;
          }

          &.box7 {
            animation: boxBaubleTwo 2400ms infinite linear;
          }
        }
      }

      .boxes-foreground {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 2;

        .layer1 {
          height: 6.2vw;
          left: 8% !important;
          top: 48% !important;
          width: 6.2vw;
        }

        .layer2 {
          height: 8.33vw;
          left: 84% !important;
          top: 35% !important;
          width: 8.33vw;
        }

        .layer3 {
          height: 1.04vw;
          left: 28% !important;
          top: 58% !important;
          width: 1.04vw;
        }

        .layer4 {
          height: 0.785vw;
          left: 75% !important;
          top: 59% !important;
          width: 0.785vw;
        }

        .layer5 {
          height: 1.04vw;
          left: 17% !important;
          top: 63% !important;
          width: 1.04vw;
        }

        .layer6 {
          height: 2.08vw;
          left: 69% !important;
          top: 81% !important;
          width: 2.08vw;
        }

        .layer7 {
          height: 1.98vw;
          left: 23% !important;
          top: 79% !important;
          width: 1.98vw;
        }

        .layer8 {
          height: 2.08vw;
          left: 47% !important;
          top: 88% !important;
          width: 2.08vw;
        }

        .box {
          height: 100%;
          position: absolute;
          width: 100%;

          &.box1 {
            animation: boxBauble 4400ms infinite linear;
            background: $colorLifeGreen;
          }

          &.box2 {
            animation: boxBauble 5600ms infinite linear;
            background: $colorEnergyBlue;
          }

          &.box3 {
            animation: boxBauble 3200ms infinite linear;
            background: $colorEnergyBlue;
          }

          &.box4 {
            animation: boxBauble 4200ms infinite linear;
            background: $colorEnergyBlue;
          }

          &.box5 {
            animation: boxBauble 2800ms infinite linear;
            background: $colorFluidPink;
          }

          &.box6 {
            animation: boxBauble 3000ms infinite linear;
            background: $colorFluidPink;
          }

          &.box7 {
            animation: boxBauble 4800ms infinite linear;
            background: $colorPurpleRay;
          }

          &.box8 {
            animation: boxBauble 3600ms infinite linear;
            background: transparent;
            border: 0.5vw solid $colorLifeGreen;
          }
        }
      }
    }

    .Hero__content {
      z-index: 2;

      @media screen and (min-width: 1024px) {
        margin: 0 auto;
        max-width: 1280px;
        position: relative;
        width: 100%;
      }

      .Hero__title {
        font-size: 13vw;
        font-weight: 900;
        line-height: 114%;
        margin: 0 0 0 0;

        @media screen and (min-width: 768px) {}

        @media screen and (min-width: 1024px) {
          font-size: 6vw;
          font-weight: 900;
          line-height: 110%;
          margin: 0 0 2vw 0;
        }

        @media (min-aspect-ratio: 16/9) {
          font-size: 10vh;
          line-height: 100%;
          margin: 0 0 2.5vh 0;
        }
      }
    }
  }

  .Intro {
    background: #FFF;
    color: #1b1e22;
    display: flex;
    flex-direction: column;
    padding: 40px 400px 140px;

    .Intro__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      .Intro__content--title {
        @include AboutTitle;
        color: #322D38;
        border-bottom: 2px solid transparent;
        border-image: linear-gradient(to right, transparent 33%, #FA3633 33% 66%, transparent 66%);
        border-image-slice: 1;
      }

      .Intro__content--details {
        color: #211C27;
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        @include AboutUsDetails
      }
    }
  }

  .Brands {
    display: flex;
    flex-direction: column;
    background-image: url('./assets/BrandsBackground.png');
    @include BgPaddingBlock;

    .Brands__content {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      position: relative;
      width: 100%;
      margin: 0 auto;

      .Brands__content--title {
        @include AboutTitle;
        align-self: center;
        border-bottom: 2px solid transparent;
        border-image: linear-gradient(to right, transparent 33%, #322D38 33% 66%, transparent 66%);
        border-image-slice: 1;
      }

      .Brands__logos-wrapper {
        align-items: flex-start;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 20px;
        justify-content: center;
        margin: 0 auto;

        .Brands__logos-wrapper--logo {
          width: 260px;
          align-items: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          transition: all 0.3s ease;

          img {
            width: 100%;
            height: auto;
            object-fit: contain;
          }

          &:hover {
            transform: translateY(-5px);
          }
        }
      }
    }
  }
}