@import '../../utils/_variables';

.GQL__Wrapper {
  // border: 7px solid transparent;
  margin: 31px 0 76px 0;
  padding: 0;

  &.NoizGame {
    border-image: linear-gradient(to bottom, #0075e4, #a142ff) 1;
  }

  &.BrandGame {
    border-image: none;
  }
}

.game-quest-list {
  .hero {
    position: relative;
    text-align: center;

    @include vw-pixels(('height': 258), $vwPixelsScale);

    @media #{$mobileOnly} {
      @include vw-pixels(('height': 258));
    }

    @media #{$macroScreens} {
      height: 258px;
    }

    .bg {
      background-position: 25% 25%;
      background-size: cover;
      height: 100%;
      left: 0;
      opacity: 0.38;
      position: absolute;
      width: 100%;

      &.gradient {
        background-image: linear-gradient(to top, #18191d, rgba(24, 25, 29, 0) 50%);
        opacity: 1;
      }
    }

    .icon-container {
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
      position: absolute;

      @include vw-pixels(('height': 200,
          'left': 32,
          'padding': 30,
          'top': 32,
          'width': 200), $vwPixelsScale);

      @media #{$mobileOnly} {
        @include vw-pixels(('height': 200,
            'left': 32,
            'padding': 30,
            'top': 32,
            'width': 200));
      }

      @media #{$macroScreens} {
        height: 200px;
        left: 32px;
        padding: 30px;
        top: 32px;
        width: 200px;
      }

      .icon {
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 100%;
        width: 100%
      }
    }

    .title {
      display: inline-block;
      font-family: Roboto;
      font-stretch: condensed;
      font-weight: bold;
      text-shadow: 2px 2px 8px $colorBlack;
      transform: scale(0.85, 1);

      @include vw-pixels(('font-size': 48,
          'line-height': 258,
          'margin-left': 232), $vwPixelsScale);

      @media #{$mobileOnly} {
        line-height: 1;
        position: relative;
        top: 50%;
        transform: translateY(-50%) scale(0.85, 1);

        @include vw-pixels(('font-size': 48,
            'margin-left': 232));
      }

      @media #{$macroScreens} {
        font-size: 40px;
        line-height: 258px;
      }
    }

    .ManagedBy {
      align-items: center;
      background-image: linear-gradient(to bottom, #e8cdfe, #b7bbfd);
      display: flex;
      position: absolute;
      right: 0;
      // width: 270px;

      @include vw-pixels(('height': 36,
          'padding': 10,
          'top': 60), $vwPixelsScale);

      @media #{$mobileOnly} {
        display: none;
      }

      .handshield-icon {
        @include vw-pixels(('height': 26.601,
            'width': 22.639,
          ), $vwPixelsScale);
      }

      .ManagedBy__Label {
        color: $colorBlack;
        font-weight: bold;

        @include vw-pixels(('font-size': 14,
            'margin-bottom': 10,
            'margin-left': 10,
            'margin-right': 10,
            'margin-top': 10,
          ), $vwPixelsScale);
      }

      .ManagedBy__Logo__BG {
        @include center-content;
        border-radius: 50%;
        position: relative;

        @include vw-pixels(('height': 95,
            'width': 95,
          ), $vwPixelsScale);

        .ManagedBy__Logo__IMG {
          border-radius: 50%;
          left: 50%;
          object-fit: cover;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);

          @include vw-pixels(('height': 81,
              'width': 81,
            ), $vwPixelsScale);

          .NOIZ {
            object-fit: none;
            // background-image: url('../../images/image-98@3x.png');
            // background: url('https: //cdn.zeplin.io/61b39e894d20733a8bc58e68/assets/25ACB0C8-D38D-4BF8-A0CC-CCAD10DE44AF.png')
          }
        }

        svg {
          left: 50%;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
        }

        &.Brand {
          background-image: linear-gradient(to bottom, #e8cdfe, #b7bbfd);
        }

        &.Noiz {
          background-image: linear-gradient(to bottom, #0075e4, #a142ff);
        }
      }
    }
  }

  .quest-list {
    list-style-type: none;

    .tool-tip {
      border-radius: 1em;
      height: 3.2em;
      line-height: 3.2em;
      padding: 0 1em;
      text-shadow: 2px 2px 3px $colorBlack;
    }
  }

  .hero {
    width: 100%;
    height: 200px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    margin-bottom: 20px;
  }

  .game-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    gap: 20px;
    margin-top: 20px;
  }

  @media screen and (max-width: 768px) {
    .game-grid {
      grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
      gap: 15px;
    }
  }

  // @media (max-width: 1200px) {
  //   .game-grid {
  //     grid-template-columns: repeat(3, 1fr); /* Three items per row for smaller screens */
  //   }
  // }

  // @media (max-width: 768px) {
  //   .game-grid {
  //     grid-template-columns: repeat(2, 1fr); /* Two items per row for even smaller screens */
  //   }
  // }

  // @media (max-width: 480px) {
  //   .game-grid {
  //     grid-template-columns: 1fr; /* One item per row for mobile screens */
  //   }
  // }

  @keyframes dots-pulse {

    0%,
    80%,
    100% {
      transform: scale(0.4);
      opacity: 0.3;
    }

    40% {
      transform: scale(1);
      opacity: 1;
    }
  }

  .loading-dots {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      display: inline-block;
      width: 10px;
      height: 10px;
      margin: 0 5px;
      background: #a142ff;
      border-radius: 50%;
      animation: dots-pulse 1.4s infinite ease-in-out both;

      &:nth-child(1) {
        animation-delay: -0.32s;
      }

      &:nth-child(2) {
        animation-delay: -0.16s;
      }

      &:nth-child(3) {
        animation-delay: 0s;
      }
    }
  }

  .load-more-container {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
  }

  .load-more-button {
    background: #211C27;
    border: 2px solid #595261;
    width: 300px;
    height: 40px;
    border-radius: 20px;
    font-size: 16px;
    color: white;
    text-transform: uppercase;
    cursor: pointer;
  }
}