@import '../../utils/_variables';

.MyQuestsPage {
  padding-bottom: 355px;
  background-color: #322D38;

  a {
    text-decoration: none;
  }

  .MyQuestPage__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100px;
  }

  .MyQuestsPage__container {
    justify-content: space-between;
    padding-top: 145px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 1400px;
    // Mobile
    padding-right: 15px;
    padding-left: 15px;

    @media #{$tabletAndLarger} {
      padding-right: 0;
      padding-left: 0;
      display: flex;
    }

    .MyQuestsPage__general {
      flex-basis: 75%;
      background-color: #211C27;
      border-bottom: 3px solid #EF3937;
      border-radius: 10px;
      padding-bottom: 80px;

      .MyQuestPage__tabs {
        display: flex;
        background-color: #211C27;
        border-radius: 10px 10px 0 0;
        font-size: 14px;

        border-bottom: 3px solid #EF3937;

        .MyQuestPage__tabs--active {
          border-radius: 10px 0 0 0;
          padding: 3px 0;
        }

        .MyQuestPage__tabs--past {}

        div {
          display: flex;
          align-items: center;
          cursor: pointer;
          width: 140px;
          justify-content: center;
          color: #CDCCD0;
          text-transform: uppercase;
          background-color: #595261;
        }

        div.active {
          color: #000;
          background-color: #EF3937;
        }

        .title {
          font-size: 14px;
          font-weight: 700;
          padding: 5px 0;
          color: #8B8A90;
          text-transform: uppercase;
          margin-left: auto;
          background-color: #211C27;
        }
      }

      .MyQuestPage__quests {
        .MyQuestPage__quest {
          margin-top: 4px;
          background-color: #202227;
          position: relative;
          min-height: 120px;

          @media #{$mobileOnly} {
            padding-bottom: 20px;
            padding-top: 1px;
          }

          .MyQuestPage__quest__expand {
            position: absolute;
            top: 10px;
            right: 10px;
            font-size: 18px;
            color: $colorEnergyBlue;

            span {
              cursor: pointer;
            }

            @media #{$tabletAndLarger} {
              display: none;
            }
          }

          .MyQuestPage__quest__referral__wrapper,
          .MyQuestPage__quest__paidBounty__wrapper,
          .MyQuestPage__quest__timed__wrapper {
            background: #433C4A;
            justify-content: space-evenly;
            flex-basis: 100%;
            padding-left: 3%;
            padding-right: 3%;

            @media #{$tabletAndLarger} {
              display: flex;
              padding-left: 0;
              padding-right: 0;
            }
          }

          .MyQuestPage__quest2__referral__wrapper,
          .MyQuestPage__quest2__paidBounty__wrapper,
          .MyQuestPage__quest2__timed__wrapper {

            background: #2C2733;

            @media #{$tabletAndLarger} {
              display: flex;
            }

            justify-content: space-evenly;
            flex-basis: 100%;
            padding-left: 3%;
            padding-right: 3%;

            @media #{$tabletAndLarger} {
              display: flex;
              padding-left: 0;
              padding-right: 0;
            }
          }

          &.MyQuestPage__quest__referral {

            transition: 1000ms ease all;

            .MyQuestPage__quest__row {
              color: $colorLifeGreen;
            }

            .MyQuestPage__quest__headRow-1 {
              flex-basis: 47%;
            }

            .MyQuestPage__quest__headRow-2 {
              flex-basis: 47%;
            }

            .MyQuestPage__quest__headRow-3 {
              flex-basis: 6%;
            }

            .MyQuestPage__quest__row-1 {
              flex-basis: 47%;
            }

            .MyQuestPage__quest__row-2 {
              flex-basis: 47%;
            }

            .MyQuestPage__quest__row-3 {
              flex-basis: 6%;
              text-align: right;
            }
          }

          &.MyQuestPage__quest__paidBounty {
            .MyQuestPage__quest__headRow-1 {
              flex-basis: 31%;
            }

            .MyQuestPage__quest__headRow-2 {
              flex-basis: 31%;
            }

            .MyQuestPage__quest__headRow-3 {
              flex-basis: 31%;
            }

            .MyQuestPage__quest__headRow-4 {
              flex-basis: 7%;
            }

            .MyQuestPage__quest__row-1 {
              flex-basis: 31%;
            }

            .MyQuestPage__quest__row-2 {
              flex-basis: 31%;
            }

            .MyQuestPage__quest__row-3 {
              flex-basis: 31%;
            }

            .MyQuestPage__quest__row-4 {
              flex-basis: 7%;
              text-align: right;
            }
          }

          .MyQuestPage__quest__first {
            min-height: 250px;
            display: flex;
            justify-content: center; // 水平居中

            .MyQuestPage__quest__first_image {
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center;
              width: 160px;
              border-radius: 10px;
              margin: 25px 0;
            }
          }

          .MyQuestPage__quest__second {
            flex-basis: 76%;

            .MyQuestPage__quest__top {
              justify-content: space-between;
              margin-top: 20px;

              @media #{$tabletAndLarger} {
                display: flex;
              }

              .MyQuestPage__quest__topLeft {
                font-size: 18px;
                flex-basis: 70%;

                .MyQuestPage_quest_topLeft-headline {
                  color: $colorLightGray;
                  font-size: 14px;
                  margin: 10px 0;
                }

                .MyQuestPage_quest_topLeft-date {
                  color: $colorLightGray;
                  font-size: 14px;
                  margin-bottom: 20px;
                }
              }

              .MyQuestPage__quest__topRight {
                flex-basis: 25%;
                font-size: 14px;
                text-align: right;
                color: #8B8A90;
                padding-top: 3px;
                line-height: 18px;
                display: none;

                @media #{$tabletAndLarger} {
                  display: block;
                }

                span {
                  cursor: pointer;

                  i {
                    font-size: 14px;
                    color: #C3C2C6;
                  }
                }

              }
            }

            .MyQuestPage__quest__bottom {
              border-top: 2px solid #EF3937;
              margin-top: 12px;
              padding-top: 12px;

              @media #{$tabletAndLarger} {
                display: flex;
              }

              justify-content: space-between;

              .MyQuestPage__quest__bottomLeft {
                flex-basis: 55%;
                padding-bottom: 15px;

                .MyQuestPage__quest__headRow {
                  display: flex;
                  font-size: 14px;
                  color: #8B8A90;
                  margin-bottom: 5px;
                  margin-right: 10%;
                }

                .MyQuestPage__quest__row {
                  display: flex;
                  font-size: 18px;
                  padding: 2px 0;
                  margin-right: 10%;

                  &.affiliate_row {
                    display: block;
                    font-size: 12px;
                    color: $colorLight;

                    .affiliate_row_first {
                      display: flex;

                      .affiliate_row_first_left {
                        flex-basis: 70%;
                      }

                      .affiliate_row_first_right {
                        flex-basis: 30%;
                        text-align: right;

                        i {
                          &.error {
                            color: #dc4f4f;
                          }

                          &.success {
                            color: #49e1ca;
                          }

                          &.warning {
                            color: #ffd300;
                          }
                        }
                      }
                    }
                  }

                  &.success {
                    color: $colorLifeGreen;
                  }

                  &.fail {
                    i {
                      color: $colorDarkGray;
                    }
                  }

                  .quest-status {
                    &.completed {
                      color: $colorLifeGreen;
                    }

                    &.inProgress {
                      color: $colorEnergyBlue;
                    }

                    &.failed {
                      color: red;
                    }
                  }
                }
              }

              .MyQuestPage__quest__bottomRight {
                flex-basis: 43%;
                margin-bottom: 15px;

                i {
                  color: $colorLifeGreen;
                  font-size: 18px;
                }

                .MyQuestPage__quest__bottomRight__wrapper {
                  text-align: center;

                  .MyQuestPage__quest__bottomRight--totalEarned {
                    display: flex;
                    flex-direction: column;
                    padding: 15px 0;

                    .MyQuestPage__quest__bottomRight--first {
                      flex-basis: 55%;

                      .MyQuestPage__quest__bottomRight--firstTop {}

                      .MyQuestPage__quest__bottomRight--firstBottom {
                        font-size: 14px;
                        color: #8B8A90;
                        text-align: right;
                      }
                    }

                    .MyQuestPage__quest__bottomRight--second {
                      flex-basis: 45%;
                      align-self: flex-end;
                      font-weight: 700;
                      font-size: 30px;
                    }
                  }

                  .MyQuestPage__quest__bottomRight--details {
                    border-bottom: 1px solid $colorDarkGray;
                    display: flex;
                    padding: 15px 0;

                    .MyQuestPage__quest__bottomRight--first {
                      flex-basis: 55%;

                      .MyQuestPage__quest__bottomRight--firstTop {}

                      .MyQuestPage__quest__bottomRight--firstBottom {
                        font-size: 10px;
                        color: $colorLightGray;
                      }
                    }

                    .MyQuestPage__quest__bottomRight--second {
                      flex-basis: 45%;
                      align-self: flex-end;
                      font-weight: 700;
                      font-size: 24px;
                    }
                  }
                }
              }
            }
          }

          .MyQuestPage__quest2__first {
            .MyQuestPage__quest2__first_image {
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center;
              width: 160px;
              height: 160px;
              border-radius: 10px;
              margin: 25px 0;
            }
          }

          .MyQuestPage__quest2__second {
            flex-basis: 76%;

            .MyQuestPage__quest2__ {
              justify-content: space-between;
              margin-top: 20px;
              display: flex;
              flex-direction: column;


              .MyQuestPage__quest2__Left {

                display: flex;
                justify-content: space-between;
                font-size: 18px;
                border-bottom: 2px solid #EF3937;
                padding-bottom: 10px;

                span {
                  cursor: pointer;
                  color: #8B8A90;
                  font-size: 14px;

                  i {
                    font-size: 14px;
                    color: #FF0000;
                  }
                }
              }

              .MyQuestPage__quest2__Right {
                // flex-basis: 25%;
                font-size: 12px;
                color: $colorLightGray;
                padding-top: 3px;
                line-height: 18px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .MyQuestPage_quest2_Left-date {
                  color: $colorLightGray;
                  font-size: 14px;
                  padding-top: 12px;
                }

                .MyQuestPage__quest2__RightTop {
                  display: none;

                  @media #{$tabletAndLarger} {
                    display: block;
                    text-align: right;
                  }
                }

                .MyQuestPage__quest2__RightBottom {
                  font-weight: 700;
                  font-size: 30px;
                  color: white;
                  margin-top: 12px;

                  @media #{$tabletAndLarger} {
                    text-align: right;
                  }

                  .MyQuestPage__quest__title1 {
                    color: #8B8A90;
                    font-size: 14px;
                    font-weight: 500;
                    margin-bottom: 10px;
                  }

                  .MyQuestPage__quest__paymentMade {
                    color: #EF3937;
                    font-size: 18px;
                    font-weight: 500;
                    cursor: default;
                    padding-right: 4px;
                  }

                  .MyQuestPage__quest_processingFee {
                    font-size: 14px;
                    color: #8B8A90;
                    padding-top: 4px;
                    font-weight: 500;
                    cursor: default;
                  }
                }



              }
            }
          }
        }
      }
    }

    .MyQuestsPage__overview {
      flex-basis: 23%;
      border-radius: 10px;

      .title {
        font-size: 14px;
        font-weight: 700;
        padding: 5px 0;
        color: #8B8A90;
        text-transform: uppercase;
        text-align: center;
        background-color: #211C27;
        border-radius: 10px 10px 0 0;
      }




      .MyQuestsPage__overview__section {
        display: flex;
        align-items: center;
        text-transform: uppercase;
        color: #C3C2C6;
        font-size: 14px;
        padding: 25px;

        i {
          color: #fff;
          font-size: 25px;
        }



        .section-right {
          text-align: left;
          margin-left: 25px;
          color: #fff;
          font-weight: 700;
        }

        .section-right--bottom {
          font-size: 24px;
          line-height: 36px;
        }
      }

      .MyQuestPage__totalEarned {
        background-color: #EF3937;

        .section-left img {
          width: 89px;
        }


        .section-right--bottom {
          font-size: 36px;
          line-height: 36px;
        }
      }

      .MyQuestPage__paymentCompleted {
        background-color: #211C27;
        border-bottom: 2px solid #595261;

        .section-left img {
          width: 82px;
        }

      }

      .MyQuestPage__paymentDue {
        background-color: #211C27;
        border-radius: 0 0 10px 10px;
        .section-left img {
          width: 82px;
        }
        .section-right--bottom {
          color: #C3C2C6;
        }
      }
    }
  }

  .MyQuestsPage__NoQuests {
    margin-top: 20px;
    background-color: #202227;
    position: relative;
    min-height: 120px;
    padding: 45px 0;
    text-align: center;

    .MyQuestsPage__NoQuests__image {
      min-height: 102px;
      img{
        width: 100px;
      }
    }

    .MyQuestsPage__NoQuests__title {
      margin-top: 30px;
      font-weight: 700;
      font-size: 22px;
    }

    .MyQuestsPage__NoQuests__message {
      margin-top: 10px;
      font-size: 16px;
      font-weight: 500;
      color: $colorLightGray;
    }

    .MyQuestsPage__NoQuests__action {
      margin-top: 20px;

      a {
        color: $colorEnergyBlue;
        text-decoration: none;
        font-weight: 700;
        font-size: 14px;
      }
    }
  }

  .tooltip {
    position: relative;
    display: inline-block;
    color: white;
  }

  .tooltiptext {
    font-size: 10px;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 200px;
    cursor: default;
    background-color: $colorBackground;
    color: #fff;
    text-align: center;
    padding: 10px 0;
    border-radius: 6px;
    bottom: 30px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 100;

    &:before {
      content: "";
      z-index: 99;
      position: absolute;
      width: 11px;
      height: 11px;
      bottom: -4.5px;
      left: 0;
      right: 66px;
      margin-left: auto;
      margin-right: auto;
      background-color: $colorBackground;
      border-top: solid 1px $colorBackground;
      border-left: solid 1px $colorBackground;
      transform: rotate(45deg);
    }
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
}