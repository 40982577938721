@import "../../utils/variables.scss";
@import "../../utils/util.scss";

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,900;1,300&display=swap");

$theMaxWidth: 80vw;
$uniformPadding: 4vw;

// px-vw
$design-width: 1920px;


@mixin px-to-vw($styles: ()) {
  @each $p, $v in $styles {
    @if $v ==0 or $v ==0px {
      #{$p}: 0; // 直接返回无单位0
    }

    @else {
      #{$p}: calc($v / $design-width) * 100 * 1vw;
    }
  }
}

@mixin px-to-vw-mq($styles: (), $min: 0px, $max: 1919px) {
  @media (min-width: 768px) and (max-width: 1919px) {
    @each $p, $v in $styles {
      @if $v ==0 or $v ==0px {
        #{$p}: 0; // 直接返回无单位0
      }
      @else {
        #{$p}: calc($v / $design-width) * 100 * 1vw;
      }
    }
  }

}


@mixin trianglePokeyLeft {
  & :after {
    border: 24px solid transparent;
    border-left: 0;
    border-right-color: #202227;
    content: "";
    height: 0;
    left: 0;
    margin-left: -24px;
    margin-top: -24px;
    position: absolute;
    top: 50%;
    width: 0;
    z-index: -1;
  }

  @media #{$mobileOnly} {
    & :after {
      border: 10px solid transparent;
      border-left: 0;
      border-right-color: #202227;
      height: 0;
      left: 0;
      margin-left: -9.5px;
      margin-top: -9.5px;
      position: absolute;
      top: 50%;
      width: 0;
      z-index: -1;
    }
  }

  @media #{$tabletOnly} {}
}

@mixin trianglePokeyRight {
  & :after {
    border: 24px solid transparent;
    border-left-color: #202227;
    border-right: 0;
    content: "";
    height: 0;
    margin-right: -24px;
    margin-top: -24px;
    position: absolute;
    right: 0;
    top: 50%;
    width: 0;
  }
}

@mixin brandHeroText {
  color: white;
  font-family: Arial;
  font-size: 36px;
  font-weight: 300;
}

@mixin centerFlex {
  align-items: center;
  display: flex;
  justify-content: center;
}

@mixin vertFlex {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 0vw $uniformPadding 0vw $uniformPadding;
}

@mixin spaceFlex {
  align-items: center;
  display: flex;
  justify-content: space-around;
}

@mixin brandTwoGrid {
  align-items: center;
  display: flex;
  justify-content: center;

  @media #{$mobileOnly} {
    flex-direction: column;
  }

  @media #{$tabletAndLarger} {
    flex-direction: row;
  }
}

@mixin medLargeHeadline($color: #F4F4F4) {
  color: $color;
  font-family: Arial;
  text-align: center;
  font-size: 36px;
  font-weight: bold;
  text-transform: uppercase;

  @media (min-width: 768px) and (max-width: 1919px) {
    @include px-to-vw((font-size: 36px));
  }
}




@mixin brandTitleMargin($marginTop: 0px, $marginBottom: 110px) {
  margin-top: $marginTop;
  margin-bottom: $marginBottom;

  @media (min-width: 768px) and (max-width: 1919px) {
    @include px-to-vw((margin-top: $marginTop,
        margin-bottom: $marginBottom));
  }
}

@mixin brandTitleBorder($color: #322D38,
  $gradient-start: 33%,
  $gradient-end: 66%) {
  border-bottom: 2px solid transparent;
  border-image: linear-gradient(to right,
      transparent $gradient-start,
      $color $gradient-start $gradient-end,
      transparent $gradient-end );
  border-image-slice: 1;
}

@mixin imgSize($width, $height) {
  width: $width;
  height: $height;
  object-fit: contain;

  @media (min-width: 768px) and (max-width: 1919px) {
    @include px-to-vw((width: $width, height: $height));
  }
}

@mixin brandMedText {
  color: #ffffff;
  font-family: Roboto;
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1.6;
  text-align: center;

  @media #{$mobileOnly} {
    font-size: 14px;
  }

  @media #{$tabletOnly} {
    font-size: 16px;
  }

  @media #{$desktopAndLarger} {
    font-size: 20px;
  }
}

@mixin brandPurpleHeroText {
  color: #a142ff;
  font-family: Roboto;
  font-size: 45px;
  font-size: calc(min(3vw, 50px));
  font-stretch: normal;
  font-style: normal;
  font-weight: 900;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;

  @media #{$mobileOnly} {
    font-size: 6vw;
  }

  @media #{$tabletOnly} {
    font-size: 4vw;
  }
}

@mixin brandRedHeroText {
  color: #F53D3B;
  font-family: Roboto;
  font-size: 60px;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;

  @media #{$mobileOnly} {
    font-size: 6vw;
  }

  @media (min-width: 768px) and (max-width: 1919px) {
    @include px-to-vw((font-size: 48px));
  }
}

@mixin brandSubtitleWhiteText($color: #ffffff,
  $fontSize: 18px) {
  font-family: Arial;
  text-align: center;
  color: $color;
  font-size: $fontSize;

  @media (min-width: 768px) and (max-width: 1919px) {
    @include px-to-vw((font-size: $fontSize));
  }
}

@mixin greyBrandBodyText {
  color: #83858c;
  font-family: Roboto;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1.6;

  @media #{$mobileOnly} {
    font-size: 16px;
    line-height: 2;
  }

  @media #{$tabletOnly} {
    font-size: 18px;
  }

  @media #{$desktopAndLarger} {
    font-size: 20px;
  }
}

.brand-service-slide-a {
  background-image: url('./images/mask1.png');
  background-repeat: no-repeat;
  background-size: cover;

  &::before {
    background-image: url('./images/111.png');
    background-repeat: no-repeat;
    background-size: cover;
    content: '';
    position: absolute;
    opacity: 1s; // 透明度值0-1
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
}

.brand-service-slide-a-text {
  @include centerFlex();
  @include medLargeHeadline();
  font-weight: bold;
  text-align: center;
  z-index: 20;
  text-transform: uppercase;
  position: relative;
  

  .brand-service-slide-a-text-content {
    @include brandTitleBorder(#FA3633);
    width: 679px;
    @include px-to-vw-mq((
    width: 690px,
  ) );  

    @media #{$tabletOnly} {
      min-width: 50%;
    }
  }
}

.brand-service-master {
  height: 100%;
  // min-height: 1000px;
  position: absolute;
  width: 100%;
}

.brand-service-master-flextainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-items: center;

  div {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  ::-webkit-scrollbar {
    display: none;
  }
}

.brand-service-clipmask {
  height: 110px;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20;
}

.brand-white-button {
  border: solid 2px #EF3937;
  border-radius: 24px;
  color: #EF3937;
  cursor: pointer;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 900;
  height: 48px;
  text-align: center;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  width: 180px;
  @include px-to-vw-mq((
    font-size: 16px,
    height: 48px,
    width: 180px,
    border-radius: 24px
  ) );  

  &:focus {
    outline: none;
  }
}

.brand-image-adorned {
  position: relative;

  img {
    z-index: 2;
  }
}

.brand-image-adorned:before {
  background-color: #3a74e0;
  content: "";
  height: 88px;
  left: -15px;
  position: absolute;
  top: -15px;
  width: 88px;
  z-index: -1;
}

.brand-source {
  color: #81858c;
  font-family: Roboto;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  height: 17px;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
  width: 375px;
  z-index: 1000;
}

.brand-source-text {
  font-size: 10px;
}