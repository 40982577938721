@import "../../utils/_variables";

.Main__Header {
  height: $headerHeight;
  left: 0;
  padding: 0 269px;
  position: relative;
  top: 0;
  width: 100%;
  background: #211C27;

  @media screen and (max-width: 768px) {
    padding: 0 25px;
  }

  &.white {
    background: $colorBackground_light;

    .navigation {
      &.open {
        @media #{$mobileOnly} {
          background: $colorBackground2_light;
        }
      }

      .navigation-right {
        padding-right: 15px;
      }

      ul li a {
        color: #fff;
      }
    }

    .toggle-mobile-menu {
      span {
        background: $colorLightGray;
      }
    }
  }

  .Main__Header__wrapper {
    display: flex;
    position: relative;
    justify-content: flex-start;
    flex-direction: row;
    z-index: 10;
    height: 100%;

    &.forceFullWidth {
      margin: 0 auto;
      max-width: calc(100% - 60px);
    }

    &.constrain {
      margin: 0 auto;
      max-width: $macroScreen;
    }
  }

  .borderBottom {
    border-bottom: 1px solid transparent;
  }

  .logo {
    align-items: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-y: 40%;
    width: 90px;
    height: $headerHeight;
  }

  &.basic-menu {
     position: absolute;
    .Main__Header__wrapper {
      width: 100%;
      margin: 0 auto;
    }
  }
}