@import url('https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,700,900');
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "utils/noiz-font.css";
@import 'utils/_variables';


*,
*::after,
*::before {
	box-sizing: border-box;
}

body {
	margin: 0;
	padding: 0;
	font-family: 'Roboto', sans-serif !important;
	color: #1a1b20;
	height: auto;
	// background: #18191d;
	background: #221C28;
}

body.no-overflow {
	overflow: hidden;
}

.Main_Container {
	color: #FFF;
}

.CTAButton__blue {
	cursor: pointer;
	background: linear-gradient(to right, #48ced1, #40a2dc);
	outline: none;
	padding: 15px 30px;
	font-size: 14px;
	color: #FFF;
	text-transform: uppercase;
	font-weight: bold;
	border-radius: 30px;
	border: none;
	transition: transform 200ms ease-out;
	backface-visibility: hidden;
	-webkit-backface-visibility: hidden;
}

.CTAButton__purple {
	cursor: pointer;
	height: 50px;
	border-radius: 22px;
	background: linear-gradient(to bottom, #3a74e0, #4a6fe6 31%, #5d69ea 67%, #8358ed);
	font-weight: bold;
	color: #FFF;
	border: none;
	outline: none;
	font-family: Roboto;
	font-size: 14px;
	font-style: normal;
	font-stretch: normal;

	&.disabled {
		cursor: not-allowed;
		opacity: 0.5;
	}
}

.CTAButton__white {
	cursor: pointer;
	height: 50px;
	border-radius: 22px;
	font-weight: bold;
	color: $colorWhite;
	border: 2px solid $colorWhite;
	outline: none;
	font-family: Roboto;
	font-size: 14px;
	font-style: normal;
	font-stretch: normal;

	&.disabled {
		cursor: not-allowed;
		opacity: 0.5;
	}
}

.CTAButton__lightGrey {
	cursor: pointer;
	height: 50px;
	border-radius: 26px;
	border: solid 2px rgba(255, 255, 255, 0.1);
	background-color: rgba(255, 255, 255, 0.1);
	font-weight: bold;
	color: $colorWhite;
	outline: none;
	font-family: Roboto;
	font-size: 14px;
	font-style: normal;
	font-stretch: normal;

	&.disabled {
		cursor: not-allowed;
		opacity: 0.9;
	}

	@include greyHoverRipple;
}

.CTAButton__cyan {
	cursor: pointer;
	height: 50px;
	border-radius: 26px;
	border: solid 2px rgba(75, 224, 202, 0.5);
	background-color: rgba(75, 224, 202, 0.5);
	font-weight: bold;
	color: $colorWhite;
	outline: none;
	font-family: Roboto;
	font-size: 14px;
	font-style: normal;
	font-stretch: normal;

	&.disabled {
		cursor: not-allowed;
		opacity: 0.9;
	}

	@include greyHoverRipple;
}

.CTAButton__red {
	cursor: pointer;
	height: 50px;
	border-radius: 22px;
	background-color: rgba(239, 58, 55, 0.2);
	font-weight: bold;
	color: #FFF;
	border: solid 2px #ef3a37;
	;
	outline: none;
	font-family: Roboto;
	font-size: 14px;
	font-style: normal;
	font-stretch: normal;

	&.disabled {
		cursor: not-allowed;
		opacity: 0.7;
	}
}

.noiz-icon {
	background-size: contain;
	background-repeat: no-repeat;
	@include vw-pixels(('width': 54,
			'height': 54), 1.6)
}

.clearfix {
	&:after {
		content: " ";
		display: block;
		height: 0;
		clear: both;
	}
}

.two-column-container {
	@media #{$tabletAndLarger} {
		display: flex;
		align-items: center;

		.column {
			width: 50%;
		}
	}
}

.content-block {
	background: #1b1b1d;
	color: #fff;
	margin-bottom: 40px;
	padding: 6%;
}

.content-block-header {
	color: #fff;
	font-size: 18px;
	letter-spacing: 0.12em;
	font-weight: 300;
	margin-bottom: 10px;
}

.site-gradient-btn {
	background: rgba(90, 209, 211, 1);
	background: -moz-linear-gradient(left, rgba(90, 209, 211, 1) 0%, rgba(64, 192, 231, 1) 64%, rgba(90, 191, 216, 1) 100%);
	background: -webkit-gradient(left top, right top, color-stop(0%, rgba(90, 209, 211, 1)), color-stop(64%, rgba(64, 192, 231, 1)), color-stop(100%, rgba(90, 191, 216, 1)));
	background: -webkit-linear-gradient(left, rgba(90, 209, 211, 1) 0%, rgba(64, 192, 231, 1) 64%, rgba(90, 191, 216, 1) 100%);
	background: -o-linear-gradient(left, rgba(90, 209, 211, 1) 0%, rgba(64, 192, 231, 1) 64%, rgba(90, 191, 216, 1) 100%);
	background: -ms-linear-gradient(left, rgba(90, 209, 211, 1) 0%, rgba(64, 192, 231, 1) 64%, rgba(90, 191, 216, 1) 100%);
	background: linear-gradient(to right, rgba(90, 209, 211, 1) 0%, rgba(64, 192, 231, 1) 64%, rgba(90, 191, 216, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5ad1d3', endColorstr='#5abfd8', GradientType=1);
	color: #FFF;
	cursor: pointer;
	text-decoration: none;
	@include vw-pixels(('padding-top': 16,
			'padding-bottom': 16,
			'padding-left': 100,
			'padding-right': 100,
			'font-size': 16,
			'border-radius': 64), 2);
	letter-spacing: 0.01em;
	transition: transform 200ms ease-out;
	backface-visibility: hidden;
	-webkit-backface-visibility: hidden;
	border: none;
	position: relative;
	text-transform: uppercase;
	outline: 0 !important;

	&.white {
		background: #fff;
		color: #52b8d0;
	}
}


.flex-table {
	display: flex;
	flex-flow: column nowrap;
	justify-content: space-between;

	.row {
		width: 100%;
		display: flex;
		flex-flow: row nowrap;
	}

	.table-cell {
		display: flex;
		flex-flow: row nowrap;
		flex-grow: 1;
		flex-basis: 0;
		padding: 0.5em;
		word-break: break-word;
		padding: 16px 0;
		font-weight: 300;

		@media #{$mobileOnly} {
			justify-content: center;
			text-align: center;
			align-items: center;
		}
	}
}


//OVERRIDES DEFAULT TOP LEFT ALERTS WITH MIDDLE OF PAGE MODAL ALERT
.notifications-wrapper {
	.notifications-tl {
		width: 100% !important;
		height: 100% !important;
		padding: 20px;
		background: rgba(0, 0, 0, 0.4);

		>div {
			padding: 24px 40px !important;
			position: absolute !important;
			width: 96% !important;
			height: auto !important;
			max-width: 440px;
			top: 50%;
			left: 50% !important;
			transform: translate(-50%, -50%) !important;
			line-height: 140%;
		}

		.notification-info {
			border-top: 2px solid #5abfd8 !important;
		}

		h4 {
			color: #5abfd8 !important;
		}
	}
}

.Global__tabs {
	display: flex;
	background-color: #202227;
	height: 70px;
	margin-bottom: 20px;

	.Global__tab {
		display: flex;
		align-items: center;
		cursor: pointer;
		width: 140px;
		justify-content: center;
		color: #83858c;
		border-bottom: 2px solid #202227;
		cursor: pointer;
		font-weight: 900;

		&.active {
			color: $colorLifeGreen;
			border-bottom: 4px solid $colorLifeGreen;
		}

		&:hover {
			color: $colorLifeGreen;
		}

		span {
			background: #9b9b9b;
			padding: 2px 3px;
			border-radius: 5px;
			font-size: 12px;
			font-weight: bold;
		}

		i {
			&.red {
				color: red;
			}
		}
	}
}

.Global__subTabs {
	display: flex;
	margin-bottom: 20px;

	.Global__subTab {
		display: flex;
		align-items: center;
		cursor: pointer;
		justify-content: center;
		padding: 8px 16px;
		color: #83858c;
		border: 1.5px solid #202227;
		border-radius: 3px;
		margin-right: 20px;
		font-weight: 900;

		&.active {
			color: $colorLifeGreen;
			border: 2px solid $colorLifeGreen;
		}

		&:hover {
			color: $colorLifeGreen;
		}
	}

}

.container-radios-yes,
.container-radios-no {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	margin-right: 15px;
	cursor: pointer;
	font-size: 16px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 25px;
		width: 25px;
		border: 1.5px solid #83858C;
		background-color: #202227;
		border-radius: 50%;
	}
}

.container-radios-yes {
	input:checked~.checkmark {
		border: 1.5px solid #2196F3;
		background-color: #2196F3;
	}
}

.container-radios-no {
	input:not(:checked)~.checkmark {
		border: 1.5px solid #2196F3;
		background-color: #2196F3;
	}
}

// Modals
.NoizModal {
	background-color: #202227;
	position: fixed;
	width: 200px;
	height: 100px;
	display: none;

	&.open {
		display: flex;
	}
}

.NoizModalOverlay {
	position: fixed;
	z-index: 100;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.5);
	display: none;

	&.open {
		display: flex;
	}
}