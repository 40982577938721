//// filepath: /d:/workspace/1/branchs/noiz-frontend/src/components/quest/TwitchAuthModal.scss
.twitch-auth-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.twitch-auth-modal {
    background: #322D38;
    border-radius: 8px;
    padding: 20px;
    width: 600px;
    height: 400px;
    text-align: center;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;

    .modal-close {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
        svg {
            width: 24px;
            height: 24px;
        }
    }

    .modal-header {
        // margin-bottom: 20px;

        .red-circle {
            width: 115px;
            height: 115px;
            border: 4px solid #EFD337;
            border-radius: 50%;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .red-x {
            color: #EFD337;
            font-size: 82px;
            line-height: 43px;
        }

        .auth-title {
            margin-top: 40px;
            font-size: 24px;
            font-weight: bold;
            color: #FFFFFF;
        }
    }

    .modal-body {
        margin-top: 10px;
        font-size: 16px;
        color: #CDCCD0;
    }

    .modal-footer {
        .retry-button {
            margin-top: 40px;
            background: #EF3937;
            color: #FFFFFF;
            border: none;
            border-radius: 50px;
            padding: 10px 100px;
            cursor: pointer;
            font-size: 18px;
        }
    }
}