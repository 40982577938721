@import '../../utils/_variables';

.QuestSelector {
	position: relative;
	background: #211C27;
	border: 1px blue;
	border-radius: 8px;
	box-shadow: 0 0 20px 0 rgba(255, 255, 255, 0.1);
	color: white;
	width: 100%;
	height: 588px;
	font-weight: 900;
	padding-left: 40px;
	display: flex;
	flex-direction: column;

	.quest-rejection-message {
		margin-top: 23px;
		color: #EF3937;
		font-size: 20px;
		// padding: 0px 25px 25px 25px;
	}

	.quest-goal-message {
		color: rgb(159, 232, 155);
		font-size: 21px;
		padding: 10px 25px 15px 25px;
	}

	.quest-insufficient-message {
		background-color: rgba(255, 68, 68, 0.1);
		color: #EF3937;
		padding: 5px;
		margin-top: 5px;
		font-weight: 400;
		font-family: Arial;
		// margin: 10px 25px 0 25px;
		width: 100%;
		border-radius: 5px;
	}

	&.status {
		margin: 80px 0 0 0;
	}

	@media #{$mobileAndTabletOnly} {
		position: relative;
		min-width: 100%;
		right: 0;
		top: 0;

		&.sticky {
			position: relative;
			right: 0;
			top: 0;
		}

		&.status {
			margin: 0 0 0 0;
		}
	}

	.MuiInput-underline:before {
		border: none !important;
	}


	.MuiInput-root {
		background-color: #202227;
		border: solid 2px #2c3239;
		border-radius: 3px;
		height: 52px;
		width: 100%;
	}
	.text-wrap{
		padding: 16px 0;
	}

	.title-quest-text {
		font-size: 26px;
		color: white;
		font-weight: bold;
		display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
	}
	.sm_text{
		font-size: 18px;
	}

	.paymentBar {
		// box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
		// background-image: linear-gradient(to top, rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.05));
		display: flex;
		flex-direction: column;

		.selector-red-line {
			width: calc(100%);
			height: 2px;
			background-color: $colorMainOrange;
		}

		span {
			color: white;
			font-weight: 900;
			@include vw-pixels(('font-size': 40,
				));
		}

		.disclaimer {
			text-decoration: underline;
			font-size: 14px;

			&:hover {
				cursor: help;
			}
		}

		.paymentBarTitle {
			margin-top: 14px;
			color: #7E7B82;
			font-size: 14px;
			text-transform: uppercase;
		}
	}

	.selections {
		width: 100%;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;

		.gamingPlatform {
			width: 100%;

			.questDatesTitle {
				color: #7E7B82;
				font-size: 14px;
				margin-top: 13px;
			}

			.selector-red-line {
				width: calc(100%);
				height: 2px;
				margin-top: 13px;
				background-color: $colorMainOrange;
			}

			.platform-text {
				font-weight: 400;
				font-size: 20px;
				padding: 5px 0;
				text-transform: uppercase;
			}
		}

		.questDates {
			width: 100%;
			padding: 0;

			.questDatesTitle {
				color: #7E7B82;
				font-size: 14px;
				margin-top: 23px;
			}

			.input-select-corrections {
				background-color: #211C27;
				border-radius: 30px; // 椭圆形边框
				border: 2px solid #595361; // 边框颜色
				padding: 0 5px;
				font-size: 14px;

				// Select下拉框样式
				.MuiSelect-root {
					background-color: #211C27;
					color: white;
				}

				// 选项菜单样式
				.MuiMenu-paper {
					background-color: #211C27;
					border-radius: 30px;
				}

				// 选项样式
				.MuiMenuItem-root {
					color: white;

					&:hover {
						background-color: rgba(255, 255, 255, 0.1);
					}
				}

				// 下拉箭头颜色
				.MuiSelect-icon {
					color: #595361;
				}

				// 如果使用自定义箭头图标
				svg {
					fill: #595361;
				}
			}
		}

		h3 {
			text-align: left;
			color: $colorGray;
			text-transform: uppercase;
			letter-spacing: 1px;
			font-weight: bold;
			@include vw-pixels(('font-size': 12,
				));
			margin: 25px 0 12px 0;
		}

		.fa {
			margin: 0 15px 0 0;
			@include vw-pixels(("font-size": 10,
				));
		}
	}

	button {
		// width: 228px;
		// margin: 0 24px;

		&.ineligible {
			cursor: not-allowed;
		}
	}

	.buttonsBarQS {
		display: flex;
		justify-content: space-between;
		width: 100%;
		margin-top: 20px;
		padding: 0 35px 0 0;
	}

	.shareBtn {
		width: 52px;
		height: 52px;
		border-radius: 4px;
		border: solid 2px #2c3239;
		background-color: #202227;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;

		&:hover {
			box-shadow: inset 0 0 24px 30px rgba(255, 255, 255, .08);
			transition: 0.8s ease;
		}
	}

	a.help-button {
		align-items: center;
		color: $colorMainOrange;
		display: flex;
		width: auto; // 自动宽度，根据内容撑开
		font-weight: normal;
		justify-content: center;
		right: 10px;
		bottom: 10px;
		position: absolute;
		text-decoration: none;
		@include whiteTextHover;
		font-size: 16px;
		// width: 100%;

		.fa {
			margin: 0 6px 0 0;
		}

		@media #{$mobileAndTabletOnly} {
			margin: 36px 0 0 0;
			position: initial;
		}
	}
}

.selector-red-line {
	width: 100%;
	height: 1px;
	background-color: $colorMainOrange;
}

.MuiSelect-selectMenu {
	padding-left: 10px !important;
	padding-right: 0px;
}

.PaymentRender {
	font-size: 25px;
	color: #EF3937;
	height: 24px;
}

.select-corrections-str {
	font-size: 15px;
	color: #CDCCD0;
	font-weight: 400;
	font-family: Arial;
	line-height: 30px;
}

.platformSelected {

	.questDatesTitle {
		color: #7E7B82;
		font-size: 14px;
		margin-top: 13px;
		text-transform: uppercase;
	}

}

.SelectorButton__lightGrey {
	cursor: pointer;
	height: 41px;
	width: 100%;
	border-radius: 26px;
	// border: solid 2px rgba(255, 255, 255, 0.1);
	background-color: rgba(255, 255, 255, 0.1);
	font-weight: bold;
	color: $colorWhite;
	outline: none;
	font-family: Roboto;
	font-size: 20px;
	line-height: 30px;
	font-style: normal;
	font-stretch: normal;
	text-transform: uppercase;

	&.disabled {
		cursor: not-allowed;
		opacity: 0.9;
	}

	@include greyHoverRipple;
}

.SelectorButton__purple {
	cursor: pointer;
	height: 41px;
	width: 100%;
	border-radius: 22px;
	background: #FA3633;
	font-weight: bold;
	color: #FFF;
	border: none;
	outline: none;
	font-family: Roboto;
	font-size: 14px;
	font-style: normal;
	font-stretch: normal;
	text-transform: uppercase;

	&.disabled {
		cursor: not-allowed;
		opacity: 0.5;
	}
}

.SelectorButton__red {
	cursor: pointer;
	height: 41px;
	width: 100%;
	border-radius: 22px;
	background-color: #FA3633;
	font-weight: bold;
	color: #FFF;
	outline: none;
	font-family: Roboto;
	font-size: 20px;
	line-height: 30px;
	font-style: normal;
	font-stretch: normal;
	text-transform: uppercase;

	&.disabled {
		cursor: not-allowed;
		opacity: 0.7;
	}
}

.SelectorButton__cyan {
	cursor: pointer;
	height: 41px;
	width: 100%;
	border-radius: 26px;
	border: solid 2px rgba(75, 224, 202, 0.5);
	background-color: rgba(75, 224, 202, 0.5);
	font-weight: bold;
	color: $colorWhite;
	outline: none;
	font-family: Roboto;
	font-size: 14px;
	font-style: normal;
	font-stretch: normal;

	&.disabled {
		cursor: not-allowed;
		opacity: 0.9;
	}

	@include greyHoverRipple;
}

.platform-twitch-btn-selector {
	margin-top: 5px;
	width: 107px;
	height: 63px;
	background: rgba(250, 54, 51, 0.15);
	border-radius: 6px;
	color: #ffffff;
	cursor: pointer;
	// transition: all 0.3s ease;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	border: 2px solid #FA3633;

}

.platform-twitch-btn-selector.active {
	background: rgba(250, 54, 51, 0.15);
	border: 2px solid #FA3633;
}

.platform-twitch-btn-icon-selector {
	width: 22px;
	height: 23px;
	fill: white;
}

.platform-twitch-btn-selector-text {
	margin-top: 3px;
	font-size: 10px;
}