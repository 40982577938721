@import "../../../utils/variables";

.Main__Background {
  background-color: #2d2d2d;
  border-radius: 3px;
  padding: 20px;

  .platform-tabs {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }

  .platform-tab {
    padding: 10px 20px;
    border-radius: 3px;
    cursor: pointer;
    // transition: all 0.3s;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .platform-tab.active {
    background: #5c16c5;
  }
  
  .platform-tab:not(.active) {
    background: #2d2d2d;
    border: 1px solid #3f3f3f;
  }
  
  .platform-tab svg {
    width: 20px;
    height: 20px;
  }

  .followers-requirement {
    background: #2d2d2d;
    border: 1px solid #3f3f3f;
    border-radius: 6px;
    padding: 16px;
    margin-bottom: 20px;
  }
  
  .followers-requirement .input-group {
    max-width: 300px;
  }

  .requirement-label {
    font-weight: bold;
    margin-bottom: 12px;
    color: #5c16c5;
  }

  .input-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .input-group label {
    font-size: 14px;
    color: #a0a0a0;
  }

  .input-group input {
    width: 100%;
    box-sizing: border-box;
    padding: 8px 12px;
    text-align: left;
    background: #1f1f1f;
    // border: 1px solid #3f3f3f;
    border-radius: 4px;
    padding: 8px 12px;
    color: #ffffff;
    font-size: 14px;
    border: none;
  }

  .mode-switch {
    margin-bottom: 20px;
    display: flex;
    gap: 12px;
  }
  
  .mode-btn {
    padding: 10px 20px;
    border-radius: 6px;
    cursor: pointer;
    background: #2d2d2d;
    border: 1px solid #3f3f3f;
    color: #ffffff;
    // transition: all 0.3s;
  }
  
  .mode-btn.active {
    background: #5c16c5;
    border-color: #5c16c5;
  }
}
