.gallery-modal-wrapper {
	// 确保这个容器不会影响网格布局
	height: 100%;
	width: 100%;
	display: contents; // 使其内容直接参与父级的布局流
}

.galleryItem {
	min-height: 30%;
	width: calc(50% - 16px);
	margin: 16px 0;
	position: relative;
	cursor: pointer;
	object-fit: cover;
	max-width: 500px;

	.galleryImage {
		// width: 100%;
		// height: 100%;
		max-height: 280px;
	}

	img {
		object-fit: cover;
		width: 100%;
	}

	span.cornerBtn {
		position: absolute;
		bottom: 0;
		right: 0;
		height: 0;
		width: 0;
		border-bottom: 48px solid rgba(0, 0, 0, 0.75);
		border-left: 48px solid transparent;
		opacity: 0;
		transition: opacity 0.3s ease;
	}

	&:hover {
		box-shadow: 0 0 24px 0 rgba(255, 255, 255, 0.25);
		transition: 0.3s ease;

		span.cornerBtn {
			display: block;
			opacity: 1;
		}
	}
}

.button {
	background: #211C27;
	border: 2px solid #595261;
	width: 300px;
	height: 40px;
	border-radius: 20px;
	color: white;
	text-transform: uppercase;
}

/* 当在 GameNew 中调用时，父容器加上 .game-new-gallery 类，
   覆盖 .galleryItem 宽度设置为每行三个 */
.GameGallery.game-new-gallery {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	// width: 100%;
	gap: 15px 11px;

	.galleryItem {
		width: 100% !important;
		/* 如果原先有 margin，则清零 */
		margin: 0 !important;
		border-radius: 10px;
		// max-width: 500px;
		// margin: 8px 0;
		position: relative;
		cursor: pointer;
		object-fit: cover;
		min-height: 30%;

		.galleryImage {
			// width: 100%;
			// height: 100%;
			max-height: 280px;
			border-radius: 10px;
		}

		img {
			object-fit: cover;
			width: 100%;
			border-radius: 10px;
		}

		span.cornerBtn {
			position: absolute;
			bottom: 0;
			right: 0;
			height: 0;
			width: 0;
			border-bottom: 48px solid rgba(0, 0, 0, 0.75);
			border-left: 48px solid transparent;
			opacity: 0;
			transition: opacity 0.3s ease;
		}

		&:hover {
			box-shadow: 0 0 24px 0 rgba(255, 255, 255, 0.25);
			transition: 0.3s ease;

			span.cornerBtn {
				display: block;
				opacity: 1;
			}
		}
	}

}