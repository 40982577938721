@import '../../utils/_variables';

.noiz-footer {
  display: flex; 
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-end;
  background: #211C27;
  position: relative;

  &.on-quest-page {
    background-image: linear-gradient(to bottom, rgba(24, 25, 29, 0), rgba(0, 0, 0, 1));
  }
  .inner-container {
    width: 100%;

    &.constrain {
      margin: 0 auto;
      max-width: $macroScreen;
    }

    .top-container {
      display: flex;
      border-top: solid 1px rgba(255, 255, 255, 0.1);
      justify-content: space-between;
      align-items: baseline;
      width: 100%;
      padding-block: 75px;
      padding-left: 266px;
      gap: 300px;
      .left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .logo {
          align-self: center;
          background-repeat: no-repeat;
          background-size: contain;
          height: 46px;
          width: 122px;
        }
        .social {
          display: flex;
          margin: 40px 0 0 0;
          .icon {
            height: 24px;
            width: 24px;
            margin-right: 32px;
          }
        }
      }
      .links-container {
        display: flex;
        gap: 32px;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        font-family: Arial;
        font-size: 18px;
        @media #{$mobileOnly} {
          margin: 23px 0 0 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        a {
          display: block;
          text-decoration: none;
          color: #CDCCD0;
        }
      }

      
    }

    .bottom-container {
      border-top: solid 1px rgba(255, 255, 255, 0.1);
      color: #625E67;
      display: flex;
      flex-direction: column;
      font-family: ArialMT;
      font-size: 14px;
      line-height: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      padding: 14px 272px 39px;
      -webkit-font-smoothing: antialiased;
      width: 100%;
      z-index: 5;
      
      .copyright {
        line-height: 1.33;
      }
    }
  }
}