@import  '../../../../utils/_variables';
.CCQuestTimedActivity{
  .tooltip {
    position: relative;
    display: inline-block;
    color: white;
  }
  .tooltiptext {
    font-size: 10px;
  }
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 200px;
    cursor: default;
    background-color: $colorBackground;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    bottom: 30px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 100;
    &:before {
      content: "";
      z-index:100;
      position: absolute;
      width: 11px;
      height: 11px;
      bottom: -4.5px;
      left: 0;
      right: 70px;
      margin-left: auto;
      margin-right: auto;
      background-color: $colorBackground;
      border-top: solid 1px $colorBackground;
      border-left: solid 1px $colorBackground;
      transform: rotate(45deg);
    }
  }
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
  h2{
    margin: 0 0 10px 0;
  }
    .buttonStyle{
      height: 50px;
      border-radius: 30px;
      background: linear-gradient(to right, #45cacd, #47b8d4);
      color: #FFF;
      border: none;
      outline: none;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 22px;
      letter-spacing: 1.2px;
      margin: 20px 0 20px 0;
      cursor: pointer;
    }
    .CCQuests__back--btn{
      @extend .buttonStyle;
      width: 300px;
    }
    .CCQuests__save--btn{
      @extend .buttonStyle;
      width: 100%;
    }
  
    .ToggleTable {
      .ToggleTable__header {
        background: #2b2e38;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 30px;
        .ToggleTable__header-title {
          padding: 0;
          margin: 0;
          font-size: 22px;
          letter-spacing: 2.5px;
        }
        .dot-status{
          width: 10px;
          height: 10px;
          border-radius: 100%;
          background-color: #ef3937;
          box-shadow: 0 0 9px 0 #ff987f;
          margin-left: 10px;
        }
        a{
          line-height: 18px;
          margin-left: auto;
          color: $colorLight;
        }
      }
      .ToggleTable__body-wrapper {
        background: #2b2e38;
        padding: 20px;
        .ToggleTable__body {
          .ToggleTable__body-row {
            display: flex;
            margin: 30px 0;
            .ToggleTable__body-rowItem {
              flex: 1;
              text-align: center;
              .ToggleTable__body-itemTop{

              }
              .ToggleTable__body-itemBot{
                font-size: 35px;
                &.green {
                  color: #49e1ca;
                }
                &.red {
                  color: red;
                }
              }
            }
          }
        }
      }
      .ToggleTable__table-wrapper {
        border-top: 1px solid #454545;
        background: #2b2e38;
        padding: 30px;
        .no-results {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 44px;
          margin-top: 30px;
        }
        .row{
          border-bottom: 1px solid #1e1f25;
          padding: 0 10px;
          &.toggable{
            flex-wrap: wrap;
            .row-basic{
              width: 100%;
              display: flex;
              flex-flow: row nowrap;
            }
            .row-red{
                color: #FF8E83;
            }
            .row-green{
                color: #4BE0CA;
            }
            .row-details{
              width: 100%;
              display: none;
            }
            &.open{
              .row-details{
                display: flex;
                .StreamActivityTable{
                  width: 100%;
                  border: 1px solid rgba(0,0,0,0.2);
                  margin: 20px 10px;
                  .table-cell{
                    &.headline{
                      font-size: 14px;
                      color: #9b9b9b;
                      font-weight: bold;
                      letter-spacing: 1.2px;
                    }
                    &:first-child{
                      justify-content: flex-start;
                      max-width: initial;
                    }
                  }
                }
              }
            }
          }
          &:hover {
            background: rgba(255,255,255,0.025);
            &:first-child {
              background: rgba(255,255,255,0);
            }
          }
          &:last-child{
            border-bottom: none;
          }
          .table-cell{
            justify-content: center;
            a {
              color: white;
            }
            &.headline{
              color: #9b9b9b;
              font-weight: 400;
              letter-spacing: 1.2px;
            }
            &:first-child{
              justify-content: flex-start;
              max-width: 50px;
            }
            &:nth-child(2){
              justify-content: flex-start;
              font-weight: bold;
            }
            &:nth-child(3){
              justify-content: flex-start;
            }
            &:nth-child(4){
              justify-content: flex-start;
            }
            &:last-child{
              justify-content: flex-end;
              align-items: center;
            }
            .small-btn {
              background: rgba(255,255,255,0.05);
              border: none;
              padding: 5px 8px;
              border-radius: 3px;
              color: #FFF;
              letter-spacing: 1px;
              font-weight: 600;
              text-transform: uppercase;
              cursor: pointer;
              font-size: 11px;
              text-decoration: none;
              margin-right: 5px;
              &.open{
                background: rgba(255,255,255,.15);
              }
              &:last-child{
                margin-right: 0;
              }
              &:hover{
                background: rgba(255,255,255,.2);
              }
  
            }
            .small-btn.waiting {
              background: rgba(255,255,255,0);
              border: none;
              padding: 5px 8px;
              border-radius: 3px;
              color: #e91e63;
              letter-spacing: 1px;
              font-weight: 600;
              text-transform: uppercase;
              font-size: 11px;
              text-decoration: none;
              cursor: pointer;
            }
          }
        }
      }
    }
    .NoQuestSale{
        padding-top: 10px;
    }
  .CCQuestTimedActivity__tabs {
    display: flex; 
    .CCQuestTimedActivity__tab { 
      background: rgba(43,46,56,0.5); 
      padding: 10px 30px; 
      font-size: 16px; 
      border-top-left-radius: 7px; 
      border-top-right-radius: 7px; 
      cursor: pointer; 
      &.active { 
        background: #2b2e38; 
      } 
      span { 
        background: #9b9b9b; 
        padding: 2px 3px; 
        border-radius: 5px; 
        font-size: 12px; 
        font-weight: bold; 
      } 
    } 
  } 
}
  