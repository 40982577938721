@import  '../../../../utils/_variables';
.CCCDKeys{
  .card {
    background-color: #242424;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
    margin-bottom: 20px;
    border: 1px solid #323232;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    border-radius: 0.25rem;
  }
  .mb-4 {
    margin-bottom: 1.5rem!important;
  }
  .g-3{
    --bs-gutter-y: 1rem;
    --bs-gutter-x: 1rem;
  }
  .card-header {
    background-color: #141414;
    color: #e0e0e0;
    border-bottom: 1px solid #323232;
    padding: 0.75rem 1rem;

    &:first-child {
      border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
    }
    h5 {
      font-size: 1.25rem;
      margin-top: 0;
      margin-bottom: 0.5rem;
      font-weight: 500;
      line-height: 1.2;
    }
    h6 {
      margin-top: 0;
      margin-bottom: 0.5rem;
      font-weight: 500;
      line-height: 1.2;
    }
    .mb-0 {
      margin-bottom: 0!important;
    }
  }
  .card-body {
    background-color: #242424;
    padding: 1rem;
    flex: 1 1 auto;
    .row {
      --bs-gutter-x: 1.5rem;
      --bs-gutter-y: 0;
      display: flex;
      flex-wrap: wrap;
      margin-top: calc(-.5 * 1rem);
      margin-right: calc(-.5 * 1rem);
      margin-left: calc(-.5 * 1rem);
      .col-md-3 {
        flex: 0 0 auto;
        width: 25%;
        .stats-card {
          background-color: #141414;
          text-align: center;
          box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 4px;
          height: 100%;
          border: 1px solid #323232;
          border-radius: 8px;
          padding: 1rem;
          h6 {
            color: #a0a0a0;
            font-size: 0.9rem;
            margin-bottom: 0.5rem;
            margin-top: 0;
            margin-bottom: 0.5rem;
            font-weight: 500;
            line-height: 1.2;
          }
          span {
            color: #0d6efd;
            font-size: 1.5rem;
            font-weight: 600;
          }
        }
      }
    }
    .row>* {
      flex-shrink: 0;
      width: 100%;
      max-width: 100%;
      padding-right: calc(1rem * .5);
      padding-left: calc(1rem * .5);
      margin-top: 1rem;
    }
    .table-responsive {
      margin-bottom: 1rem;
      max-height: 500px;
      overflow-y: auto;
      overflow-x: auto;
      border-width: 0px;
      border-style: initial;
      border-color: initial;
      border-image: initial;
      .table-dark {
        --bs-table-bg: #212529;
        --bs-table-striped-bg: #2c3034;
        --bs-table-striped-color: #fff;
        --bs-table-active-bg: #373b3e;
        --bs-table-active-color: #fff;
        --bs-table-hover-bg: #323539;
        --bs-table-hover-color: #fff;
        color: #fff;
        border-color: #373b3e;
      }
      .table {
        color: #e0e0e0;
        margin-bottom: 0px;
        width: 100%;
        vertical-align: top;
        --bs-table-accent-bg: transparent;
        border-collapse: collapse;
      }
      .table>:not(caption)>*>* {
        padding: .5rem .5rem;
        background-color: #212529;
        border-bottom-width: 1px;
        box-shadow: inset 0 0 0 9999px transparent;
      }
      .table thead th {
        font-size: 0.85rem;
        padding: 0.4rem 0.5rem;
      }
      .table th {
        background-color: #141414;
        font-weight: 500;
        white-space: nowrap;
        border-color: #323232;
      }
      .table>thead {
        vertical-align: bottom;
      }
      .table td {
        vertical-align: middle;
      }
      .table td, .table th {
        padding: 0.3rem 0.5rem;
        font-size: 0.85rem;
        line-height: 1.2;
      }
      .table>:not(:first-child) {
        border-top: 2px solid currentColor;
      }

      tbody, td, tfoot, th, thead, tr {
        border-color: inherit;
        border-style: solid;
        border-width: 0;
      }
      .text-end {
        text-align: right!important;
        display: flex;
        justify-content: flex-end;
        p{
          font-size:0.875rem;
          margin:0px 0px;
          font-style:italic;
        }
        button{
            height:auto;
          align-items: center;
        }
      }
      .edit-btn {
        padding: 2px 6px;
        margin-left: 8px;
        font-size: 0.8em;
        background: transparent;
        border: 1px solid #323232;
        color: #a0a0a0;
        border-radius: 4px;
        cursor: pointer;
      }
      button, input, optgroup, select, textarea {
        margin: 0;
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
      }
      input{
        width:100%;
        padding:8px;
        background:#2d2d2d;
        border:1px solid #3d3d3d;
        border-radius: 4px;
        color:#ffffff;
        max-width: 200px;
      }
      i {
        font-style:normal;
      }
      .platform-status {
        padding: 0.25rem 0.5rem;
        border-radius: 4px;
        font-size: 0.875rem;
        display: inline-block;
      }
      .platform-status.active {
        background-color: rgba(25, 135, 84, 0.2);
        color: #198754;
      }
      .platform-status.inactive {
        background-color: rgba(73, 40, 43, 1);
        color: #ff0000;
      }
      .input-group-sm {
        width: 200px;
      }
      .input-group {
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: stretch;
        width: 100%;
        .fake-input {
          width: 130px;
          height: 30px;
          border: solid 1.5px #323232;
          padding: 10px;
          display: inline-flex;
          justify-content: flex-start;
          align-items: center;
          cursor: pointer;
          background-color: #141414;
          color: #323232;
          overflow: hidden;
        }
        button.FilePickButton {
          height: 50px;
          border: solid 1.5px #ffffff;
          padding: 0px 25px;
          display: inline-flex;
          justify-content: flex-start;
          align-items: center;
          text-transform: uppercase;
          background: rgba(0, 0, 0, 0);
          color: #FFF;
          border-left: none;
          font-size: 14px;
          font-weight: 600;
          cursor: pointer;
        }
      }
      .input-group-sm {
        width: 200px;
      }
      .input-group-sm>.btn, .input-group-sm>.form-control, .input-group-sm>.form-select, .input-group-sm>.input-group-text {
        padding: .25rem .5rem;
        font-size: .875rem;
        border-radius: .2rem;
      }
      .btn {
        position: relative;
        z-index: 2;
        text-transform: none;
        background-image: none;
        cursor: pointer;
        border-radius: .2rem;
        padding: .375rem .75rem;
        width:auto;
        height:auto;
        display: inline-block;
      }
      .btn-sm {
        padding: 0.25rem 0.5rem;
        font-size: 0.875rem;
        white-space: nowrap;
        border-radius: .2rem;
      }
      .btn-warning {
        color: #000;
        background-color: #ffc107;
        border-color: #ffc107;
      }
      .btn-normal {
        color: #000;
        background-color: #198754;
        border-color: #ffc107;
      }
      .form-control, .form-select {
        background-color: #141414;
        border-color: #323232;
        color: #e0e0e0;
      }
      .platform-import-file {
        max-width: 120px;
      }
      .form-control[type=file] {
        overflow: hidden;
      }
      .btn-primary {
        background-color:#0d6efd;
        border-color:#0d6efd;
      }
      .btn-primary.disabled {
        background-color:#0d6efd;
        border-color:#ffffff;
        border-width: 100px;
      }
      .mt-3 {
        margin-top: 1rem !important;
      }
      .order-status.completed {
        background-color: rgba(25, 135, 84, 0.2);
        color: #198754;
      }
      .order-status.pending {
        background-color: rgba(255, 193, 7, 0.2);
        color: #ffc107;
      }
      .order-status.cancelled {
        background-color: rgba(220, 53, 69, 0.2);
        color: #dc3545;
      }
      .order-status {
        padding: 0.15rem 0.35rem;
        font-size: 0.8rem;
        border-radius: 4px;
        display: inline-block;
      }
      .masked-cdk {
        cursor: pointer;
        text-decoration: underline;
        color: #0d6efd;
        font-size: 0.85rem;
      }
      .sales-record{
        text-align: center;
        .purchase-type{
          padding: 0.15rem 0.35rem;
          font-size: 0.8rem;
          border-radius: 4px;
          display: inline-block;

        }
        .purchase{
          background-color: rgba(25, 135, 84, 0.2);
          color: #198754;
        }
        .gift{
          background-color: rgba(13, 110, 253, 0.2);
          color: #0d6efd;
        }
      }
    }
    .mb-3 {
      margin-bottom: 1rem !important;
    }
    .col-md-6 {
      flex: 0 0 auto;
      width: 50%;
      .d-flex {
        display: flex !important;
      }
      .gap-2 {
        gap: .5rem !important;
      }
      .form-select {
        display: block;
        width: 100%;
        padding: .375rem 2.25rem .375rem .75rem;
        -moz-padding-start: calc(0.75rem - 3px);
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        background-color: #fff;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right .75rem center;
        background-size: 16px 12px;
        border: 1px solid #ced4da;
        border-radius: .25rem;
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-color: #141414;
        border-color: #323232;
        color: #e0e0e0;
        option {
          font-weight: normal;
          display: block;
          min-height: 1.2em;
          padding: 0px 2px 1px;
          white-space: nowrap;
        }
      }
      .form-control{
        background-color: #141414;
        border-color: #323232;
        color: #e0e0e0;
        display: block;
        width: 100%;
        padding: .375rem .75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
      }
      .search-wrapper {
        position: relative;
        width: 100%;

      }
    }
  }
  .ToggleTable {

    .ToggleTable__header {
      background: #2b2e38;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 30px;
      border-bottom: 1px solid #454545;
      // border-top: 1px solid #1d1e23;
      border-top: 1px solid #454545;
      .ToggleTable__header-title {
        padding: 0;
        margin: 0;
        font-size: 22px;
        letter-spacing: 2.5px;
      }
      .dot-status{
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background-color: #ef3937;
        box-shadow: 0 0 9px 0 #ff987f;
        margin-left: 10px;
      }
      i{
        font-size: 30px;
        line-height: 18px;
        margin-left: auto;
      }
    }
    .ToggleTable__table-wrapper {
      background: #202227;
      padding: 30px;
      .no-results {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 44px;
        margin-top: 30px;
      }
      .row{
        border-bottom: 1px solid #1e1f25;
        padding: 0 10px;
        &:hover {
          background: rgba(255,255,255,0.025);
          &:first-child {
            background: rgba(255,255,255,0);
          }
        }
        &:last-child{
          border-bottom: none;
        }
        .table-cell{
          justify-content: center;
          &.headline{
            color: #9b9b9b;
            font-weight: 400;
            letter-spacing: 1.2px;
          }
          &:first-child{
            justify-content: flex-start;
          }
          &:last-child{
            justify-content: flex-end;
          }
          .remove-key {
            i {
              margin-right: 0;
            }
            display: flex;
            align-items: center;
            background: rgba(255,255,255,0.05);
            border: none;
            padding: 5px 8px;
            border-radius: 3px;
            color: #FFF;
            line-height: 1;
            letter-spacing: 1px;
            font-weight: 600;
            text-transform: uppercase;
            cursor: pointer;
            font-size: 11px;
            text-decoration: none;
            margin-right: 5px;
            &:last-child{
              margin-right: 0;
            }
            &:hover{
              background: rgba(255,255,255,.2);
            }
          }
          .removing-key {
            background: rgba(255,255,255,0);
            border: none;
            padding: 5px 8px;
            border-radius: 3px;
            color: #e91e63;
            letter-spacing: 1px;
            font-weight: 600;
            text-transform: uppercase;
            font-size: 11px;
            text-decoration: none;
            cursor: pointer;
          }
        }
      }
    }
  }
  .ModalMaster{
    &.active{
      visibility: visible;
      opacity: 1;
      .Modal__wrapper{
        transform: scale(1);
      }
    }
    .Modal{
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 99;
      display: flex;
      align-items: center;
      justify-content: center;
      visibility: hidden;
      opacity: 0;
      overflow-x: hidden;
      background: rgba(0,0,0,.5);
      align-items: flex-start;
      @media screen and (min-width: 640px){
        align-items: center;
      }
      &.active{
        visibility: visible;
        opacity: 1;
        .Modal__wrapper{
          // transform: scale(1);
          top: -50%;
          transform: scale(1) translateY(calc(50% + 10vh));
          margin: 10vh 0;
          @media #{$mobileOnly}{
            transform: none !important;
            margin:5vh 0 !important;
            top:0 !important;
          }
        }
      }
      .Modal__layer{
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        cursor: pointer;
      }
      .Modal__wrapper{
        width: 90%;
        max-width: 825px;
        background: #202227;
        padding: 0;
        position: relative;
        text-align: center;
        align-items: center;
        justify-content: center;
        transform: scale(0.8);
        // margin: 5vw 0 5vh 0;
        @media screen and (min-width: 640px){
          margin: 0;
        }
      }
      .Copy{
        padding: 30px 0;
        color: $colorLight;
        select{
          min-width: 200px;
          max-width: 300px;
          background-color: transparent;
          border: none;
          border-bottom: 2px solid #83858C;
          border-radius: 0px;
          color: white;
          display: inline-block;
          padding-left: 0;
          text-align: left;
          margin: 0;
          width: 100%;
          option{
            background-color: #202227;
          }
          &:focus{
            border-bottom: 2px solid #3A74E0;
            box-shadow: none;
          }
        }
        button{
          color: $colorLight;
          background: $colorBackground2;
          padding: 4px 7px;
          margin-right: 5px;
          border: 1px solid $colorBackground2;
          border-radius: 5px;
          cursor: pointer;
          &.active{
            border: 1px solid $colorLight;
          }
          &:focus{
            outline:0;
          }
        }
        .Modal__copy__header{}
        .Modal__copy__body{
          margin-top: 20px;
        }
        .Modal__copy__footer{
          margin-top: 20px;
        }
      }
    }
  }
}
