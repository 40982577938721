@import "../BrandService.scss";

// *** Mixins ***
// centerFlex
// vertFlex
// spaceFlex
// brandTwoGrid
// brandMedText
// brandPurpleHeroText
// brandSubtitleWhiteText

$titleMargin: 120px;

.scroll-for-more-brand {
  @include centerFlex;
  color: rgba(255, 255, 255, 0.75);
  font-family: Roboto;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: bold;
  height: 100%;
  letter-spacing: 1px;
  line-height: normal;
  position: absolute;
  text-align: center;
  margin-bottom: 200px;

  .scroll-notice-flex {
    align-content: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    position: absolute;
    font-size: 14px;
    @include px-to-vw-mq((font-size: 14px));


    img {
      width: 16px;
      height: 26px;
      margin-bottom: 6px;
      @include px-to-vw-mq((width: 16px, height: 26px, margin-bottom: 6px));
    }

    @media #{$tabletOnly} {
      bottom: 2vh;
    }

    @media #{$desktopAndLarger} {
      bottom: 2vh;
    }

    @media #{$mobileOnly} {
      bottom: 2vh;

    }
  }
}

.nice-slide-c-container {
  justify-content: center;
  padding-top: 4%;
  z-index: 2;
  @include brandTwoGrid;

  .brand-computer {
    @media #{$mobileOnly} {
      margin-bottom: 20px;
      max-width: 80vw;
    }
  }

  .stacked-nice-text {
    @include brandMedText;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    @media #{$tabletOnly} {
      max-width: 30vw;
    }

    @media #{$desktopAndLarger} {
      margin-right: 60px;
      max-width: 450px;
    }

    @media #{$mobileOnly} {
      justify-content: space-between;
      max-width: 80vw;
    }
  }

  :nth-child(2) {
    margin-top: 20px;
  }
}

.brand-vert-stack {
  align-items: stretch;
  justify-content: flex-start;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;


  div {
    justify-content: flex-start;
    margin-bottom: 12px;
  }

  :nth-child(3) {
    margin-top: 10px;
  }
}

.brand-vert-stack-center {
  align-items: center;
  text-align: center;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  div {
    margin-bottom: 20px;
    text-align: center;
  }
}

.brand-angle-svg {
  @include centerFlex;
  align-items: flex-start;
  justify-content: flex-end;
  padding-right: 2%;
  padding-top: 1.5%;
  z-index: 0;
}

.global-platform-background {
  @include centerFlex;
  background-position: left;
  /* Center the image */
  background-size: cover;
  justify-content: flex-start;

  @media #{$mobileOnly} {
    background-position: left;
  }

  @media #{$tabletOnly} {}
}

.brand-d-noiz-title {
  @include medLargeHeadline(#211C27);
  @include brandTitleBorder(#FA3633)
}

.brand-title {
  @media #{$mobileOnly} {
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
  }
}

.brand-d-noiz-vcontainer {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.brand-d-noiz-horiz-container {
  height: 50%;
  @include spaceFlex;
  justify-content: center;
  gap: 100px;
  width: 100%;

  @media #{$mobileOnly} {
    align-items: flex-start;
    display: grid;
    grid: 150px / auto auto;
    grid-auto-rows: auto;
    grid-gap: 10%;
    grid-template-rows: 1fr 1fr 1fr;
    justify-content: center;
    width: 100%;
    margin-top: 100px;
  }


}

.brand-d-writing-pod {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.brand-d-subtitle {
  @include brandSubtitleWhiteText(#322D38, 24px);
  text-transform: uppercase;
  margin-top: 12px;
}

.brand-d-purple-counter {
  @include brandRedHeroText;
}

.slide-e-container,
.slide-f-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
  width: 100%;
  z-index: 5;
  gap: 100px;
  @media (min-width: 768px) and (max-width: 1919px) {
    gap:5.2vw;
  }

  >div {
    width: 520px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include px-to-vw-mq((width: 520px));
  }

  .brand-title {
    @include medLargeHeadline;
    @include brandTitleBorder(#FA3633, 0, 50%);
    text-align: left;
  }

  .grey-brand-body-text {
    @include brandSubtitleWhiteText(#E7E4E9);
    @include brandTitleMargin(24px, 48px);
    text-align: left;
  }

  .brand-image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .brand-micro-image {
    @include imgSize(460px, 460px)
  }

  .brand-micro-description {
    position: absolute;
    text-align: center;
    bottom: 20px;
  }

  .brand-services-trophy-text {
    @include brandSubtitleWhiteText(#ACABAC, 20px);

    @media #{$mobileOnly} {
      text-align: left;
    }

    @media #{$tabletOnly} {
      text-align: left;
    }
  }
}

// SLIDE H

.slide-h-background {
  background-image: linear-gradient(to right, #B90A08, #FF7877 100%);
  // clip-path: polygon(0% 0%, 100% 10%, 100% 90%, 0% 100%);
  width: 100%;

  // @media #{$mobileOnly} {
  //   clip-path: polygon(0% 0%, 100% 5%, 100% 95%, 0% 100%);
  // }

  // @media #{$tabletOnly} {
  //   clip-path: polygon(0% 0%, 100% 5%, 100% 95%, 0% 100%);
  // }
}

.slide-h-background-2 {
  background-image: linear-gradient(to bottom, #3a74e0, #8052f5 20%);
  width: 100%;

  @media #{$mobileOnly} {
    clip-path: polygon(0% 0%, 100% 5%, 100% 95%, 0% 100%);
  }

  @media #{$tabletOnly} {
    clip-path: polygon(0% 0%, 100% 5%, 100% 95%, 0% 100%);
  }
}

.slide-h-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  z-index: 5;

  font-weight: bold;
  text-transform: uppercase;

  .brand-title {
    @include medLargeHeadline;
    @include brandTitleMargin;
    @include brandTitleBorder;
  }

  .brand-logo-grid {
    align-items: flex-start;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
    justify-content: center;
    margin: 0 auto;
    @include px-to-vw-mq((gap: 20px));

    img {
      width: 260px;
      height: 100%;
      object-fit: contain;
      @include px-to-vw-mq((width: 260px))
    }
  }
}


.slide-i-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 5;

  .cascade-fade-g {
    opacity: 0;
  }

  .brand-i-title {
    @include medLargeHeadline;
    @include brandTitleMargin;
    @include brandTitleBorder(#FA3633);

    @media #{$mobileOnly} {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-top: 20px;
      text-align: center;
    }

    @media #{$tabletOnly} {
      align-items: center;
      display: flex;
      text-align: center;
      text-transform: uppercase;
    }
  }

  .brand-i-grid {
    display: grid;
    justify-content: center;
    max-width: 1440px;
    grid-template-columns: repeat(3, 1fr);
    gap: 32px;
    margin: 0 auto;

    // 移动端 - 单列
    @media #{$mobileOnly} {
      grid-template-columns: 329px;
      gap: 24px;
    }
  }

  .brand-text-stack {
    align-items: flex-start;
    background-color: #EF3937;
    border-radius: 10px;
    height: 210px;
    width: 329px;
    padding: 0 40px;
    @include px-to-vw-mq((padding-inline:40px, height: 210px,
        width: 329px, border-radius: 10px));

    &:nth-child(3) {
      .title {
        width: 260px;
        @include px-to-vw-mq(( width: 260px));
      }
    }

    .row-flex {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      max-width: 700px;
      height: 100%;
    }

    .title {
      color: #ffffff;
      font-family: Roboto;
      @include brandSubtitleWhiteText(#FFFFFF, 24px)
    }

    .body {
      @include brandSubtitleWhiteText(#FFFFFF, 14px)
    }

    .image-round {
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 0px;
      img {
        width: 50px;
        @include px-to-vw-mq((width: 50px));
      }
    }
  }
}

// slide I

.brand-k-row-flex {
  align-items: center;
  display: flex;
  flex-direction: row;
  font-family: Roboto;
  justify-content: space-around;
  gap: 32px;

  .row-flex {
    @media #{$mobileOnly} {
      flex-direction: column;
    }
  }

  @media #{$mobileOnly} {
    flex-direction: column;
    min-height: 90%;
  }

  @media #{$tabletOnly} {
    flex-direction: column;
    min-height: 80%;
  }

  .featured-content-view {
    align-content: flex-start;
    justify-content: flex-start;
    min-width: 95%;
    width: 100%;

    .image {
      cursor: pointer;
      width: 400px;
      line-height: normal;
      @include px-to-vw-mq((width: 400px));
    }

    .vert-text {
      align-items: flex-start;
      background-color: #211C27;
      border-top: 2px solid #EF3937;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      min-height: 80px;
      min-width: 100%;
      padding: 10px 20px;
      text-align: left;
      border-radius: 0 0 5px 5px;

      >div {
        width: 100%;
      }
    }

    .title {
      @include brandSubtitleWhiteText;
      text-align: left;
      font-weight: bolder;
    }

    .name {
      @include brandSubtitleWhiteText(#CDCCD0, 16px);
      text-align: left;
    }

    .stats {
      color: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .stats-right {
        display: flex;
        @include brandSubtitleWhiteText(#CDCCD0, 14px);
      }

      div:nth-of-type(1) {
        margin-right: 30px;
      }
    }
  }
}

.brand-lightbox {
  align-items: center;
  border: none;
  display: flex;
  height: 100%;
  justify-content: flex-start;
  outline-color: transparent;
  outline-width: 0px;
  text-shadow: 0 0 0 #000;
  width: 100%;

  .brand-iframe {
    border: none;
    height: 540px;
    width: 960px;

    @media #{$mobileOnly} {
      height: 202px;
      width: 360px;
    }

    @media #{$tabletOnly} {
      height: 319px;
      width: 560px;
    }
  }

  .brand-iframe-container {
    align-items: center;
    border: none;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;

    .exit {
      align-items: center;
      border-radius: 20px;
      bottom: 570px;
      color: white;
      cursor: pointer;
      display: flex;
      font-size: 25px;
      font-weight: 500;
      height: 40px;
      justify-content: center;
      left: 945px;
      position: relative;
      right: 0px;
      width: 40px;

      @media #{$mobileOnly} {
        bottom: 290px;
        left: 160px;
      }

      @media #{$tabletOnly} {
        bottom: 345px;
        left: 545px;
      }
    }
  }
}

:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

.slide-k-container {
  @include spaceFlex;
  align-items: center;
  background: #D7D7D7;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 135px;
  width: 100%;
  z-index: 5;

  @media #{$mobileOnly} {
    // clip-path: polygon(0% 0%, 100% 5%, 100% 95%, 0% 100%);
    // clip-path: polygon(0% 5%, 100% 0%, 100% 100%, 0% 95%);
    justify-content: flex-start;
    padding-top: 130px;
  }

  @media #{$tabletOnly} {
    // clip-path: polygon(0% 0%, 100% 5%, 100% 95%, 0% 100%);
    // clip-path: polygon(0% 5%, 100% 0%, 100% 100%, 0% 95%);
    justify-content: flex-start;
    padding-top: 130px;
  }

  @media #{$desktopAndLarger} {
    // clip-path: polygon(0% 0%, 100% 10%, 100% 90%, 0% 100%);
    // clip-path: polygon(0% 10%, 100% 0%, 100% 100%, 0% 90%);
  }

  .brand-logo-grid {
    align-items: flex-start;
    display: grid;
    grid: 150px / auto auto auto auto;
    grid-auto-rows: auto;
    grid-gap: 10%;
    grid-template-rows: 1fr 1fr 1fr;
    justify-content: center;
    width: 100%;

    @media #{$mobileOnly} {
      grid: 100px / auto auto;

      >div {
        max-width: 100px;
      }
    }

    @media #{$tabletOnly} {
      grid: 150px / auto auto auto;

      >div {
        max-width: 150px;
      }
    }

    @media #{$desktopAndLarger} {
      >div {
        max-width: 100px;
      }
    }
  }
}

.cascade-fade-c {
  opacity: 0;
}

.cascade-fade-d {
  opacity: 0;
}

.cascade-fade-e {
  opacity: 0;
}

.cascade-fade-f {
  opacity: 0;
}

.cascade-fade-g {
  opacity: 0;
}

.cascade-fade-i {
  opacity: 0;
}

.brand-k-title {
  @include medLargeHeadline(#211C27);
  @include brandTitleMargin;
  @include brandTitleBorder(#FA3633);

  @media #{$mobileOnly} {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    text-align: center;
  }

  @media #{$tabletOnly} {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    text-align: center;
  }

  @media #{$desktopAndLarger} {
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
  }
}

// HEAVYLIFT

.big-line-down {
  left: 49.4%;
  position: absolute;
  top: 250px;
  z-index: 4;

  @media #{$mobileOnly} {
    left: 4.43%;
    top: 295px;
  }

  @media #{$tabletOnly} {
    left: 4.43%;
    top: 290px;
  }
}

.r-line {
  margin-top: 100px;
  margin-bottom: 200px;
}

.slide-j-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  z-index: 5;
  padding-top: 150px;
  padding-inline: 200px;
  position: relative;

  .brand-j-title {
    @include medLargeHeadline;
    @include brandTitleMargin;
    text-transform: uppercase;
    color: #F4F4F4;
    width: 480px;
    font-size: 36px;
    border-bottom: 2px solid transparent;
    border-image: linear-gradient(to right, transparent 33%, #322D38 33% 66%, transparent 66%);
    border-image-slice: 1;
  }


  .brand-j-fixed-stack {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;


    .brand-text-stack {
      width: 1509px;
      height: 460px;
      display: grid;
      grid-template-columns: 32% 32% 32%;
      align-items: center;
      background-image: url("../assetsV2/jbg.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      @media (min-width: 768px) and (max-width: 1919px) {
        @include px-to-vw((width: 1509px,
            height: 460px,
          ));
      }

      .text-flex {

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          width: 280px;
          margin-left: 125px; // 新增统一偏移

          @media (min-width: 768px) and (max-width: 1919px) {
            @include px-to-vw((width: 280px, margin-left: 125px,
              ));
          }
        }

        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6) {
          width: 280px;
          margin-left: 360px; // 新增统一偏移

          @media (min-width: 768px) and (max-width: 1919px) {
            @include px-to-vw((width: 280px, margin-left: 360px,
              ));
          }
        }

        .title {
          color: #ffffff;
          font-family: Roboto;
          text-transform: uppercase;
          font-family: Arial;
          font-weight: 400;
          font-size: 24px;
          color: #F4F4F4;

          @media (min-width: 768px) and (max-width: 1919px) {
            @include px-to-vw((font-size: 24px));
          }

        }

        .body {
          color: #F4F4F4;
          font-family: Roboto;
          font-size: 14px;
          font-stretch: normal;
          font-style: normal;
          font-weight: normal;
          letter-spacing: normal;

          @media (min-width: 768px) and (max-width: 1919px) {
            @include px-to-vw((font-size: 14px));
          }
        }
      }
    }

  }



}

.contact-us-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  z-index: 5;
  padding-top: 150px;

  .brand-title {
    @include medLargeHeadline;
    @include brandTitleBorder(#FA3633);
  }

  @media #{$mobileOnly} {
    align-items: center;

    >div {
      margin: 0;
      margin-bottom: 10px;
      max-width: 90%;
    }
  }


  .brand-description {
    @include brandSubtitleWhiteText(#CDCCD0, 18px);
    @include brandTitleMargin(80px,80px);
  }

  @media #{$desktopAndLarger} {
    >div {
      max-width: 50%;
    }
  }
}

.brand-form-control {
  border: 2px solid #7E7B82;
  border-radius: 8px;
  padding: 0 36px;

  &:hover {
    border: 2px solid#EF3937;

    h2 {
     
      border-bottom: 2px solid transparent;
      border-image: linear-gradient(to right, transparent 25%, #FA3633 25% 75%, transparent 75%);
      border-image-slice: 1;
    }
  }

  h2 {
    font-size: 24px;
    color: #CDCCD0;
    border-bottom: 2px solid transparent;
    border-image: linear-gradient(to right, transparent 25%, #7E7B82 25% 75%, transparent 75%);
    border-image-slice: 1;
    @include px-to-vw-mq((font-size: 24px));

  }

  .contact-form-label {
    color: white;
    font-weight: 500;
    letter-spacing: 1px;
  }

  align-items: center;
  display: flex;
  flex-direction: column;
  // height: 100%;
  justify-content: flex-start;

  @media #{$mobileOnly} {
    min-width: 80% !important;
  }

  .MuiSelect-root {
    border: none !important;
    border-radius: 0 !important;
  }

  .MuiSelect-icon {
    margin-right: 5px;
  }
}

.brand-form-control-brandLogin {

  .brand-form-control-hint {
    text-align: center;
    height: 55px;
    margin: 24px 0;
    font-size: 14px;

    a {
      font-weight: bold;
      text-transform: uppercase;
    }
  }
  input {
    color: white;
    background-color: transparent;
    display: inline-block;
    font-size: 18px;
    text-align: left;
    margin: 0;
    width: 100%;
    border: none; // 去掉边框
    outline: none; // 去掉点击时的轮廓
    // 由于 caret-width 不是标准属性，改用其他方式控制光标样式
    caret-color: #F4F4F4;

    &:focus {
    border: none; // 确保聚焦时也没有边框
    outline: none; // 确保聚焦时也没有轮廓
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-box-shadow: 0 0 0 30px #322D38 inset !important;
    -webkit-text-fill-color: #fff !important;
    background-color: #322D38 !important;
    }
  }
}

// utils and one offs
.brand-form-control-contactForm {
  background: #322D38;
}

.quick-reverse {
  flex-direction: row-reverse !important;
  justify-content: flex-end !important;
}

.justify-start {
  justify-content: flex-start !important;
  margin-right: 10px;
}

.justify-end {
  justify-content: flex-end !important;
  margin-left: 10px;
}

.brand-rec-button {
  background: #7E7B82;
  border-radius: 24px;
  color: white;
  cursor: pointer;
  font-family: Roboto;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  font-weight: bold;
  height: 40px;
  letter-spacing: normal;
  line-height: normal;
  padding: 0 90px;
  position: relative;
  top: 20px;
  @include px-to-vw-mq((font-size: 18px, height: 40px,padding-inline: 90px));

  &:focus {
    outline: none;
  }

  &:hover {
    background: #EF3937;
    color: #322D38;
  }
}


.triangle-pokey-right {
  @include trianglePokeyRight;
}

.triangle-pokey-left {
  @include trianglePokeyLeft;
}

.fixed-contact-dealy {
  align-items: center;
  background-color: transparent;
  background-color: #18191d;
  border-bottom: 1px solid #5c5757;
  color: white;
  display: none;
  font-family: Roboto;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  font-weight: bold;
  height: 75px;
  justify-content: center;
  left: 0px;
  letter-spacing: normal;
  line-height: normal;
  opacity: 0;
  overflow-y: visible;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 20;

  @media (min-width: 768px) and (max-width: 1919px) {
    @include px-to-vw((font-size: 18px,
        height: 75px,
      ));
  }


  @media #{$mobileOnly} {
    font-size: 13px;
  }

  .inner-button {
    margin-left: 10px;

    a {
      align-items: center;
      cursor: pointer;
      font-family: Roboto;
      justify-content: center;
      text-decoration: none;
      text-transform: uppercase;
      border: 2px solid #CDCCD0;
      border-radius: 24px;
      font-size: 14px;
      margin-left: 20px;
      width: 140px;
      height: 36px;
      display: inline-flex;
      transition: 300ms ease all;
      color: #CDCCD0;

      &:hover {
        background: #EF3937;
        color: #FEFEFF;
        border: none;
      }

      @media (min-width: 768px) and (max-width: 1919px) {
        @include px-to-vw((font-size: 14px,
            margin-left: 20px,
            width: 140px,
            height: 36px,
          ));
      }
    }
  }
}

.fade-up-prep {
  opacity: 0;
}