.verification-container-root{
  position:fixed;
  z-index: 1300;
  inset:0px;
}

.verification-container-div {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  font-family: Arial, sans-serif;
  background-color: rgb(0,0,0,0.5);
  opacity: 1;
  transition: all 225ms cubic-bezier(0.4,0,0.2,1);
}

.verification-container {
  background: #262626;
  padding: 30px;
  border-radius: 10px;
  position: relative;
  width: 400px;
}

.close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  color: #ff4444;
  font-size: 24px;
  cursor: pointer;
  text-decoration: none;
}

h2 {
  color: #ffffff;
  margin-bottom: 20px;
  font-size: 18px;
  text-transform: none;
}

.code-container {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 20px;
}

.code-input {
  width: 50px;
  height: 50px;
  border: 2px solid #444;
  border-radius: 8px;
  font-size: 24px;
  text-align: center;
  background: #333;
  color: white;
  outline: none;
}

.code-input:focus {
  border-color: #0088ff;
}

.email-text {
  color: #666;
  font-size: 14px;
  margin-top: 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  p {
    font-size: 14px;
    color:#ffffff;
    line-height: 0px;
  }
}

.timer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  gap: 10px;
}

.timer {
  color: #0088ff;
  font-size: 14px;
  span{
    color:#FF0000;
  }
}

.resend-button {
  background: none;
  border: none;
  color: #0088ff;
  cursor: pointer;
  font-size: 14px;
  text-decoration: underline;
}

.resend-button:disabled {
  color: #666;
  cursor: not-allowed;
  text-decoration: none;
}

.error-message {
  color: #ff4444;
  font-size: 14px;
  text-align: center;
  // margin-top: 15px;
}
