// import variables
@import "../../../../../utils/_variables";

.QuestCampaignToggle_Wrapper {
  display: flex;
  background: #322D38;
  border-radius: 16px;
  width: fit-content;
  gap: 4px;

  input[type="radio"] {
    display: none;
  }

  label {
    padding: 8px 16px;
    border-radius: 16px;
    cursor: pointer;
    color: #FDFDFD;
    font-size: 14px;
    transition: all 0.3s ease;
    text-transform: uppercase;
    font-weight: 500;
    width: 124px;
    text-align: center;
  }

  input[type="radio"]:checked + label {
    background: #EF3937;
  }
}
