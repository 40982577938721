 @import  '../../utils/_variables';

.OpenBar {
  background-color: rgba(0, 0, 0, 0.35);
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  height: 60px;
  margin: 30px 0 10px;
  padding: 0 10px 18px 13px;
  width: 100%;	

  .previewBar {
	  display: flex;
    align-items: baseline;
	  justify-content: space-between;
    @media #{$mobileOnly} {
      @include vw-pixels((
        'font-size': 16,
      ));
    }
	}

  .faqDesc {
  	display: none;
  }

  .fa {
    color: rgba(255, 255, 255, 0.5);
  	cursor: pointer;
    @include vw-pixels((
      'font-size': 16,
    ));

    @media #{$mobileAndTabletOnly} {
      padding: 5px 0 0 0;
      @include vw-pixels((
        'font-size': 16,
      ));
    }
  }

  h2 {
  	margin: 0;
  }

  &.open {
		height: auto;

  	.faqDesc {
      display: block;
      margin: 24px 0 0 0;
  		text-align: left;
  	}
	}
}