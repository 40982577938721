

.ConnectPlatforms {
  text-align: center;
  .ConnectPlatforms--squares {
    display: flex; 
    justify-content: center;
    gap: 82px;
    .ConnectPlatforms--square {
      border: 1px solid grey;
      background: #211C27;
      border-radius: 50%;
      display: inline-block;
      height: 200px;
      width: 200px;
      position: relative;
      cursor: pointer;
      @media screen and (max-width: 640px) {
        display:flex;
        align-items: center;
        flex-direction:row;
        width:100%;
        padding-bottom: 40%;
        padding-top: 6%;
      }

      .ConnectPlatforms--check {
        display: none;
        position: absolute;

        @media screen and (max-width: 1023px) {
          display:none !important;
        }
        border-radius: 50%;
        // border: 2px solid #595261;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        font-size: 100px;
        line-height: 200px;

        .check_icon {
          width: 36px;
          height: 36px;
          position: absolute;
          top: 20%;
          left: 87.5%;
        }
      }

      &.not_connected {
        
        .ConnectPlatforms--disconnected {
          display: inline-block;
        }
        .coming_soon{
          color: #83858c;
        }
        .ConnectPlatforms--connecting {
          display: none;
        }

        .ConnectPlatforms--connected {
          display: none;
        }

        .ConnectPlatforms--check {
          display: none;
        }
      }
      @-webkit-keyframes Rotate {
        from {
          -webkit-transform: rotate(0deg);
        }

        to {
          -webkit-transform: rotate(360deg);
        }
      }
      @-moz-keyframes Rotate {
        from {
          -moz-transform: rotate(0deg);
        }

        to {
          -moz-transform: rotate(360deg);
        }
      }
      @-ms-keyframes Rotate {
        from {
          -ms-transform: rotate(0deg);
        }

        to {
          -ms-transform: rotate(360deg);
        }
      }

      &.connecting {
       
        .ConnectPlatforms--border {
        }
        .ConnectPlatforms--disconnected {
          display: none;
        }
        .ConnectPlatforms--connecting {
          margin-top: 12px;
          display: inline-block;
        }
        .ConnectPlatforms--connected {
          display: none;
        }
        .ConnectPlatforms--check {
          display: none;
        }
      }
      &.connected {
        .ConnectPlatforms--border {
          border: none;
        }
        .ConnectPlatforms--disconnected {
          display: none;
        }
        .ConnectPlatforms--connecting {
          display: none;
        }
        .ConnectPlatforms--connected {
          display: inline-block;
        }
        .ConnectPlatforms--check {
          display: block;
          border: 2px solid #EF3937;
        }
      }
      .ConnectPlatforms--inner {
        display: block;
        min-height: 100%;
        padding-top: 30%;

        @media screen and (max-width: 640px) {
          display:block;
          width:100%;
          align-items:center;
        }

        .ConnectPlatforms--inner1 {
          height: 60%;
          @media screen and (max-width: 640px) {
                text-align: center;
              width: 100%;
              margin-top: 15px;
            }

          img {
            display: block;
            margin-right: auto;
            margin-left: auto;

            @media screen and (max-width: 640px) {
              margin-left: 0;
              height:60px !important;
              object-fit: cover;
              display: inline-block;
              width: 80%;
              min-width:144px;
            }
          }
        }

        .ConnectPlatforms--inner2 {
          height: 40%;

          @media screen and (max-width: 640px) {
            margin-right:10% !important;
            width: 100%;
            text-align: center;
          }

          button {
            margin-top: 20px;
          }

          .ConnectPlatforms--connecting {
            color: #83858c;
            font-size: 12px;
          }
        }
      }

      &.ConnectPlatforms--square1 {
        .ConnectPlatforms--inner1 {
          img {
              @media screen and (max-width: 640px) {
                object-position: -50px 0px !important;
                    margin: auto;
                  display: inline-block;
                  object-position: center center !important;
                  object-fit: contain;
              }
            }
        }
      }

      &.ConnectPlatforms--square2 {
        .ConnectPlatforms--inner1 {
          img {
              @media screen and (max-width: 640px) {
                width:auto !important;
              }
            }
        }
      }

      &.ConnectPlatforms--square3 {
        .ConnectPlatforms--inner1 {
          img {
              @media screen and (max-width: 640px) {
                width:auto !important;
              }
            }
        }
      }
      &.ConnectPlatforms--square3:after {
        content: "";
        display: block;
        width: 0;
        position: absolute;
        bottom: -5%;
        right: -1px;
        background: #43C8B5;
        height: 10px;
        width: 30%;
        transition: 200ms ease all;
        @media screen and (max-width: 640px) {
          right:-1px;
          bottom:-6px;
          width:15vw;
          height:6px;
        }
      }
      &.ConnectPlatforms--square3 .ConnectPlatforms--inner2:after {
        content: "";
        display: block;
        width: 0;
        position: absolute;
        top: -1px;
        right: -3%;
        background: #3A74E0;
        width: 6px;
        height: 60%;
        transition: 200ms ease all;
        @media screen and (max-width: 640px) {
          right:-6px;
          top:-1px;
          height:15vw;
          width:6px;
        }
      }
    }
  }
  @media screen and (max-width: 1023px) {
    .ConnectPlatforms--inner2{
      button.ConnectPlatforms--connected.mobile{
        &.connected{
          display: -webkit-box !important;
        }
        display:none;
        overflow:hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical !important;
        text-overflow: ellipsis !important;
        width: 100%;
        padding: 0 10px;
      }
    }
  }
  a,
  button {
    font-size: 16px;
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    text-transform: uppercase;
    @media screen and (max-width: 1023px) {
      &.desktop{
        display:none !important;
      }
    }
    @media screen and (min-width: 1024px) {
      &.mobile{
        display:none !important;
      }
    }
  }
}