@import  '../../../../utils/_variables';

.AGameEdit{
  .AGameEdit__loading {
    text-align: center;
    padding-top: 20px;
  }
  .Modal__close-btn{
    width: 25px;
    height: 25px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 10px;
    cursor: pointer;
    outline: none;
    &:hover{
      &::after,
      &::before{
        background: #6d7179;
      }
    }
    &::before{
      content: "";
      width: 3px;
      height: 90%;
      background: #37393D;
      display: block;
      position: absolute;
      left: 50%;
      transform-origin: center;
      transform: rotate(45deg);
      transition: ease 300ms background;
    }
    &::after{
      content: "";
      width: 3px;
      height: 90%;
      background: #37393D;
      display: block;
      position: absolute;
      left: 50%;
      transform-origin: center;
      transform: rotate(-45deg);
      transition: ease 300ms background;
    }
  }
  .info-tab-wrap{
    width:100% !important;
    .gallery__add{
      display: inline-block;
      margin-bottom: 10px;
      cursor: pointer;
      padding: 5px;
      border-radius: 5px;
      color: $colorLight;
      border: 1px solid $colorLight;
      margin-right: 10px;
    }
    .gallery__item{
      border-radius: 10px;
      border: 1px dashed $colorLight;
      padding: 10px;
      margin-bottom: 10px;
      position: relative;
      .GameForm__gallery--up {
        cursor: pointer;
        position: absolute;
        right: 90px;
        padding: 0 5px;
        border: 1px solid white;
        border-radius: 3px;
        &:hover {
          background: $colorLight;
          color: #202227;
        }
      }
      .GameForm__gallery--down {
        cursor: pointer;
        position: absolute;
        right: 50px;
        padding: 0 5px;
        border: 1px solid white;
        border-radius: 3px;
        &:hover {
          background: $colorLight;
          color: #202227;
        }
      }
    }
  }
  .main_desc .mce-path,
  .main_desc .mce-container{
    background-color:#2D2D33 !important;
  }
  .main_desc .mce-panel{
    border:0 solid #2D2D33 !important;
  }
  .AGameEdit__wrapper{
    margin: 0 auto;
		padding: 30px;
    padding-top: 120px;
    .category__field{
      margin-bottom: 10px;
      padding-top: 20px;
      span{
        width: 120px;
        color: #ececec;
        border: 1px solid #ececec;
        padding: 4px 10px;
        margin-right: 10px;
        cursor: pointer;
        i{
          width: 14px;
          text-align: center;
        }
        &.checked{
          color: #4BE0CA;
          border: 1px solid #4BE0CA;
        }}
      
    }
    .button-style{
      width: 300px;
      height: 50px;
      border-radius: 30px;
      background: linear-gradient(to right, #45cacd, #47b8d4);
      color: #FFF;
      border: none;
      outline: none;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 22px;
      letter-spacing: 1.2px;
      margin: 20px 0 20px 0;
      cursor: pointer;
    }
    .AGameEdit__content{
      margin: 0 10%;
      .AGameEdit__title{
        font-weight: 500;
        letter-spacing: 3.5px;
        text-align: left;
        text-transform: uppercase;
        @include vw-pixels((
          'font-size': 35
        ));
      }
      .AGameEdit__actions{
        button{
          @extend .button-style;
        }
      }
      form.admin-game-form>* {
        padding-right: 20px;
      }
      form.admin-game-form{
        background-color: #202227;
        padding: 20px;
        width: 100%;
        label{
          color:white;
        }
        .GameEdit__upload {
          color: $colorEnergyBlue;
          display: inline-block;
          // margin-bottom: 10px;
          padding: 5px 0;
          cursor: pointer;
        }
        .input-wrapper{
          position: relative;
          .error-message{
            color: #dc4f4f;
            font-size: 12px;
            position: absolute;
            right: 0;
            bottom: -17px;
          }
          .error-icon{
            color: #dc4f4f;
            display: none;
            position: absolute;
            right: 10px;
            top: 10px;
          }
          .ok-icon{
            position: absolute;
            right: 10px;
            bottom: 5px;
            color: #49E1CA;
          }
        }
        
        input, select, textarea{
          background-color: #202227;
          border: none;
          border-bottom: 2px solid #83858C;
          border-radius: 0px;
          color: white;
          display: inline-block;
          padding-left: 0;
          text-align: left;
          margin: 0;
          width: 100%;
        }
        input.error, select.error, textarea.error{
          border-bottom: 2px solid #dc4f4f;
        }
        select {
          background-image: none;
        }
        a {
          text-decoration: none;
        }
        ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: rgb(131, 133, 140);
          opacity: 1; /* Firefox */
        }
    
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: rgb(131, 133, 140);
        }
    
        ::-ms-input-placeholder { /* Microsoft Edge */
          color: rgb(131, 133, 140);
        }
        .first-option{
          color: rgb(131, 133, 140);
        }
        input:focus, select:focus, textarea:focus{
          border-bottom: 2px solid #3A74E0;
          box-shadow: none;
        }
        input:placeholder-shown + label {
          cursor: text;
          max-width: 66.66%;
          color:#d1d1d1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          transform-origin: left bottom;
          transform: translate(0, 1.9rem) scale(1.5);
          &:after{
            content: attr(data-label);
          }
        }
        .input-wrapper {
          display: flex;
          flex-flow: column-reverse;
          margin-bottom: 1em;
          position: relative;
        }
        input, select, textarea{
          // z-index:1;
          background-color: transparent;
        }
        select option{
          background-color: #202227;
        }
        label{
          // z-index:0;
        }
        label, input {
          transition: all 0.2s;
          touch-action: manipulation;
        }
        .form__footer{
          .AGameForm__save--btn{
            @extend .button-style;
          }
        }
      }
    }
  }
  .ModalMaster {
    .inputFile {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }
    .cta__button{
      background-image: linear-gradient(to bottom, #3a74e0, #4a6fe6, #5d69ea, #7061ec, #8358ed);
      color: #fff;
      cursor: pointer;
      text-decoration: none;
      text-align: center;
      font-weight: normal;
      border-radius: 2px;
      font-weight: 600;
      font-size: 14px;
      padding: 10px 20px;
    }
    .Modal__upload {
      .Modal__wrapper {
        max-height: 350px;
        max-width: 600px;
        display: flex;
        flex-flow: wrap;
        background: $colorBackground;
        .Modal__upload__content {
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          // margin: 4%;
          padding: 5px;
          width: 92%;
          height: 92%;
          cursor: pointer;
          .Modal__upload__image {
            padding-top: 30px;
            background: url('../../../../images/profile/upload.png');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            height: 50px;
            width: 55px;
            margin-right: auto;
            margin-left: auto;
            position: relative;
          }
          .Modal__upload__title {
            padding-top: 25px;
            font-weight: 700;
            font-size: 20px;
            width: 100%;
            color: white;
            position: relative;
          }
          .Modal__upload__or {
            padding-top: 10px;
            font-size: 14px;
            color: $colorLightGray;
            width: 100%;
            position: relative;
          }
          .Modal__upload__action {
            padding-top: 10px;
            position: relative;
            button, label {
              padding: 15px 30px;
              font-weight: 900;
              font-size: 16px;
            }
            label {
              width: 50%;
              line-height: 1;
              margin: 0 auto;
            }
          }
          .Modal__upload__loading {
            margin-top: 20px;
            height: 70px;
            margin-right: 40px;
            margin-left: 40px;
            position: relative;
            .Modal__upload__loading__progress {
              position: absolute;
              height: 100%;
              width: 42%;
              background-color: $colorEnergyBlue;
            }
            .Modal__upload__loading__percentage {
              position: absolute;
              font-weight: 900;
              right: 10px;
              font-size: 30px;
              line-height: 70px;
              color: $colorBackground2;
            }
            .Modal__upload__loading__fileinfo {
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              .Modal__upload__loading__top {
                position: relative;
                font-size: 18px;
                text-align: left;
                padding-left: 15px;
              }
              .Modal__upload__loading__bottom {
                position: relative;
                font-size: 12px;
                text-align: left;
                font-weight: 300;
                padding-left: 15px;
              }
            }
          }
          .Modal__upload__title2 {
            padding-top: 10px;
            font-weight: 700;
            font-size: 20px;
            width: 100%;
            color: white;
            position: relative;
          }
          .Modal__upload__button {
            cursor: pointer;
            padding-top: 10px;
            color: $colorEnergyBlue;
            font-size: 14px;
            font-weight: 700;
          }
          .Modal__upload__avatar {
            color: #FFF;
            display: flex;
            width: 140px;
            height: 140px;
            align-items: center;
            justify-content: center;
            justify-self: center;
            margin-right: auto;
            margin-left: auto;
            font-family: 'Lato', sans-serif;
            position:relative;
            .Modal__upload__avatar--container {
              position: relative;
              max-width: 130px;
              max-height: 130px;
              width: 100%;
              height: 100%;
              border-radius: 100%;
              background: linear-gradient(to bottom, #02bdad, #036ccf);
              .Modal__upload__avatar--container-avatar {
                width: calc(100% - 6px);
                height: calc(100% - 6px);
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                padding: 0;
                margin: 0;
                border-radius: 100%;
                background-repeat: no-repeat;
                background-size: contain;
              }
            }
          }
        }
      }
    }
    .Modal {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 99;
      display: flex;
      align-items: center;
      justify-content: center;
      visibility: hidden;
      opacity: 0;
      overflow-x: hidden;
      background: rgba(0,0,0,.5);

      .Modal__layer {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
      }
      align-items: flex-start;
      @media screen and (min-width: 640px) {
        align-items: center;
      }
      .Modal__wrapper {
        width: 90%;
        height: 90%;
        position: relative;
        text-align: center;
        align-items: center;
        justify-content: center;
        transform: scale(0.8);
        margin: 5vw 0 5vh;
        @media screen and (min-width: 640px) {
          margin: 0;
        }
        .Modal__upload__border{
          position: absolute;
          width: 92%;
          height: 92%;
          border: 2px dashed $colorBackground2;
          &.active {
            border: 2px solid $colorEnergyBlue;
          }
        }
        .icon {
          width: 50px;
          margin: 30px auto;
          img {
            width: 100%;
          }
        }
        .Modal__title {
          font-size: 25px;
          letter-spacing: 2px;
          margin: 20px 0 0;
        }
        .Modal__close-btn {
          width: 35px;
          height: 35px;
          margin-left: auto;
          position: absolute;
          right: 35px;
          top: 25px;
          cursor: pointer;
          // z-index: 10;
          $crossColor: $colorBackground2;
          &:before {
            content: "";
            width: 3px;
            height: 90%;
            background: $crossColor;
            display: block;
            position: absolute;
            left: 50%;
            transform-origin: center;
            transform: rotate(45deg);
          }
          &:after {
            content: "";
            width: 3px;
            height: 90%;
            background: $crossColor;
            display: block;
            position: absolute;
            left: 50%;
            transform-origin: center;
            transform: rotate(-45deg);
          }
        }
      }
      &.active {
        visibility: visible;
        opacity: 1;
        .Modal__wrapper {
          transform: scale(1);
        }
      }
    }
  }
}