@import "../../../../../utils/_variables";

.QuestDisplay {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  

  .QuestDisplay_Title {
    margin: 20px 0;

    font-size: 50px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.5px;
    text-align: left;


  }

  .QuestDisplay_Pagination {
    @include center-content;
    width: 100%;
    color: #B0ADB4;
    padding: 20px 0;
    font-size: 16px;
    .QuestDisplay_Pagination_Page_Size {
      display: flex;
      align-items: center;
      flex-direction: row;
    }
    
    .QuestDisplay_Pagination_Buttons {
      display: flex;
      align-items: center;
      .QuestDisplay_Pagionation_Current_Page {
      }

      .QuestDisplay_Pagination_Button {
        @include center-content;
        height: 39px;
        width: 39px;
        color: #B0ADB4;
        cursor: not-allowed;
        svg {
          width: 10px;
          height: auto;
        }
        &[disabled] {
          // cursor: not-allowed;
          pointer-events: none;
        }
      }
      .Clickable {
        border: none;
        cursor: pointer;
      }
    }

  }

  .QuestDisplay_List {
    padding: 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .QuestDisplay__Item {
      display: flex;
      width: 100%;
      background-color: #322D38;
      border-radius: 10px;
      @media #{$mobileAndTabletOnly} {
        flex-direction: column;
        padding: 40px 20px;
      }

      .QuestDisplay__Item_Img {
        height: 244px;
        width: 514px;
        border-radius: 10px;
        object-fit: cover;
        @media #{$mobileAndTabletOnly} {
          align-self: center;
          height: 200px;
          min-width: 200px;
          max-width: 200px;
        }
      }

      .QuestDisplay__Item_Details {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        height: 100%;
        @media #{$mobileAndTabletOnly} {
          width: 80%;
        }
        margin-left: 39px;
        .QuestDisplay__Item_Title {
          font-size: 24px;
          font-weight: 900;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: 0.3px;
          text-align: left;
          @media #{$mobileAndTabletOnly} {
            font-size: 20px;
            margin-bottom: 15px;
            margin-top: 15px;
          }
        }
        .QuestDisplay__Item_Rewards {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: 5px;
          .QuestDisplay__Item_Rewards_Title {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: left;
            color: $colorCGray;
          }
          .QuestDisplay__Item_Rewards_Details {
            display: flex;
            align-items: center;
            background-color: #211C27;
            border-radius:8px;
            width: 200px;
            padding: 8px;
            height: 32px;
            svg {
              width: 18px;
              height: auto;
            }
            .QuestDisplay__Item_Rewards_Details_Text {
              font-size: 12px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              letter-spacing: 0.24px;
              text-align: left;
              margin-left: 10px;
            }
          }
        }

        .QuestDisplay__Item_Actions {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          gap: 24px;
          width: 100%;

          .QuestDisplay__Item_Actions_Action {
            @include center-content;
            @include transform-scale;
            width: 32px;
            height: 32px;
            background-color: #FFAFC9;
            border-radius: 8px;
            cursor: pointer;
            svg {
              width: 20px;
              height: 20px;
            }
            .QuestDisplay__Item_Actions_Campaign_Report {
              width: 100px !important;
            }
          }
          .QuestDisplay__Item_Actions_Game {
            @include transform-scale;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            height: 46px;
            width: 209px;
            margin: 0 10px 10px 0;
            background-color: $colorFluidPink;
            border-radius: 8px;
            color: $colorBackground;
            cursor: pointer;
            img {
              width: 36px;
              height: 36px;
              // object-fit: cover;
              border-radius: 6px;
            }

            .QuestDisplay__Item_Actions_Game_Title {
              font-size: 12px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              letter-spacing: 0.24px;
              text-align: left;
              // color: $colorBackground;
            }

            svg {
              width: 20px;
              height: 20px;
            }
          }
        }

        .QuestDisplay__Item_View_Details {
          @include red-button-content;
          padding: 7px 28px;
          height: 32px;
          text-transform: uppercase;
          img{
            margin-right: 16px;
          }
          span{
            text-transform: uppercase;
          }
        }
      }
    }

    .QuestDisplay__Blank {
      @include center-content;
      flex-direction: column;

      min-height: 412px;
      max-height: 412px;
      width: 100%;

      border-radius: 14px;
      border: 1px solid $colorSoftGray;

      & > * {
        margin: 12px;
      }

      svg {
        height: 85px;
        width: auto;
      }

      .QuestDisplay__Blank_Text {
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 0.2px;
        text-align: center;
        color: $colorWhite;
      }

      .QuestDisplay__Blank_Button {
        @include red-button-content;
        @include transform-scale;
        font-weight: 600;
        font-size: 18px;
        height: 48px;
        width: 40%;
      }
    }
  }
}
