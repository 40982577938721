.twitch-rankings {
    margin-top: 30px;

    .rankings-title {
        font-size: 18px;
        font-weight: bold;
        color: #F8F8F8;
        // line-height: 30px;
        margin-bottom: 10px;
    }

    .rankings-summary {
        background-color: #211C27;
        padding: 10px 10px 10px 16px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        color: #908E93;
        line-height: 30px;

        .summary-title {
            font-size: 14px;
        }

        .summary-bottom {
            color: #FA3633;
            font-size: 14px;
        }

        .summary-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 12px;

            .label {
                font-weight: bold;
                margin-right: 10px;
            }
        }
    }

    .rankings-slider {
        margin-top: 10px;
        background-color: #211C27;
        padding: 10px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        border-radius: 10px;

        .ranking-item {
            background: #322D38;
            padding: 6px 14px;
            border-radius: 6px;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 15px;
            color: #ffffff;
            cursor: pointer;
            transition: transform 0.2s ease, background-color 0.2s ease;

            &:hover {
                transform: translateY(-2px);
                background-color: rgba(100, 65, 164, 0.2); // 紫色调的悬停背景，与Twitch品牌颜色相匹配
              }

            .rank {
                width: 30px; // 固定宽度留出足够空间显示排名
                color: #EF3937;
                font-size: 18px;
                font-weight: bold;
                flex-shrink: 0;
            }

            .avatar {
                width: 36px;
                height: 36px;
                border-radius: 50%;
                flex-shrink: 0;
                margin-left: 25px;
            }

            .user-info {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 5px;

                .name {
                    font-size: 16px;
                    color: #F8F8F8;
                }

                .fans {
                    font-size: 14px;
                    color: #908E93;
                    font-weight: bold;
                }
            }
        }
    }
}

.break-lines {
    white-space: pre-wrap;
}